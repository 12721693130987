import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { AgroforestryForm } from '../../components/AgroforestryForm/AgroforestryForm';
import { NavigateFunction } from 'react-router-dom';
import { useAgroforestryForm } from '../../hooks/useAgroforestryForm';
import agroforestryRoutes from '../../agroforestry.routes';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';

export default function AgroforestryFormActionPage({ navigate }: { navigate: NavigateFunction }) {
    const { t } = useTranslation();
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });
    const isPerformingMutation = globalMutationStatus === 'pending';
    const { isLoading, isDone, field, handleContinue } = useAgroforestryForm({ navigate });
    return (
        <encodingShared.ActionPage
            title={t('encoding-agroforestry.form.title', { fieldName: field?.name })}
            leftButtonProps={{
                onClick: () => {
                    navigate(agroforestryRoutes.introduction());
                },
                children: t('constants.back'),
            }}
            rightButtonProps={{
                children: t('constants.continue'),
                onClick: handleContinue,
                disabled: isPerformingMutation || !isDone,
            }}
            isLoading={isLoading}
        >
            <AgroforestryForm navigate={navigate} />
        </encodingShared.ActionPage>
    );
}
