import api from '@shared/api';
import {
    PostCreatePaymentSessionBodyT,
    FarmPaymentStatusT,
    FarmSeasonPaymentStatusT,
    FarmSeasonProductPlanT,
} from './payment.types';
import { PaymentDetailsT } from '@modules/billing/api/billing.types';
import { skipToken } from '@reduxjs/toolkit/query';

export const paymentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRenewalInformation: builder.query<PaymentDetailsT, { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/billing/renewal-information`,
                method: 'GET',
            }),
        }),
        getFarmPaymentStatus: builder.query<FarmPaymentStatusT, { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/payment/status`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: ['FarmPaymentStatus'],
        }),
        getNextFarmSeasonProductPlan: builder.query<FarmSeasonProductPlanT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/next-product-plan`,
                method: 'GET',
            }),
            providesTags: ['NextFarmSeasonProductPlan'],
        }),
        postCreateOrUpdateNextFarmSeasonAndProductPlan: builder.mutation<
            FarmSeasonProductPlanT,
            { farmSeasonId: number; farmSeasonProductPlanName: 'NETOFF' | 'HA_BASED' }
        >({
            query: ({ farmSeasonId, farmSeasonProductPlanName }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/next-product-plan`,
                method: 'POST',
                body: { farm_season_product_plan_name: farmSeasonProductPlanName },
            }),
            invalidatesTags: ['Season', 'NextFarmSeasonProductPlan', 'FarmPaymentStatus'],
        }),
        postCreatePayment: builder.mutation<PostCreatePaymentSessionBodyT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/payment`,
                method: 'POST',
            }),
            invalidatesTags: ['FarmSeasonPaymentStatus'],
        }),
        getFarmSeasonPaymentStatus: builder.query<FarmSeasonPaymentStatusT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/payment/status`,
                method: 'GET',
            }),
            providesTags: ['FarmSeasonPaymentStatus'],
        }),
    }),
});

export const { usePostCreatePaymentMutation, usePostCreateOrUpdateNextFarmSeasonAndProductPlanMutation } = paymentApi;

export const useGetRenewalInformationQuery = (params: { farmId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return paymentApi.useGetRenewalInformationQuery({ farmId: params.farmId! }, { skip: !params.farmId });
};
export const useGetFarmPaymentStatusQuery = (params: { farmId: number | undefined }) => {
    return paymentApi.useGetFarmPaymentStatusQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmId: params.farmId! },
        { skip: !params.farmId, refetchOnMountOrArgChange: true },
    );
};
export const useGetNextFarmSeasonProductPlanQuery = (params: { farmSeasonId: number | undefined }) => {
    return paymentApi.useGetNextFarmSeasonProductPlanQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};
export const useGetFarmSeasonPaymentStatusQuery = ({ farmSeasonId }: { farmSeasonId?: number | null }) => {
    return paymentApi.useGetFarmSeasonPaymentStatusQuery(farmSeasonId ? { farmSeasonId } : skipToken);
};
