import createDefaultState from '../createDefaultState';
import { livestockCacheAdapter } from './livestock.cache';
import livestockApi from './livestock.api';
import { LivestockEndpointParamsT } from './livestock.types';

export default ({ farmSeasonId }: LivestockEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(livestockCacheAdapter, () =>
        livestockApi.useGetAll({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    return {
        livestockState: {
            ...defaultState,
        },
    };
};
