/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsT, constructRouteWithParams } from '@shared/utils/routesHelper';
const fieldId = 'fieldId';
type FieldIdT = typeof fieldId;

/* -------------------------------------------------------------------------- */

export const soilAnalysisRoutesConfig = {
    introduction: '',
    soilAnalysis: {
        base: `:${fieldId}`,
        form: '',
        duplication: 'duplication',
    },
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;
export type SoilAnalysisFormParamsT = RouteParamsT<FieldIdT>;
export type SoilAnalysisDuplicationParamsT = RouteParamsT<FieldIdT>;

const introduction = () => `${soilAnalysisRoutesConfig.introduction}`;
const soilAnalysisForm = (params: SoilAnalysisFormParamsT) => {
    return constructRouteWithParams(`${soilAnalysisRoutesConfig.soilAnalysis.base}`, params);
};
const soilAnalysisDuplication = (params: SoilAnalysisDuplicationParamsT) => {
    return constructRouteWithParams(
        `${soilAnalysisRoutesConfig.soilAnalysis.base}/${soilAnalysisRoutesConfig.soilAnalysis.duplication}`,
        params,
    );
};

/* -------------------------------------------------------------------------- */

export default {
    introduction,
    soilAnalysisForm,
    soilAnalysisDuplication,
};
