import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { Button } from '@soil-capital/ui-kit/components';
import { IconClose, IconLightning } from '@soil-capital/ui-kit/icons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type DuplicationModalType = {
    title: string;
    leftPanel: ReactNode;
    rightPanel: ReactNode;
    open: boolean;
    onClose: () => void;
    isDoingCopy?: boolean;
};
export const DuplicationModal = ({
    title,
    leftPanel,
    rightPanel,
    open,
    onClose,
    isDoingCopy,
}: DuplicationModalType) => {
    const { t } = useTranslation();
    return (
        <>
            <FullscreenModal
                open={open}
                onClose={onClose}
                title={
                    <>
                        <IconLightning />
                        {title}
                    </>
                }
                leftPanel={leftPanel}
                rightPanel={rightPanel}
                headerButton={
                    <Button
                        startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />}
                        variant="text"
                        disabled={isDoingCopy}
                        onClick={onClose}
                    >
                        {t('constants.cancel')}
                    </Button>
                }
            />
        </>
    );
};
