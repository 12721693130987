import { useTranslation } from 'react-i18next';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { ScrollSection } from '@soil-capital/ui-kit/components';
import { IconSpraying } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { PesticidesListT } from '../FieldCropSummaryActionPage.types';

export const SprayingSummary = ({ pesticidesList }: { pesticidesList: PesticidesListT }) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropSummaryActionPageStyle();

    return (
        <ScrollSection spacingX={2} className={classes.container}>
            <div className={classes.containerHeader}>
                <IconSpraying data-testid="icon-spraying" />
                <Typography variant="caps">{t('encoding-technical-itinerary.field-crop-summary.spraying')}</Typography>
            </div>
            {pesticidesList?.length && pesticidesList.length > 0 ? (
                <table className={classes.summaryTable}>
                    {pesticidesList?.map((pesticide, index) => (
                        <tr key={index} className={classes.summaryRow}>
                            <td width={220}>
                                <Typography variant="body2">{pesticide.product}</Typography>
                            </td>
                            <td width={120}>
                                <Typography variant="body2">
                                    {pesticide.passes}{' '}
                                    {pesticide.passes === 1 ? t('constants.pass') : t('constants.pass_other')}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </table>
            ) : (
                <></>
            )}
        </ScrollSection>
    );
};
