import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import useFieldIdentityContextualPanelLogic from './useFieldIdentityContextualPanel.logic';
import { useTranslation } from 'react-i18next';
import { Button } from '@soil-capital/ui-kit/components';
import { IconFile2 } from '@soil-capital/ui-kit/icons';
import { FieldMapSnapshot } from '@modules/encoding/shared/components/FieldMapSnapshot/FieldMapSnapshot';
import irrigationRoutes from '../../irrigation.routes';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { fieldCropId, seasonId, fieldId, isLoading, setFieldMapSnapshotIsLoading, setFieldIdentityCardIsLoading } =
        useFieldIdentityContextualPanelLogic();
    const { t } = useTranslation();

    return (
        <encodingShared.ContextualPanel isLoading={isLoading}>
            <FieldMapSnapshot
                farmSeasonId={seasonId}
                fieldId={fieldId}
                header={
                    <Button
                        variant="text"
                        startIcon={<IconFile2 fontSize="small" />}
                        size="medium"
                        onClick={() => navigate(irrigationRoutes.introduction())}
                    >
                        {t('encoding-irrigation.contextual-panel.back-to-fields-list')}
                    </Button>
                }
                setFieldMapSnapshotIsLoading={setFieldMapSnapshotIsLoading}
            >
                <encodingShared.FieldIdentityCard
                    fieldCropId={fieldCropId}
                    setIsLoading={setFieldIdentityCardIsLoading}
                />
            </FieldMapSnapshot>
        </encodingShared.ContextualPanel>
    );
};
