import { useEffect, useState } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */

/** Fetch data for the entity after a delay. This can be useful to preload data without slowing the current fetches */
export const getUsePrefetch =
    <T extends (p: any, o: any) => any>(
        /* The RTK fetch hook returned by the entity api and used in the entityState. */
        useGet: T,
        /* time in millisecond to wait until do the fetch */
        delayingTime = 800,
    ) =>
    (params: Parameters<T>[0]) => {
        const [delaying, setDelaying] = useState(true);
        const paramValues = Object.values(params ?? {});
        const invalidParam = paramValues.findIndex((v) => !v) !== -1;

        useGet(params, { skip: invalidParam || delaying });

        useEffect(() => {
            const timeout = setTimeout(() => {
                setDelaying(false);
            }, delayingTime);

            return () => clearTimeout(timeout);
        }, []);
    };
