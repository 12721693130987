import entities, { TreeT } from '@shared/entities';
import { useCallback } from 'react';
import { encodingShared } from '@modules/encoding/shared';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const useAgroforestryDetailsForm = () => {
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { readOnly } = useFarmSeasonReadOnly();

    const [deleteAgroforestry] = entities.tree.useDelete();
    const [updateAgroforestry] = entities.tree.useUpdate();

    const onUpdate = useStableDebounce((tree: TreeT) => {
        updateAgroforestry({
            farmSeasonId: currentSeasonId,
            farmSeasonFieldTreeId: tree.id,
            body: { ...tree },
        });
    }, FORM_SUBMIT_DEBOUNCE_TIME_MS);

    const onDelete = useCallback(
        ({ treeId }: { treeId: number }) => {
            deleteAgroforestry({
                farmSeasonId: currentSeasonId,
                farmSeasonTreeId: treeId,
            });
        },
        [currentSeasonId, deleteAgroforestry],
    );

    return {
        onUpdate,
        onDelete,
        readOnly,
    };
};
