import { formatNumber } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetCropDestinationsQuery } from '@modules/encoding/modules/rotation/api/cropDestinationApi';

export const useComputeCropDestinationInfoBoxData = () => {
    const { t } = useTranslation();
    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { data: cropDestinations, isLoading } = useGetCropDestinationsQuery();

    const computeCropDestinationInfoBoxData = ({ cropDestinationId }: { cropDestinationId: number }) => {
        const cropDestination = cropDestinations?.find((c) => c.id === cropDestinationId);
        const cropDestinationName = t(`static-data.slug.${cropDestination?.slug}`) ?? '';
        const fieldCrops = fieldCropState.list.filter(
            (fsfc) => fsfc.crop_destination_static_data_id === cropDestinationId,
        );
        const numberOfAssignedFields = fieldCrops.length;
        const assignedFieldsTotalArea = fieldCrops.reduce((acc, fieldCrop) => {
            const fieldId = fieldCrop.farm_season_field_id;
            const fieldArea = fieldState.getById(fieldId)?.area;

            return acc + (fieldArea ?? 0);
        }, 0);
        const formattedAssignedFieldsTotalArea = formatNumber(assignedFieldsTotalArea, 1, 0);

        const cropDestinationImage =
            cropDestination?.slug === 'crop-destination-food' ? '/assets/images/food.svg' : '/assets/images/feed.svg';

        return {
            isLoading,
            id: cropDestinationId,
            cropDestinationName,
            cropDestinationImage,
            numberOfAssignedFields,
            assignedFieldsTotalArea: formattedAssignedFieldsTotalArea,
        };
    };

    return { computeCropDestinationInfoBoxData, isLoading };
};
