/* ---------------------------- route parameters ---------------------------- */
import { constructRouteWithParams, RouteParamsT, RouteParamsToStringT } from '@shared/utils/routesHelper';
import {
    OPERATION_TYPE_SLUGS,
    OperationTypeSlugT,
} from '@shared/entities/staticOperationType/staticOperationType.types';

const fieldCropId = 'fieldCropId';
type FieldCropId = typeof fieldCropId;

const operationId = 'operationId';
type OperationId = typeof operationId;

/* -------------------------------------------------------------------------- */

export const technicalItineraryRouteConfig = {
    introduction: '',
    fieldCrop: {
        base: `:${fieldCropId}`,
        globalCharacteristics: 'global-characteristics',
        operationsTimeline: 'operations-timeline',
        summary: 'summary',
        operationDuplication: `operation-duplication`,
        operations: {
            operationsForm: `operations-form/:${operationId}`,
            sprayingOperationForm: `spraying/:${operationId}`,
            coverCropDestructionOperationForm: `cover-crop-destruction/:${operationId}`,
            coverCropSeedingOperationForm: `cover-crop-seeding/:${operationId}`,
            deepWorkMachineOperationForm: `deep-work-machine/:${operationId}`,
            destructionOperationForm: `destruction/:${operationId}`,
            harvestOperationForm: `harvest/:${operationId}`,
            mineralFertilisationOperationForm: `mineral-fertilisation/:${operationId}`,
            organicFertilisationOperationForm: `organic-fertilisation/:${operationId}`,
            ploughOperationForm: `plough/:${operationId}`,
            seedingOperationForm: `seeding/:${operationId}`,
            companionSeedingOperationForm: `companion-seeding/:${operationId}`,
            shallowWorkMachineOperationForm: `shallow-work-machine/:${operationId}`,
            weedingMachineOperationForm: `weeding-machine/:${operationId}`,
        },
    },
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;

const introduction = () => `${technicalItineraryRouteConfig.introduction}`;

const globalCharacteristics = (params: GlobalCharacteristicsParamsT) => {
    return constructRouteWithParams(
        `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.globalCharacteristics}`,
        params,
    );
};
export type GlobalCharacteristicsParamsT = RouteParamsT<FieldCropId>;

const operationTimeline = (params: OperationTimelineParamsT) => {
    return constructRouteWithParams(
        `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operationsTimeline}`,
        params,
    );
};
type OperationTimelineParamsT = RouteParamsT<FieldCropId>;

const fieldCropSummary = (params: FieldCropSummaryParamsT) => {
    return constructRouteWithParams(
        `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.summary}`,
        params,
    );
};
type FieldCropSummaryParamsT = RouteParamsT<FieldCropId>;

const operationDuplication = (params: OperationDuplicationParamsT) => {
    return constructRouteWithParams(
        `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operationDuplication}`,
        params,
    );
};
type OperationDuplicationParamsT = RouteParamsT<FieldCropId>;

const operationForm = (params: OperationFormParamsT, operationSlug?: OperationTypeSlugT) => {
    switch (operationSlug) {
        case OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.coverCropSeedingOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.coverCropSeedingOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SPRAYING_ALL:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.sprayingOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.HARVEST_CREATION:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.harvestOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.HARVEST_DESTRUCTION:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.destructionOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.FERTILISATION_MINERAL:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.mineralFertilisationOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.FERTILISATION_ORGANIC:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.organicFertilisationOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SOIL_WORK_DEEP_WORK_MACHINE:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.deepWorkMachineOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SOIL_WORK_PLOUGH:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.ploughOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SOIL_WORK_SHALLOW_WORK_MACHINE:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.shallowWorkMachineOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SOIL_WORK_WEEDING_MACHINE:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.weedingMachineOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SEEDING_SEEDING:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.seedingOperationForm}`,
                params,
            );
        case OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING:
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.companionSeedingOperationForm}`,
                params,
            );
        default:
            // TODO: Error / redirection ?
            return constructRouteWithParams(
                `${technicalItineraryRouteConfig.fieldCrop.base}/${technicalItineraryRouteConfig.fieldCrop.operations.operationsForm}`,
                params,
            );
    }
};

type OperationFormParamsT = RouteParamsT<FieldCropId | OperationId>;

/* -------------------------------------------------------------------------- */

/* ------------------- Route params to type useParams<X>() ------------------ */
export type TechnicalItineraryRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
    globalCharacteristics: RouteParamsToStringT<GlobalCharacteristicsParamsT>;
    operationTimeline: RouteParamsToStringT<OperationTimelineParamsT>;
    fieldCropSummary: RouteParamsToStringT<FieldCropSummaryParamsT>;
    operationForm: RouteParamsToStringT<OperationFormParamsT>;
    operationPloughingForm: RouteParamsToStringT<OperationFormParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    introduction,
    globalCharacteristics,
    operationTimeline,
    operationForm,
    fieldCropSummary,
    operationDuplication,
};
