import { useFarmSeasonProfileComputed } from '@modules/encoding/modules/farmProfile/hooks/useFarmSeasonProfileComputed';
import { useFarmProfileOptions } from '@modules/encoding/modules/farmProfile/hooks/useFarmProfileOptions';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { isObjectEmpty } from '@shared/utils/isObjectEmpty';
import { useEffect, useRef } from 'react';
import { useCreateFarmSeasonProfile } from '@modules/encoding/modules/farmProfile/hooks/useCreateFarmSeasonProfile';
import { useCopyFarmSeasonProfileFromPrevious } from '@modules/yoy/hooks/useCopyFarmSeasonProfileFromPrevious';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const useCreateProfileIfNotExists = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { isBaseline, isHistoryFarmSeason, isLoading: isLoadingBaselineFs } = useBaselineFarmSeason();

    // Get profile
    const { data, isLoading: isLoadingDefaultValues } = useFarmSeasonProfileComputed({ farmSeasonId: currentSeasonId });
    const { isLoading: isLoadingOptions } = useFarmProfileOptions();
    // If not profile, we receive an empty object
    const hasProfile = !!data && !isObjectEmpty(data);

    const { createProfile, isLoading: isLoadingProfileCreate } = useCreateFarmSeasonProfile({
        farmSeasonId: currentSeasonId,
    });
    const { copyProfileFromPrevious, isLoading: isLoadingPreviousProfileCopy } = useCopyFarmSeasonProfileFromPrevious();

    const isLoading =
        isLoadingDefaultValues ||
        isLoadingOptions ||
        isLoadingPreviousProfileCopy ||
        isLoadingBaselineFs ||
        isLoadingProfileCreate;

    // ------------- Baseline profile -------------
    // Refs are used to prevent multiple calls
    const hasBaselineProfileRef = useRef(false);

    useEffect(() => {
        if (!isLoading && isBaseline && !hasProfile && !hasBaselineProfileRef.current) {
            createProfile();
            hasBaselineProfileRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isBaseline, hasProfile]);

    // ------------- YoY profile -------------
    const hasYoyProfileRef = useRef(false);
    const canCopyProfileFromPrevious = !isBaseline && !isHistoryFarmSeason;

    useEffect(() => {
        if (!isLoadingDefaultValues && !hasProfile && canCopyProfileFromPrevious && !hasYoyProfileRef.current) {
            copyProfileFromPrevious(currentSeasonId);
            hasYoyProfileRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingDefaultValues, hasProfile, canCopyProfileFromPrevious, currentSeasonId]);

    return { isProfileReady: hasProfile, isLoading };
};
