/* ---------------------------- route parameters ---------------------------- */
import { constructRouteWithParams, RouteParamsT, RouteParamsToStringT } from '@shared/utils/routesHelper';

const irrigationId = 'irrigationId';
type IrrigationId = typeof irrigationId;

/* -------------------------------------------------------------------------- */

export const irrigationRoutesConfig = {
    introduction: '',
    irrigation: {
        base: `:${irrigationId}`,
        form: '',
        duplication: 'duplication',
    },
} as const;

/* --------------------------------- Routes --------------------------------- */
// eslint-disable-next-line @typescript-eslint/ban-types
export type IntroductionParamsT = {};

const introduction = () => `${irrigationRoutesConfig.introduction}`;

const irrigationForm = (params: IrrigationFormParamsT) => {
    return constructRouteWithParams(
        `${irrigationRoutesConfig.irrigation.base}/${irrigationRoutesConfig.irrigation.form}`,
        params,
    );
};
export type IrrigationFormParamsT = RouteParamsT<IrrigationId>;
const irrigationDuplication = (params: IrrigationDuplicationParamsT) => {
    return constructRouteWithParams(
        `${irrigationRoutesConfig.irrigation.base}/${irrigationRoutesConfig.irrigation.duplication}`,
        params,
    );
};
export type IrrigationDuplicationParamsT = RouteParamsT<IrrigationId>;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type IrrigationRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
    irrigationForm: RouteParamsToStringT<IrrigationFormParamsT>;
    duplication: RouteParamsToStringT<IrrigationDuplicationParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    introduction,
    irrigationForm,
    irrigationDuplication,
};
