import { Card } from '@soil-capital/ui-kit/components';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { IconCover } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';

export const CoverCroppingSummary = () => {
    const { t } = useTranslation();
    const { classes } = useFieldCropSummaryActionPageStyle();

    return (
        <Card className={classes.container}>
            <div className={classes.containerHeader}>
                <IconCover data-testid="icon-cover" />
                <Typography variant="caps">
                    {t('encoding-technical-itinerary.field-crop-summary.cover-crop')}
                </Typography>
            </div>
        </Card>
    );
};
