import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFarmProfileActionPageStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
}));
