import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { FarmProfileFormSkeleton } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileFormSkeleton';
import { useFarmProfileProgress } from '@modules/encoding/modules/farmProfile/pages/FarmProfileActionPage/useFarmProfileProgress';
import { encodingShared } from '@modules/encoding/shared';
import { HelpBox } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { FarmProfileForm } from '../../components/FarmProfileForm/FarmProfileForm';
import { useFarmProfileActionPageStyles } from './FarmProfileActionPage.style';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { FarmProfileCommentForm } from '@modules/encoding/modules/farmProfile/components/FarmProfileCommentForm/FarmProfileCommentForm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useCreateProfileIfNotExists } from '@modules/encoding/modules/farmProfile/hooks/useCreateProfileIfNotExists';

export const FarmProfileActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { readOnly } = useFarmSeasonReadOnly();
    const { classes } = useFarmProfileActionPageStyles();
    const { t } = useTranslation();

    const { canContinue } = useFarmProfileProgress({ farmSeasonId: currentSeasonId });

    const { isLoading, isProfileReady } = useCreateProfileIfNotExists();

    const handleBackClick = () => navigate(`../${encodingRoutesConfig.PREPARATION}`);
    const handleGoNext = () => navigate(`../${encodingRoutesConfig.ROTATION}`);

    return (
        <encodingShared.ActionPage
            title={t('encoding-farm-profile.action-page.title')}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                disabled: !canContinue,
                loading: isLoading,
                onClick: handleGoNext,
                children: t('constants.continue'),
            }}
            readOnly={readOnly}
        >
            <main className={classes.container}>
                <HelpBox>{t('encoding-farm-profile.help-box')}</HelpBox>
                {!isProfileReady ? <FarmProfileFormSkeleton /> : <FarmProfileForm />}
                <FarmProfileCommentForm />
            </main>
        </encodingShared.ActionPage>
    );
};
