import { BoxedImg, CircularProgress } from '@soil-capital/ui-kit/components';
import { IconChevronDown, IconChevronRight } from '@soil-capital/ui-kit/icons';
import { Typography, Collapse } from '@soil-capital/ui-kit/material-core';
import useIntroProgressItemLogic from './useIntroProgressItem.logic';
import useIntroProgressItemStyle from './useIntroProgressItem.style';
import { IntroProgressItemPropsT } from './IntroProgressItem.types';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';
import { Tooltip } from '@soil-capital/ui-kit';

/**
 * the component used in intro screens which is collapsable (only if children provided), can display an image, a text + handle the progress
 */
export const IntroProgressItem = ({
    text,
    subText,
    children,
    image,
    infoText,
    progress,
    onClick,
    className,
    disabled,
    openDefault = false,
    hoverText,
}: IntroProgressItemPropsT) => {
    const isCollapsable = !!children;
    const { handleClickedItem, isCollapseOpen } = useIntroProgressItemLogic({ onClick, openDefault });
    const hasSrcImage = typeof image === 'string';
    const { classes, cx } = useIntroProgressItemStyle({ hasSrcImage: hasSrcImage });

    return (
        <div className={className}>
            <Tooltip title={hoverText}>
                <button
                    onClick={handleClickedItem}
                    disabled={disabled}
                    className={cx(classes.introProgressItem, disabled && classes.disabled)}
                >
                    {isCollapsable && (isCollapseOpen ? <IconChevronDown /> : <IconChevronRight />)}
                    {hasSrcImage ? <BoxedImg size="medium" src={image} imgPosition="top" /> : image}
                    <div className={classes.textContainer}>
                        <TextEllipsisTooltip text={text} tooltipProps={{ text }} />
                        {infoText && (
                            <TextEllipsisTooltip
                                text={infoText}
                                tooltipProps={{ text: infoText }}
                                typographyProps={{ color: 'neutral', variant: 'caps' }}
                            />
                        )}
                        {subText && (
                            <Typography color="neutral" variant="caps">
                                {subText}
                            </Typography>
                        )}
                    </div>
                    {progress && progress.max !== 0 && (
                        <div className={classes.progressContainer}>
                            <Typography color="neutral" className={classes.progressText}>
                                {progress.text}
                            </Typography>
                            <CircularProgress
                                className={classes.progressIcon}
                                value={(progress.current / progress.max) * 100}
                            />
                        </div>
                    )}
                </button>
            </Tooltip>
            <Collapse in={isCollapseOpen} className={classes.collapseContainer}>
                {children}
            </Collapse>
        </div>
    );
};
