import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { ProgressDetailItemT, TimelineItemT } from './OperationTimelineNav.types';
import { matchPath, useLocation } from 'react-router-dom';
import { PROGRESS_DETAIL_CATEGORIES, ProgressDetailCategoryT } from '@shared/entities/progress/progress.types';
import technicalItineraryRoutes from '../../technicalItinerary.routes';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import entities from '@shared/entities';
import { useGetSeedingOperationTitle } from '../../hooks/useGetSeedingOperationTitle';
import { useTranslation } from 'react-i18next';
import { useGetOperationDateString } from '../../hooks/useGetOperationDateString';

export const useGetOperationTimelineItemsProperties = (
    currentSeasonId: number,
    fieldCropId: number,
    operationId: number | undefined,
    progressDetailItems: ProgressDetailItemT[],
) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const basePath = `/${currentSeasonId}/${encodingRoutesConfig.TECHNICAL_ITINERARY}/`;

    const { operationState } = entities.operation.useState({
        farmSeasonId: currentSeasonId,
        farmSeasonFieldCropId: fieldCropId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { getOperationDateString } = useGetOperationDateString();
    const { getSeedingOperationTitle } = useGetSeedingOperationTitle();

    const isGlobalCharacteristicsCategory = (category: string) =>
        category === PROGRESS_DETAIL_CATEGORIES.GLOBAL_CHARACTERISTICS;
    const isOperationTimelineCategory = (category: string) =>
        category === PROGRESS_DETAIL_CATEGORIES.OPERATIONS_TIMELINE;

    const isRollerOperation = (operationTypeId?: number) => {
        return staticOperationTypeState?.getById(operationTypeId)?.slug === OPERATION_TYPE_SLUGS.SOIL_WORK_ROLLER;
    };
    const isSeedingOperation = (operationTypeId?: number) =>
        staticOperationTypeState?.getById(operationTypeId)?.slug === OPERATION_TYPE_SLUGS.SEEDING_SEEDING ||
        staticOperationTypeState?.getById(operationTypeId)?.slug === OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING;
    const isSprayingOperation = (operationTypeId?: number) =>
        staticOperationTypeState?.getById(operationTypeId)?.slug === OPERATION_TYPE_SLUGS.SPRAYING_ALL;

    const isActiveLink = (
        progressDetailOperationId: number | undefined,
        progressDetailCategory: ProgressDetailCategoryT,
    ) => {
        return (
            operationId === progressDetailOperationId ||
            (matchPath(basePath + technicalItineraryRoutes.globalCharacteristics({ fieldCropId }), pathname) !== null &&
                isGlobalCharacteristicsCategory(progressDetailCategory)) ||
            (matchPath(basePath + technicalItineraryRoutes.operationTimeline({ fieldCropId }), pathname) !== null &&
                isOperationTimelineCategory(progressDetailCategory))
        );
    };

    const isDisabled = (category: string, operationTypeId?: number) => {
        const isGlobalCharacteristicsDone = progressDetailItems.some((progressDetail) => {
            return isGlobalCharacteristicsCategory(progressDetail.category) && progressDetail.done;
        });
        const isOperationTimelineDone = progressDetailItems.some((progressDetail) => {
            return isOperationTimelineCategory(progressDetail.category) && progressDetail.done;
        });

        switch (category) {
            case PROGRESS_DETAIL_CATEGORIES.OPERATIONS_TIMELINE:
                return !isGlobalCharacteristicsDone;
            case PROGRESS_DETAIL_CATEGORIES.OPERATION_FORM:
            case 'duplication':
                return !isGlobalCharacteristicsDone || !isOperationTimelineDone || isRollerOperation(operationTypeId);
            case 'summary':
                return !isGlobalCharacteristicsDone || !isOperationTimelineDone;
            default:
                return false;
        }
    };

    const getStatus = (progressDetail: ProgressDetailItemT) => {
        let operationStatus: TimelineItemT['status'] = 'not-started';
        if (
            isDisabled(progressDetail.category, progressDetail.operationTypeId) &&
            !isRollerOperation(progressDetail.operationTypeId)
        ) {
            operationStatus = 'disabled';
        } else if (progressDetail.done) {
            operationStatus = 'done';
        } else if (isActiveLink(progressDetail.operationId, progressDetail.category) && !progressDetail.done) {
            operationStatus = 'ongoing';
        }

        return operationStatus;
    };

    const getTitle = (progressDetail: ProgressDetailItemT) => {
        // Title is different for seeding operations (seeding or companion seeding)
        return isSeedingOperation(progressDetail.operationTypeId) && progressDetail.operationId
            ? getSeedingOperationTitle(progressDetail.operationId)
            : t(progressDetail.translationSlug ?? '');
    };

    const getSubTitle = (progressDetail: ProgressDetailItemT) => {
        return progressDetail.operationTypeId
            ? getOperationDateString(
                  progressDetail.operationDate,
                  isSprayingOperation(progressDetail.operationTypeId)
                      ? t('encoding-technical-itinerary.all-date')
                      : t('encoding-technical-itinerary.no-date'),
              )
            : '';
    };

    const getTooltip = (progressDetailItem: ProgressDetailItemT) => {
        return isRollerOperation(progressDetailItem.operationTypeId)
            ? t('encoding-technical-itinerary.contextual-panel.roller-disabled-tooltip')
            : t('encoding-technical-itinerary.contextual-panel.operation-disabled-tooltip');
    };

    const getOperationId = (progressDetailItem: ProgressDetailItemT) => {
        const operationType = staticOperationTypeState?.getById(progressDetailItem.operationTypeId);

        if (operationType?.slug === OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION) {
            return operationState.list.find(
                (operation) =>
                    staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                    OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING,
            )?.id;
        }

        return progressDetailItem.operationId;
    };

    return {
        isActiveLink,
        isDisabled,
        getStatus,
        getTitle,
        getSubTitle,
        getTooltip,
        getOperationId,
    };
};
