import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

// Make sure this is kept in sync with PROGRESS_SLUGS @shared/entities/progress/progress.types for routing from progress slugs
export const encodingRoutesConfig = {
    PREPARATION: PROGRESS_SLUGS.PREPARATION,
    FARM_PROFILE: PROGRESS_SLUGS.FARM_PROFILE,
    ROTATION: PROGRESS_SLUGS.ROTATION,
    TECHNICAL_ITINERARY: PROGRESS_SLUGS.TECHNICAL_ITINERARY,
    DRAINAGE: PROGRESS_SLUGS.DRAINAGE,
    IRRIGATION: PROGRESS_SLUGS.IRRIGATION,
    AGROFORESTRY: PROGRESS_SLUGS.AGROFORESTRY,
    LIVESTOCK: PROGRESS_SLUGS.LIVESTOCK,
    SOIL_ANALYSIS: PROGRESS_SLUGS.SOIL_ANALYSIS,
    HISTORY: PROGRESS_SLUGS.HISTORY,
    ENCODING_DONE: 'encoding-done',
} as const;
