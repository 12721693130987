import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import {
    useCreateHistoricalFarmSeasonsMutation,
    useLazyGetHistoricalFarmSeasonsQuery,
} from '@modules/encoding/modules/history/api/historyApi';
import { useIsLoadingQueries } from '@shared/hooks/useIsLoadingQueries';
import { useEffect, useState } from 'react';
import { useHistoryProgress } from '@modules/encoding/modules/history/hooks/useHistoryProgress';
import { HISTORY_PROGRESS_ENUM, HistoryFarmSeasonT } from '@modules/encoding/modules/history/api/historyApi.types';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useNavigate } from 'react-router-dom';

let hasCreatedHistoryFarmSeason = false;

export const useHistoryContentPageLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const navigate = useNavigate();
    const [getHistoryFarmSeasons, { isLoading: isLoadingHistoryFarmSeasons }] = useLazyGetHistoricalFarmSeasonsQuery();
    const [createMutation, { isLoading: isCreatingHistoryFarmSeasons }] = useCreateHistoricalFarmSeasonsMutation();
    const [historyFarmSeasons, setHistoryFarmSeasons] = useState<HistoryFarmSeasonT[]>([]);
    const { isLoading } = useIsLoadingQueries();

    useEffect(() => {
        (async () => {
            if (!currentSeasonId || historyFarmSeasons.length > 0) return;

            let historyFarmSeasonsResp = await getHistoryFarmSeasons({
                baselineFarmSeasonId: currentSeasonId,
            }).unwrap();

            if (historyFarmSeasonsResp.length === 0 && !hasCreatedHistoryFarmSeason) {
                hasCreatedHistoryFarmSeason = true;
                historyFarmSeasonsResp = await createMutation({ baselineFarmSeasonId: currentSeasonId }).unwrap();
            }

            setHistoryFarmSeasons(historyFarmSeasonsResp);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSeasonId]);

    const {
        maxRotationProgress,
        currentRotationProgress,
        maxTechnicalItineraryProgress,
        currentTechnicalItineraryProgress,
        isProgressStepCompleted,
    } = useHistoryProgress();

    const orderedHistoryFarmSeasons = [...(historyFarmSeasons ?? [])]?.sort((a, b) => b.harvest_year - a.harvest_year);

    const handleContinueClicked = () => {
        if (maxRotationProgress !== currentRotationProgress) {
            const farmSeasonId = orderedHistoryFarmSeasons?.find(
                (farmSeason) => !isProgressStepCompleted(farmSeason, HISTORY_PROGRESS_ENUM.ROTATION),
            )?.id;

            if (farmSeasonId) return handleHistoryFarmSeasonRotationRedirect(farmSeasonId);
        }

        if (maxTechnicalItineraryProgress !== currentTechnicalItineraryProgress) {
            const farmSeasonId = orderedHistoryFarmSeasons?.find(
                (farmSeason) => !isProgressStepCompleted(farmSeason, HISTORY_PROGRESS_ENUM.TECHNICAL_ITINERARY),
            )?.id;

            if (farmSeasonId) return handleHistoryFarmSeasonTechnicalItineraryRedirect(farmSeasonId);
        }

        return navigate(`/${currentSeasonId}/${encodingRoutesConfig.ENCODING_DONE}`);
    };

    const handleHistoryFarmSeasonRotationRedirect = (farmSeasonId: number) =>
        navigate(`/${farmSeasonId}/${encodingRoutesConfig.ROTATION}`);
    const handleHistoryFarmSeasonTechnicalItineraryRedirect = (farmSeasonId: number) =>
        navigate(`/${farmSeasonId}/${encodingRoutesConfig.TECHNICAL_ITINERARY}`);

    return {
        isLoading: isLoading || isLoadingHistoryFarmSeasons || isCreatingHistoryFarmSeasons,
        handleContinueClicked,
        maxRotationProgress,
        currentRotationProgress,
        maxTechnicalItineraryProgress,
        currentTechnicalItineraryProgress,
        historyFarmSeasons: orderedHistoryFarmSeasons,
        handleHistoryFarmSeasonRotationRedirect,
        isProgressStepCompleted,
        handleHistoryFarmSeasonTechnicalItineraryRedirect,
    };
};
