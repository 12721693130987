import { useNavigate } from 'react-router-dom';
import { rotationRoutesConfig } from '../../rotation.routes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import {
    ROTATION_PROGRESS_STATUSES,
    RotationProgressStatusT,
} from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/useIntroductionContentProgress';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { changeFieldId } from '@modules/encoding/modules/rotation/store/filter.slice';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

type PropsT = {
    status: RotationProgressStatusT;
    maxCropDestinationProgress: number;
    isFieldCropAssignationDone: boolean;
};

export const useIntroductionContentPageLogic = ({
    status = 'PENDING',
    maxCropDestinationProgress,
    isFieldCropAssignationDone,
}: PropsT) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const { currentSeason } = useCurrentSeason();
    const currentCarbonProgrammeYear = currentSeason?.carbon_programme_year ?? 0;
    const shouldShowCropDestination = maxCropDestinationProgress > 0 && currentCarbonProgrammeYear > 0;

    const handleOpenWithFieldId = (farmSeasonFieldId: number) => {
        dispatch(changeFieldId(farmSeasonFieldId));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleRedirectToPac = () => navigate(rotationRoutesConfig.pacImport);
    const handleRedirectToCropDestination = () => navigate(rotationRoutesConfig.cropDestination);
    const handleRedirectToTechnicalItinerary = () =>
        navigate(`../${encodingRoutesConfig.TECHNICAL_ITINERARY}/${technicalItineraryRoutes.introduction()}`);

    const handleContinue = () => {
        if (status === ROTATION_PROGRESS_STATUSES.PENDING && isFieldCropAssignationDone && shouldShowCropDestination)
            return handleRedirectToCropDestination();
        if (status === ROTATION_PROGRESS_STATUSES.FINISHED) return handleRedirectToTechnicalItinerary();
        if (status === ROTATION_PROGRESS_STATUSES.PENDING) return handleOpen();
        return handleRedirectToPac();
    };

    return {
        open,
        handleOpen,
        handleOpenWithFieldId,
        handleContinue,
        handleClose,
        handleRedirectToPac,
        shouldShowCropDestination,
        handleRedirectToCropDestination,
    };
};
