import { useTranslation } from 'react-i18next';
import { useIntroductionContentData } from './useIntroductionContentData';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { Box } from '@soil-capital/ui-kit/material-core';
import { TechnicalItineraryCropList } from '@modules/encoding/modules/technicalItinerary/components/TechnicalItineraryCropList/TechnicalItineraryCropList';
import { TechnicalItineraryHelpBox } from '@modules/encoding/modules/technicalItinerary/components/TechnicalItineraryHelpBox/TechnicalItineraryHelpBox';
import { YoyDuplicationPage } from '@modules/yoy/pages/YoyDuplicationPage/YoyDuplicationPage';
import { useYoyDuplicationModule } from '@modules/yoy/hooks/useYoyDuplicationModule';

const TechnicalItineraryIntroductionContentPage = () => {
    const { t } = useTranslation();
    const {
        harvestYear,
        hasStartedItinerary,
        isDataLoading,
        allFieldsCompleted,
        handleStepRedirect,
        handleContinueClick,
    } = useIntroductionContentData();

    return (
        <ContentPage
            Title={
                <ContentPage.Title>
                    {t('encoding-technical-itinerary.introduction.title', { harvestYear })}
                </ContentPage.Title>
            }
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={
                <ContentPage.Action onClick={allFieldsCompleted ? handleStepRedirect : handleContinueClick}>
                    {hasStartedItinerary ? t('constants.continue') : t('constants.start')}
                </ContentPage.Action>
            }
            isLoading={isDataLoading}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, background: 'white' }}>
                <IntroProgressLayout
                    title={t('encoding-technical-itinerary.introduction.progress-title')}
                    leftSection={<TechnicalItineraryCropList />}
                    rightSection={<TechnicalItineraryHelpBox />}
                />
            </Box>
        </ContentPage>
    );
};

export default () => {
    const { shouldDisplay } = useYoyDuplicationModule();
    return shouldDisplay ? <YoyDuplicationPage /> : <TechnicalItineraryIntroductionContentPage />;
};
