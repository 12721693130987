/* eslint-disable @typescript-eslint/no-explicit-any */
// Source: https://github.com/Turfjs/turf/blob/99dd9da9dc92823835f9fe9b1f9c3eca665933ce/packages/turf-line-offset/index.js
// Cfr. https://github.com/Turfjs/turf/pull/1949 & https://github.com/Turfjs/turf/issues/933

import { flattenEach } from '@turf/meta';
import { getCoords, getType } from '@turf/invariant';
import {
    isObject,
    lineString,
    multiLineString,
    lengthToDegrees,
    LineString,
    Position,
    AllGeoJSON,
    Units,
} from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import booleanDisjoint from '@turf/boolean-disjoint';
import lineToPolygon from '@turf/line-to-polygon';
import difference from '@turf/difference';
import flatten from '@turf/flatten';
import _ from 'lodash';
import area from '@turf/area';
import { toleranceOptions } from './polygonHelper';
import intersection from './intersection';
import { FieldPolygonT } from '@shared/entities/field/field.types';

type optionsT = {
    units: Units;
};

export const polygonCut = (poly: FieldPolygonT['geometry'], line: LineString) => {
    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.00001;
    let i, j, forCut;
    let thickLineString, thickLinePolygon, clipped, flattenPolygons, filteredPolygons;
    let polyCoords = [];
    const offsetLine = [];
    const retVal = [] as any;

    if ((poly.type !== 'Polygon' && poly.type !== 'MultiPolygon') || line.type !== 'LineString') {
        return retVal;
    }

    const intersectPoints = lineIntersect(poly, line);
    if (intersectPoints.features.length === 0) {
        return retVal;
    }

    if (booleanDisjoint(line, poly)) {
        return retVal;
    }

    offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS,
    });
    offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS,
    });

    for (i = 0; i <= 1; i++) {
        forCut = i;
        polyCoords = [];
        for (j = 0; j < line.coordinates.length; j++) {
            polyCoords.push(line.coordinates[j]);
        }
        for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
            polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
        }
        polyCoords.push(line.coordinates[0]);

        thickLineString = lineString(polyCoords as Position[]);
        thickLinePolygon = lineToPolygon(thickLineString);
        clipped = difference(poly, thickLinePolygon);

        flattenPolygons = flatten(clipped as AllGeoJSON);
        filteredPolygons = _.filter(flattenPolygons.features, (v) => area(v) > toleranceOptions.TOLERANCE_MIN_AREA);
        if (!filteredPolygons || filteredPolygons.length < 2) {
            filteredPolygons = [];
        }
    }

    return filteredPolygons ?? [];
};

/**
 * Takes a {@link LineString|line} and returns a {@link LineString|line} at offset by the specified distance.
 *
 * @name lineOffset
 * @param {Geometry|Feature<LineString|MultiLineString>} geojson input GeoJSON
 * @param {number} distance distance to offset the line (can be of negative value)
 * @param {Object} [options={}] Optional parameters
 * @param {string} [options.units='kilometers'] can be degrees, radians, miles, kilometers, inches, yards, meters
 * @returns {Feature<LineString|MultiLineString>} Line offset from the input line
 * @example
 * var line = turf.lineString([[-83, 30], [-84, 36], [-78, 41]], { "stroke": "#F00" });
 *
 * var offsetLine = turf.lineOffset(line, 2, {units: 'miles'});
 *
 * //addToMap
 * var addToMap = [offsetLine, line]
 * offsetLine.properties.stroke = "#00F"
 */
function lineOffset(geojson: LineString, distance: number, options: optionsT) {
    // Optional parameters
    options = options || {};
    if (!isObject(options)) throw new Error('options is invalid');
    const units = options.units;

    // Valdiation
    if (!geojson) throw new Error('geojson is required');
    if (distance === undefined || distance === null || isNaN(distance)) throw new Error('distance is required');

    const type = getType(geojson);

    switch (type) {
        case 'LineString':
            return lineOffsetFeature(geojson, distance, units);
        case 'MultiLineString':
            const coords = [] as Position[][];
            flattenEach(geojson, function (feature) {
                coords.push(lineOffsetFeature(feature, distance, units).geometry.coordinates);
            });
            return multiLineString(coords);
        default:
            throw new Error('geometry ' + type + ' is not supported');
    }
}

/**
 * Line Offset
 *
 * @private
 * @param {Geometry|Feature<LineString>} line input line
 * @param {number} distance distance to offset the line (can be of negative value)
 * @param {string} [units=kilometers] units
 * @returns {Feature<LineString>} Line offset from the input line
 */
function lineOffsetFeature(line: any, distance: number, units: Units) {
    const segments = [] as any;
    const offsetDegrees = lengthToDegrees(distance, units);
    const coords = getCoords(line);
    const finalCoords = [] as any;
    coords.forEach(function (currentCoords, index) {
        if (index !== coords.length - 1) {
            const segment = processSegment(currentCoords, coords[index + 1], offsetDegrees);
            segments.push(segment);
            if (index > 0) {
                const seg2Coords = segments[index - 1];
                const intersects = intersection(segment, seg2Coords);

                // Handling for line segments that aren't straight
                if (intersects !== false) {
                    seg2Coords[1] = intersects;
                    segment[0] = intersects;
                }

                finalCoords.push(seg2Coords[0]);
                if (index === coords.length - 2) {
                    finalCoords.push(segment[0]);
                    finalCoords.push(segment[1]);
                }
            }
            // Handling for lines that only have 1 segment
            if (coords.length === 2) {
                finalCoords.push(segment[0]);
                finalCoords.push(segment[1]);
            }
        }
    });
    return lineString(finalCoords, line.properties);
}

/**
 * Process Segment
 * Inspiration taken from http://stackoverflow.com/questions/2825412/draw-a-parallel-line
 *
 * @private
 * @param {Array<number>} point1 Point coordinates
 * @param {Array<number>} point2 Point coordinates
 * @param {number} offset Offset
 * @returns {Array<Array<number>>} offset points
 */
function processSegment(point1: any, point2: any, offset: any) {
    // arccos of dot product between point1 and point2 is the angle between points (in radians)
    const angle = Math.acos(
        Math.cos((point1[1] * Math.PI) / 180) *
            Math.cos((point1[0] * Math.PI) / 180) *
            Math.cos((point2[1] * Math.PI) / 180) *
            Math.cos((point2[0] * Math.PI) / 180) +
            Math.cos((point1[1] * Math.PI) / 180) *
                Math.sin((point1[0] * Math.PI) / 180) *
                Math.cos((point2[1] * Math.PI) / 180) *
                Math.sin((point2[0] * Math.PI) / 180) +
            Math.sin((point1[1] * Math.PI) / 180) * Math.sin((point2[1] * Math.PI) / 180),
    );

    // Convert to degrees
    const L = (angle * 180) / Math.PI;

    let out1x, out2x, out1y, out2y;

    if (L > 0) {
        // Transform local cartesian coordinate system to account for lat steps being smaller farther away from the equator
        const midpointFactor = Math.cos((((point1[1] + point2[1]) / 2) * Math.PI) / 180);

        out1x = point1[0] + (offset * (point2[1] - point1[1])) / L / midpointFactor;
        out2x = point2[0] + (offset * (point2[1] - point1[1])) / L / midpointFactor;
        out1y = point1[1] + ((offset * (point1[0] - point2[0])) / L) * midpointFactor;
        out2y = point2[1] + ((offset * (point1[0] - point2[0])) / L) * midpointFactor;
    } else {
        out1x = point1[0];
        out2x = point2[0];
        out1y = point1[1];
        out2y = point2[1];
    }
    return [
        [out1x, out1y],
        [out2x, out2y],
    ];
}

export default lineOffset;
