import { SidePanel } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useDuplicationModalLeftPanelStyles } from './DuplicationModalLeftPanel.style';
import {
    DuplicationModalLeftPanelPropertiesList,
    PropertiesType,
} from '../DuplicationModalLeftPanelPropertiesList/DuplicationModalLeftPanelPropertiesList';
import { FieldT } from '@shared/entities';
import { cropNamesT } from '@shared/map/types/mapTypes';
import { DuplicationSourceItem } from '../DuplicationSourceItem/DuplicationSourceItem';

export type DuplicationFieldT = {
    field: FieldT;
    snapshot: string;
    crops: cropNamesT[];
};

type DuplicationModalLeftPanelProps<T extends DuplicationFieldT> = {
    title: string;
    description: string;
    sourceField: T;
    propertiesList?: PropertiesType[];
};

export const DuplicationModalLeftPanel = <T extends DuplicationFieldT>({
    title,
    description,
    sourceField,
    propertiesList,
}: DuplicationModalLeftPanelProps<T>) => {
    const { classes } = useDuplicationModalLeftPanelStyles();
    const { t } = useTranslation();

    return (
        <SidePanel
            header={
                <SidePanel.Header>
                    <Typography variant="h3" mb={2}>
                        {title}
                    </Typography>
                    <Typography color="neutral" variant="body2" mb={2}>
                        {description}
                    </Typography>

                    <Typography color="neutral" className={classes.sourceFieldTitle} component="h4" mt={1}>
                        {t('endoding-duplication.modal-left-panel.source-field')}
                    </Typography>
                    <DuplicationSourceItem sourceField={sourceField} />
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body>
                    {propertiesList && propertiesList.length > 0 ? (
                        <DuplicationModalLeftPanelPropertiesList propertiesList={propertiesList} />
                    ) : (
                        <></>
                    )}
                </SidePanel.Body>
            }
        />
    );
};
