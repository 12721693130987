import { NavigateFunction } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DuplicationActionPanel } from '@modules/encoding/shared/components/Duplication/DuplicationActionPanel/DuplicationActionPanel';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import agroforestryRoutes from '../../agroforestry.routes';
import { useAgroforestryDuplicationActionPageData } from './useAgroforestryDuplicationActionPageData';

export const AgroforestryDuplicationActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    // redirect if readonly as there is no point to display the duplication panel
    const { readOnly } = useFarmSeasonReadOnly();
    if (readOnly) {
        navigate(agroforestryRoutes.introduction());
    }
    const { t } = useTranslation();
    const {
        sourceField,
        excludedFieldIds,
        selectedFieldIds,
        propertiesList,
        isLoading,
        openAgroforestryDuplicationModal,
        toggleAgroforestryDuplicationModal,
        onSelect,
        onDeselect,
        handleDuplicateClick,
        handleExceptionClick,
        onModalRightPanelCopyButtonClick,
        confirmOverrideAgroforestryWarningModalProps,
    } = useAgroforestryDuplicationActionPageData({ navigate });

    return (
        <DuplicationActionPanel
            // Panel props
            panelTitle={t('encoding-agroforestry.duplication.intro.title')}
            panelSubtitle={t('encoding-agroforestry.duplication.intro.subtitle')}
            copyButtonLabel={t('encoding-agroforestry.duplication.intro.confirm')}
            exceptionButtonLabel={t('encoding-agroforestry.duplication.intro.cancel')}
            isLoading={isLoading}
            handleDuplicateClick={handleDuplicateClick}
            handleExceptionClick={handleExceptionClick}
            isExceptionButtonLoading={false}
            // Modal props
            sourceField={sourceField}
            excludedFieldIds={excludedFieldIds}
            propertiesList={propertiesList}
            onTargetFieldSelect={onSelect}
            onTargetFieldDeselect={onDeselect}
            openDuplicationModal={openAgroforestryDuplicationModal}
            toggleDuplicationModal={toggleAgroforestryDuplicationModal}
            // Modal Left Panel props
            modalTitle={t('encoding-agroforestry.duplication.modal.title')}
            modalLeftPanelTitle={t('encoding-agroforestry.duplication.modal.left-panel.title')}
            modalLeftPanelSubtitle={t('encoding-agroforestry.duplication.modal.left-panel.description')}
            // Modal Right Panel props
            modalRightPanelTitle={t('encoding-agroforestry.duplication.modal.right-panel.title')}
            modalRightPanelSubtitle={t('encoding-agroforestry.duplication.modal.right-panel.description')}
            modalRightPanelCopyButtonLabel={t('encoding-agroforestry.duplication.modal.right-panel.copy-button-label', {
                selectedFieldsCount: selectedFieldIds?.length || 0,
            })}
            modalRightPanelCopyButtonisDisabled={selectedFieldIds ? selectedFieldIds?.length <= 0 : true}
            onModalRightPanelCopyButtonClick={onModalRightPanelCopyButtonClick}
            // Modal warnings
            confirmOverrideWarningModalProps={confirmOverrideAgroforestryWarningModalProps}
            warningModalTitle={t('encoding-agroforestry.duplication.modal.warning-modal.title')}
            warningModalDescription={t('encoding-agroforestry.duplication.modal.warning-modal.description')}
            warningModalDescriptionSecondary={t(
                'encoding-agroforestry.duplication.modal.warning-modal.description-secondary',
                {
                    fieldName: sourceField?.field?.name || '',
                },
            )}
        />
    );
};
