import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { SelectOption } from '@shared/components/SelectOption/SelectOption';
import { useSoilAnalysisFormStyle } from './useSoilAnalysisForm.style';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import {
    SoilAnalysisInitialFormValuesT,
    SoilAnalysisStaticFormOptionsT,
} from '../pages/SoilAnalysisFormActionPage/useSoilAnalysisFormActionPage.logic';
import { useEffect, useState } from 'react';

export const SoilAnalysisForm = ({
    handleFormChange,
    initialFormValues,
    soilAnalysisStaticData,
}: {
    handleFormChange: (formValues: SoilAnalysisInitialFormValuesT) => void;
    initialFormValues: SoilAnalysisInitialFormValuesT | null;
    soilAnalysisStaticData: SoilAnalysisStaticFormOptionsT;
}) => {
    const { classes } = useSoilAnalysisFormStyle();
    const { t } = useTranslation();
    const { readOnly } = useFarmSeasonReadOnly();
    const [formState, setFormState] = useState<SoilAnalysisInitialFormValuesT | null>(null);

    useEffect(() => {
        if (initialFormValues && !formState) {
            setFormState(initialFormValues);
        }
    }, [initialFormValues, formState]);

    const handleFormValuesChange = (property: 'pHLevel' | 'organicCarbonLevel', value: unknown) => {
        const formValues = { ...formState, [property]: value };
        setFormState(formValues);
        handleFormChange(formValues);
    };

    return (
        <div className={classes.form}>
            <FormRow data-testid="acidity-question">
                <FormQuestion
                    question={t('encoding-soil-analysis.soil-analysis-form-action-page.acidity-question')}
                    tooltipTextKey="encoding-soil-analysis.soil-analysis-form-action-page.acidity-tooltip-title"
                    tooltipTitleKey="encoding-soil-analysis.soil-analysis-form-action-page.acidity-tooltip-description"
                />
                <SelectOption
                    onChange={(v) => {
                        handleFormValuesChange('pHLevel', v);
                    }}
                    options={soilAnalysisStaticData.pHLevels}
                    value={formState?.pHLevel}
                    disabled={readOnly}
                    placeholder={t('encoding-soil-analysis.soil-analysis-form-action-page.acidity-placeholder')}
                />
            </FormRow>
            <FormRow data-testid="organic-carbon-question">
                <FormQuestion
                    question={t('encoding-soil-analysis.soil-analysis-form-action-page.organic-carbon-question')}
                    tooltipTextKey="encoding-soil-analysis.soil-analysis-form-action-page.organic-carbon-tooltip-title"
                    tooltipTitleKey="encoding-soil-analysis.soil-analysis-form-action-page.organic-carbon-tooltip-description"
                />
                <SelectOption
                    onChange={(v) => {
                        handleFormValuesChange('organicCarbonLevel', v);
                    }}
                    options={soilAnalysisStaticData.organicCarbonLevels}
                    value={formState?.organicCarbonLevel}
                    disabled={readOnly}
                    placeholder={t('encoding-soil-analysis.soil-analysis-form-action-page.organic-carbon-placeholder')}
                />
            </FormRow>
        </div>
    );
};
