import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOperationDuplicationRightPanelStyle = makeStyles()(() => ({
    listItem: {
        width: '100%',
        padding: 0,
        '& .MuiFormControlLabel-label': {
            width: '100%',
        },
    },
    hide: {
        display: 'none',
    },
    panel: {
        flex: 1,
    },
    fieldInfoBoxOverride: {
        padding: 0,
    },
    switch: {
        marginLeft: 'auto',
        width: '100%',
    },
}));
