import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';
import entities from '@shared/entities';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { useTranslation } from 'react-i18next';
import useAgroforestryParams from '../../hooks/useAgroforestryParams';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const ConfirmDeleteAgroforestryModal = ({
    modalController,
}: {
    modalController: ModalPropsT<{ fieldId: number }, { confirmed: boolean }>;
}) => {
    const { t } = useTranslation();
    const { fieldId } = useAgroforestryParams();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const field = fieldState.getById(fieldId);

    return (
        <ConfirmModal
            type="warning"
            isOpen={modalController.isOpen}
            onClose={modalController.onClose}
            title={t('encoding-agroforestry.modal.agroforestry-delete-modal.title')}
            primaryText={{
                translationKey: 'encoding-agroforestry.modal.agroforestry-delete-modal.warning',
                translationValues: {
                    fieldName: field?.name,
                },
            }}
            confirmText={t('constants.confirm-deletion')}
            cancelText={t('constants.cancel')}
        />
    );
};
