import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import {
    FertilisersListT,
    PesticidesListT,
    TillageListT,
    TotalCamgsT,
    TotalNpkT,
} from './FieldCropSummaryActionPage.types';

export const useFieldCropSummaryActionPageData = () => {
    const { t } = useTranslation();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['fieldCropSummary']>();
    const { currentSeasonId } = useCurrentSeasonId();

    const { data: fieldCropSummary, isLoading: isLoadingFieldCropSummaryData } =
        entities.fieldCrop.useGetFieldCropSummary({
            farmSeasonId: currentSeasonId,
            id: Number(fieldCropId),
        });

    const hasCoverCrops = fieldCropSummary?.has_cover_crops;
    const tillageSummary = fieldCropSummary?.tillage_summary;
    const fertilisingSummary = fieldCropSummary?.fertilising_summary;
    const sprayingSummary = fieldCropSummary?.spraying_summary;
    const harvestingSummary = fieldCropSummary?.harvesting_summary;

    const tillageList: TillageListT = tillageSummary?.map((tillage) => {
        return {
            machinery: t(`db.machineries.${tillage.machinery_slug}`),
            ...tillage,
        };
    });

    const totalNpk: TotalNpkT = fertilisingSummary?.total_npk;
    const totalCamgs: TotalCamgsT = fertilisingSummary?.total_camgs;
    const fertilisersList: FertilisersListT = fertilisingSummary?.fertilisers?.map((fertiliser) => {
        return {
            product: `${fertiliser.quantity}${t(`constants.unit.${fertiliser.unit.toLowerCase()}`)} ${t(
                fertiliser.fertiliser_slug,
            )}`,
            ...fertiliser,
        };
    });

    const pesticidesList: PesticidesListT = sprayingSummary?.map((pesticide) => {
        return {
            product: t(`db.pesticide-type.${pesticide.pesticide_type_slug}`),
            ...pesticide,
        };
    });

    const totalYield = harvestingSummary?.yield;

    return {
        hasCoverCrops,
        tillageList,
        totalNpk,
        totalCamgs,
        fertilisersList,
        pesticidesList,
        totalYield,
        isLoadingFieldCropSummaryData: isLoadingFieldCropSummaryData,
    };
};
