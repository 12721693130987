import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import entities from '@shared/entities';
import { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FIELD_CROP_DESTINATION } from './useCropDestinationPageFormLogic';
import { useCropDestinationPageFormLogic } from './useCropDestinationPageFormLogic';
import { useCropDestinationStep } from '../../hooks/useCropDestinationStep';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const useCropDestinationPageLogic = () => {
    const [recomputeRotationProgress, { isLoading: isRotationProgressLoading }] =
        entities.progress.useComputeRotationProgress();
    const { methods } = useCropDestinationPageFormLogic();
    const { currentSeasonId } = useCurrentSeasonId();
    const { readOnly } = useFarmSeasonReadOnly();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectedFieldIds = methods.watch(FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS);
    const selectedCropDestinationId = methods.watch(FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID);
    const { fieldIdsToExcludeFromCropDestination, fieldCropsWithCropAssignationNeed, isCropDestinationStepLoading } =
        useCropDestinationStep(selectedCropDestinationId);
    const redirectToRotation = () => navigate(`/${currentSeasonId}/rotation`);
    const [updateFieldCrop] = entities.fieldCrop.useUpdate();
    const dispatch = useDispatch();

    const withFieldLoadingState = useCallback(
        async (fieldId: number, asyncFn: () => Promise<void>) => {
            dispatch(fieldSelectionAssignationSlice.actions.addLoadingFieldId(fieldId));
            dispatch(fieldSelectionAssignationSlice.actions.addDisabledFieldId(fieldId));
            try {
                await asyncFn();
            } finally {
                dispatch(fieldSelectionAssignationSlice.actions.removeLoadingFieldId(fieldId));
                dispatch(fieldSelectionAssignationSlice.actions.removeDisabledFieldId(fieldId));
            }
        },
        [dispatch],
    );

    const hasDataChange = useRef(false);

    const handleUpdateFieldCropDestination = useCallback(
        (fieldCropId: number, cropDestinationStaticDataId: number | null) => {
            return withFieldLoadingState(fieldCropId, async () => {
                hasDataChange.current = true;
                await updateFieldCrop({
                    farmSeasonId: currentSeasonId,
                    id: fieldCropId,
                    body: {
                        crop_destination_static_data_id: cropDestinationStaticDataId,
                    },
                });
            });
        },
        [currentSeasonId, updateFieldCrop, withFieldLoadingState],
    );

    const handleUpdateSelectedCropDestination = (fieldId: number, operationType: 'remove' | 'add') => {
        const updatedFieldsId = [...selectedFieldIds, fieldId];
        methods.setValue(FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS, updatedFieldsId);

        const selectedFieldCrops = fieldCropsWithCropAssignationNeed.filter((fieldCrop) =>
            updatedFieldsId.includes(fieldCrop.farm_season_field_id),
        );

        const selectedFieldCrop = selectedFieldCrops.find((fieldCrop) => fieldCrop.farm_season_field_id === fieldId);
        if (selectedFieldCrop) {
            handleUpdateFieldCropDestination(
                selectedFieldCrop.id,
                operationType === 'add' ? selectedCropDestinationId : null,
            );
        }

        dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldId]));
    };

    const onFinishClick = async () => {
        if (!readOnly) {
            await recomputeRotationProgress({ seasonId: currentSeasonId });
        }
        redirectToRotation();
    };

    useEffect(() => {
        const fieldCropWithSelectedCropDestination = fieldCropsWithCropAssignationNeed.filter(
            (fieldCrop) => fieldCrop.crop_destination_static_data_id === selectedCropDestinationId,
        );
        const fieldWithSelectedCropDestinationIds = fieldCropWithSelectedCropDestination.map(
            (fieldCrop) => fieldCrop.farm_season_field_id,
        );
        if (selectedCropDestinationId) {
            dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds(fieldWithSelectedCropDestinationIds));
        }

        if (readOnly) {
            const disabledFieldIds = fieldCropsWithCropAssignationNeed.map(
                (fieldCrop) => fieldCrop.farm_season_field_id,
            );
            dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(disabledFieldIds));
        }
    }, [dispatch, fieldCropsWithCropAssignationNeed, methods, selectedCropDestinationId, readOnly]);

    useEffect(() => {
        dispatch(fieldSelectionAssignationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        t,
        onFinishClick,
        methods,
        handleUpdateSelectedCropDestination,
        handleUpdateFieldCropDestination,
        fieldIdsToExcludeFromCropDestination,
        isRotationProgressLoading,
        isLoading: isCropDestinationStepLoading || !selectedCropDestinationId,
    };
};
