import { useCallback, useRef, useState } from 'react';

import useCurrentSeasonId from '../../hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';

export const useFieldSelectionFiltersLogic = ({
    onFilteredIdsChange,
    isDuplication,
}: {
    onFilteredIdsChange: (filteredFieldIds: Set<number> | null) => void;
    isDuplication?: boolean;
}) => {
    const selectedFieldIds = useSelector((state: SharedStateT) =>
        isDuplication ? state.fieldSelectionDuplication.selectedIds : state.fieldSelectionAssignation.selectedIds,
    );
    const [toggleSelectedFields, setToggleSelectedFields] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });

    const switchFilteredIdsRef = useRef<Set<number> | null>(null);
    const searchFilteredIdsRef = useRef<Set<number> | null>(null);

    const handleFilteredIdsChange = useCallback(() => {
        // if Both filters are used, intersect the common ids
        if (searchFilteredIdsRef.current && switchFilteredIdsRef.current) {
            onFilteredIdsChange(searchFilteredIdsRef.current.intersection(switchFilteredIdsRef.current));
            // if one filter is used, we take its filtered ids
        } else if (searchFilteredIdsRef.current || switchFilteredIdsRef.current) {
            onFilteredIdsChange(searchFilteredIdsRef.current || switchFilteredIdsRef.current);
            // No filter
        } else {
            onFilteredIdsChange(null);
        }
    }, [onFilteredIdsChange]);

    const handleSwitchChange = useCallback(
        (newValue: boolean) => {
            setToggleSelectedFields(newValue);
            if (newValue === true) {
                switchFilteredIdsRef.current = new Set(selectedFieldIds);
            } else {
                switchFilteredIdsRef.current = new Set(fieldState.list.map((field) => field.id));
            }
            handleFilteredIdsChange();
        },
        [fieldState.list, selectedFieldIds, handleFilteredIdsChange],
    );

    const handleSearchChange = useCallback(
        (newValue: string) => {
            setSearchValue(newValue);
            if (newValue === '') {
                searchFilteredIdsRef.current = new Set(fieldState.list.map((field) => field.id));
            } else {
                searchFilteredIdsRef.current = new Set(
                    fieldState.list
                        .filter((field) => field.name.toLowerCase().includes(newValue.toLowerCase()))
                        .map((field) => field.id),
                );
            }
            handleFilteredIdsChange();
        },
        [fieldState.list, handleFilteredIdsChange],
    );

    return {
        handleSwitchChange,
        handleSearchChange,
        searchValue,
        toggleSelectedFields,
    };
};
