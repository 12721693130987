import { useCopyRotationFromPreviousMutation } from '@modules/yoy/api/yoyApi';

export const useCopyRotationFromPrevious = () => {
    const [copy, { isLoading }] = useCopyRotationFromPreviousMutation();

    const copyRotation = async (farmSeasonId: number) => {
        try {
            return copy({ farmSeasonId });
        } catch (e) {
            console.error('Could not copy rotation');
        }
    };

    return { copyRotation, isLoading };
};
