import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useMemo } from 'react';

export const useCropDestinationStep = (selectedCropDestinationId?: number | null) => {
    const { cropState } = useGetFarmSeasonCrops();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    const farmSeasonCropsWithStaticCrops = useMemo(() => {
        return cropState.list.map((fieldCrop) => ({
            ...fieldCrop,
            staticCrop: staticCropState.getById(fieldCrop.crop_id),
        }));
    }, [cropState.list, staticCropState]);

    const fieldCropsToIncludeInCropDestinationStep = useMemo(() => {
        const cropIdsNeedingAssignation = new Set(
            farmSeasonCropsWithStaticCrops
                .filter(
                    (farmSeasonCrop) =>
                        farmSeasonCrop.staticCrop?.crop_destination_static_data_ids &&
                        farmSeasonCrop.staticCrop.crop_destination_static_data_ids.length > 0,
                )
                .map((farmSeasonCrop) => farmSeasonCrop.id),
        );

        return fieldCropState.list.filter((fieldCrop) => cropIdsNeedingAssignation.has(fieldCrop.farm_season_crop_id));
    }, [farmSeasonCropsWithStaticCrops, fieldCropState.list]);

    const fieldIdsWithSelectedCropDestinationOrNeedingAssignation = useMemo(() => {
        return fieldCropsToIncludeInCropDestinationStep
            .filter(
                (fieldCrop) =>
                    fieldCrop.crop_destination_static_data_id === selectedCropDestinationId ||
                    fieldCrop.crop_destination_static_data_id === null,
            )
            .map((fieldCrop) => fieldCrop.farm_season_field_id);
    }, [fieldCropsToIncludeInCropDestinationStep, selectedCropDestinationId]);

    const fieldIdsToExcludeFromCropDestination = useMemo(() => {
        return fieldState.list
            .filter((field) => !fieldIdsWithSelectedCropDestinationOrNeedingAssignation.includes(field.id))
            .map((field) => field.id);
    }, [fieldState.list, fieldIdsWithSelectedCropDestinationOrNeedingAssignation]);

    const fieldCropsWithCropAssignationNeed = useMemo(() => {
        return fieldCropState.list.filter((fieldCrop) => fieldCropsToIncludeInCropDestinationStep.includes(fieldCrop));
    }, [fieldCropState.list, fieldCropsToIncludeInCropDestinationStep]);

    const currentCropDestinationProgress = fieldCropsWithCropAssignationNeed.filter(
        (fieldCrop) => fieldCrop.crop_destination_static_data_id !== null,
    ).length;

    return {
        fieldCropsWithCropAssignationNeed,
        fieldIdsToExcludeFromCropDestination,
        maxCropDestinationProgress: fieldCropsWithCropAssignationNeed.length,
        currentCropDestinationProgress,
        isCropDestinationStepLoading:
            fieldCropState.isLoading ||
            fieldState.isLoading ||
            cropState.isLoading ||
            staticCropState.isLoading ||
            currentFarmLoading,
    };
};
