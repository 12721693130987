import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import { useCopyRotationFromPrevious } from '@modules/yoy/hooks/useCopyRotationFromPrevious';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPreviousFarmSeasonQuery } from '@modules/yoy/api/yoyApi';

export const useYoyRotationHeaderLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = useGetFarmSeasonFields();
    const { isBaseline, isHistoryFarmSeason, baselineHarvestYear } = useBaselineFarmSeason();
    const { copyRotation, isLoading } = useCopyRotationFromPrevious();
    const { data: previousFarmSeason } = useGetPreviousFarmSeasonQuery(
        isHistoryFarmSeason ? skipToken : { farmSeasonId: currentSeasonId },
    );

    const harvestYearToCopyFrom = isHistoryFarmSeason ? baselineHarvestYear : previousFarmSeason?.harvest_year;
    const hasFields = fieldState?.list?.length > 0;

    return {
        harvestYear: harvestYearToCopyFrom,
        shouldDisplayHeader: !isBaseline && !hasFields,
        copyRotation,
        isCopyingRotation: isLoading,
    };
};
