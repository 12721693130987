import { MapContext } from '@shared/map/utils/MapProvider';
import { useContext, useMemo, useEffect } from 'react';
import { FieldT } from '@shared/entities';
import { usePolygon } from '@shared/map/hook/usePolygonHook';

export const usePacImportActionPageMap = (fields: FieldT[]) => {
    const mapContext = useContext(MapContext);

    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    const {
        setFields,
        setPermanentFields,
        setSelectedFields,
        calculateCentroid,
        drawMode,
        editMode,
        splitMode,
        mergeMode,
    } = mapContext || {};

    const polygons = fields?.map((field) => field.polygon);

    const helpText = drawMode
        ? 'encoding-rotation.map.draw-help-drawMode'
        : editMode
        ? 'encoding-rotation.map.draw-help-editMode'
        : splitMode
        ? 'encoding-rotation.map.draw-help-splitMode'
        : 'encoding-rotation.map.draw-help';

    const memoizedFields = useMemo(
        () => fields,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(fields)],
    );
    useEffect(() => {
        if (setFields) {
            setFields(memoizedFields.filter((field) => !field.is_permanent));
            setPermanentFields(memoizedFields.filter((field) => field.is_permanent));
        }
        setSelectedFields([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedFields]);

    const { hoveredFeatureFieldOverview } = usePolygon();

    return {
        calculateCentroid,
        polygons,
        helpText,
        drawMode,
        editMode,
        splitMode,
        mergeMode,
        hoveredFeatureFieldOverview,
    };
};
