import { EntityState } from '@reduxjs/toolkit';
import api from '@shared/api';
import treeCache from './tree.cache';
import { TreeBodyT, TreeDuplicateBodyT, TreeEndpointParamsT, TreeT } from './tree.types';

const url = (farmSeasonId?: number) => `/v3/farm-seasons/${farmSeasonId}/trees`;

export const treeApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getTree: build.query<EntityState<TreeT>, TreeEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            providesTags: ['Tree'],
            transformResponse: treeCache.transformResponse,
        }),
        createTree: build.mutation<EntityState<TreeT>, TreeEndpointParamsT & { body: TreeBodyT }>({
            query: ({ body, farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/trees`,
                method: 'POST',
                body,
            }),
            onQueryStarted: treeCache.add('cache-then-fetch'),
            invalidatesTags: ['Progress'],
        }),
        deleteTree: build.mutation<EntityState<TreeT>, { farmSeasonId: number; farmSeasonTreeId: number }>({
            query: ({ farmSeasonId, farmSeasonTreeId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/trees/${farmSeasonTreeId}`,
                method: 'DELETE',
            }),
            onQueryStarted: async ({ farmSeasonId, farmSeasonTreeId }, apiContext) => {
                treeCache.remove('cache-only')({ farmSeasonId, id: farmSeasonTreeId }, apiContext);
            },
            invalidatesTags: ['Progress'],
        }),
        updateTree: build.mutation<
            EntityState<TreeT>,
            { farmSeasonId: number; farmSeasonFieldTreeId: number; body: TreeBodyT }
        >({
            query: ({ farmSeasonId, farmSeasonFieldTreeId, body }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/trees/${farmSeasonFieldTreeId}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId, farmSeasonFieldTreeId, body }, apiContext) => {
                treeCache.update('cache-only')({ farmSeasonId, id: farmSeasonFieldTreeId, body }, apiContext);
            },
            invalidatesTags: ['Progress'],
        }),
        duplicateTree: build.mutation<
            { trees_created: TreeT[]; trees_deleted: number[] },
            TreeEndpointParamsT & { farmSeasonFieldId: number; body: TreeDuplicateBodyT }
        >({
            query: ({ farmSeasonFieldId, body, farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/trees/${farmSeasonFieldId}/duplicate`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: duplicateTreeResponse } = await apiContext.queryFulfilled;
                // Remove deleted trees from the cache
                await treeCache.removeMany('cache-only')(
                    { farmSeasonId, ids: duplicateTreeResponse.trees_deleted },
                    apiContext,
                );
                // Upsert new or updated trees into the cache
                await treeCache.upsertMany('cache-only')(
                    { farmSeasonId, entities: duplicateTreeResponse.trees_created },
                    apiContext,
                );
            },
            invalidatesTags: ['Progress'],
        }),
    }),
});

export default {
    useGet: treeApiInstance.useGetTreeQuery,
    useCreate: treeApiInstance.useCreateTreeMutation,
    useUpdate: treeApiInstance.useUpdateTreeMutation,
    useDelete: treeApiInstance.useDeleteTreeMutation,
    useDuplicate: treeApiInstance.useDuplicateTreeMutation,
};
