import { FunctionComponent, ReactNode, SVGAttributes } from 'react';
import useContextualPanelStyle, { useContextualPanelImageStyle } from './ContextualPanel.style';
import { useLocation, useOutlet } from 'react-router-dom';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import FadeTransition from '../FadeTransition/FadeTransition';
import { ScrollSection } from '@soil-capital/ui-kit/components';

const ContextualPanel = ({
    children,
    isLoading = false,
    classes: externalClasses,
}: {
    children: ReactNode;
    isLoading?: boolean;
    classes?: {
        root?: string;
        contextualSection?: string;
    };
}) => {
    const { classes, cx } = useContextualPanelStyle();
    const { pathname } = useLocation();
    const outlet = useOutlet();

    return (
        <div className={cx(classes.ContextualPanel, externalClasses?.root)}>
            <div className={cx(classes.contextualSection, externalClasses?.contextualSection)}>
                <ScrollSection spacingX={2} id="contextual-panel-scroll-section">
                    <div className={cx(classes.childrenContainer, 'transition-panel')}>{children}</div>

                    {/* Skeleton */}
                    <div
                        data-testid="contextual-panel-skeleton"
                        className={`${classes.contextualSection} ${classes.contextualSectionSkeleton} ${
                            isLoading ? classes.contextualSectionSkeletonLoading : ''
                        }`}
                    >
                        <Skeleton sx={{ height: '60px', mb: 1, mr: 2, ml: 2 }}></Skeleton>
                        <Skeleton variant="rounded" sx={{ height: '370px', mb: 1, mr: 2, ml: 2 }} />

                        <Skeleton variant="rectangular" sx={{ height: '80px', mb: 1, mr: 2, ml: 2 }} />
                        <Skeleton variant="rectangular" sx={{ height: '80px', mb: 1, mr: 2, ml: 2 }} />
                        <Skeleton variant="rectangular" sx={{ height: '80px', mb: 1, mr: 2, ml: 2 }} />
                    </div>
                </ScrollSection>
            </div>

            <div className={classes.actionSection}>
                <FadeTransition transitionKey={pathname}>{outlet}</FadeTransition>
            </div>
        </div>
    );
};

type ContextualPanelImageProps = {
    background: string | FunctionComponent<SVGAttributes<SVGElement>>;
};

const ContextualPanelImage = ({ background }: ContextualPanelImageProps) => {
    const { classes } = useContextualPanelImageStyle({ background: background });
    return (
        <div className={classes.imageContainer}>
            <div data-testid="contextual-panel-image" className={classes.background} />
        </div>
    );
};

ContextualPanel.Image = ContextualPanelImage;

export default ContextualPanel;
