import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCropListStyle = makeStyles()((theme) => ({
    cropInfoBox: {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        minWidth: 32,
        width: 32,
        height: 32,
        padding: theme.spacing(1),
    },
    icon: {
        width: 16,
        color: theme.palette.darkScale[900],
        fontWeight: 'bold',
    },
}));
