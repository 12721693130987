import { PaymentStepModal } from '@modules/payment/components/PaymentStepModal/PaymentStepModal';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { ConfirmationStep } from '@modules/payment/components/PaymentStepModal/components/ConfirmationStep/ConfirmationStep';
import { useTranslation } from 'react-i18next';
import { useNetOffLogic } from './useNetOffLogic';

export const NetOff = () => {
    const { t } = useTranslation();
    const { isLoading, handleRedirect, harvestYear, currency, price, canOpenNewSeason, isCtaLoading } =
        useNetOffLogic();

    return (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModal stepProgress={4}>
                <ConfirmationStep
                    title={t('pages.payment.net-off.title')}
                    subtitle={t('pages.payment.net-off.subtitle', { price, currency })}
                    secondarySubtitle={!canOpenNewSeason ? t('pages.payment.net-off.subtitle.season-closed') : ''}
                    ctaText={
                        canOpenNewSeason
                            ? t('pages.payment.confirmation.cta.renewal.season-opened', { harvest_year: harvestYear })
                            : t('pages.payment.confirmation.cta.renewal.season-closed')
                    }
                    ctaAction={handleRedirect}
                    isCtaLoading={isCtaLoading}
                    isLoading={isLoading}
                />
            </PaymentStepModal>
        </Grid>
    );
};
