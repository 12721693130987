import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsEarningStyle = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    disclaimerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    resultsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
    },
    graphContainer: {
        padding: theme.spacing(2),
        minWidth: '45%',
        flexGrow: 1,
    },
    earningContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        justifyContent: 'center',
        padding: theme.spacing(3),
        background: theme.palette.darkScale[50],
        borderRadius: theme.shape.borderRadius,
        minWidth: 450,
    },
    earningHeader: {
        textAlign: 'center',
        color: theme.palette.darkScale[700],
    },
    accordion: {
        padding: theme.spacing(3),
    },
    accordionHeader: {
        padding: 0,
        fontSize: theme.typography.h2.fontSize,
        '.MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    accordionContent: {
        padding: 0,
    },
    detailedCalculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        paddingBottom: theme.spacing(3),
    },
    accordionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
}));
