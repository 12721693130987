import { makeStyles } from '@soil-capital/ui-kit/style';

const useFieldCropSummaryActionPageStyle = makeStyles()((theme) => ({
    container: {
        backgroundColor: theme.palette.primaryScale[800],
        borderRadius: theme.shape.borderRadiusS,
        color: theme.palette.darkScale[300],
        boxShadow: 'none',
        padding: `${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primaryScale[700],
            border: `7px solid ${theme.palette.primaryScale[800]}`,
        },
        '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
        },
    },
    containerHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    cardContent: {
        color: theme.palette.common.white,
        paddingTop: theme.spacing(1.5),
    },
    accordion: {
        '.MuiAccordionSummary-expandIconWrapper': {
            color: theme.palette.darkScale[300],
        },
        '.MuiAccordionSummary-content': {
            margin: 0,
            width: '90%',
        },
        '.Mui-expanded': {
            '& .hiddenCell': {
                opacity: 1,
            },
        },
        position: 'relative',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 40,
    },
    accordionHeader: {
        alignItems: 'flex-start',
        minHeight: '24px !important',
        padding: 0,
        position: 'static',
    },
    accordionHeaderContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    accordionContent: {
        padding: 0,
    },
    accordionFooter: {
        paddingLeft: theme.spacing(3.5),
        position: 'absolute',
        bottom: theme.spacing(1),
    },
    summaryTable: {
        color: theme.palette.common.white,
        padding: `${theme.spacing(1)} 0 0 ${theme.spacing(3.5)}`,
    },
    summaryHeader: {
        display: 'flex',
        gap: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.common.white,
    },
    summaryRow: {
        display: 'flex',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'left',
    },
    productCell: {
        opacity: 0,
        transition: 'opacity 0.3s',
    },
}));

export default useFieldCropSummaryActionPageStyle;
