import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { useIrrigationFormActionPageLogic } from './IrrigationFormActionPage.logic';
import { IrrigationForm } from '../../components/IrrigationForm/IrrigationForm';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        handleBackClick,
        handleContinueClick,
        fieldName,
        initialFormValues,
        handleFormChange,
        irrigationStaticData,
        isLoading,
        continueDisabled,
    } = useIrrigationFormActionPageLogic({ navigate });
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <encodingShared.ActionPage
            title={t('encoding-irrigation.irrigation-form-action-page.title', { fieldName })}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                children: t('constants.continue'),
                onClick: handleContinueClick,
                disabled: continueDisabled,
            }}
            isLoading={isLoading}
        >
            <IrrigationForm
                isReadOnly={readOnly}
                formStaticData={irrigationStaticData}
                initialFormValues={initialFormValues}
                onFormValuesChange={handleFormChange}
            />
        </encodingShared.ActionPage>
    );
};
