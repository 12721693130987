import { HelpBox, Modal } from '@soil-capital/ui-kit';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export const YoyDuplicationOverwriteModal = ({
    open,
    onClose,
    onConfirm,
    isDuplicating = false,
    harvestYear,
}: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isDuplicating?: boolean;
    harvestYear: number;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            ModalProps={{ disablePortal: true }}
            size="medium"
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            header={
                <Modal.Header>
                    <Typography variant="h3">{t('yoy.duplication.modal.overwrite.title', { harvestYear })}</Typography>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <HelpBox>{t('yoy.duplication.modal.overwrite.help-box')}</HelpBox>
                    <Typography sx={{ mt: 2, display: 'block' }}>
                        {t('yoy.duplication.modal.overwrite.warning', { harvestYear })}
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel onClick={onClose}>{t('constants.cancel')}</Modal.Cancel>
                    <Modal.Confirm loading={isDuplicating}>
                        <IconLightning />
                        <Typography>{t('yoy.duplication.modal.overwrite.confirm')}</Typography>
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
