import { CropInfoBox } from '@shared/components/CropInfoBox/CropInfoBox';
import { useTranslation } from 'react-i18next';
import { useSelectedCropStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedCrop/SelectedCrop.style';
import { IconClose, IconEdit } from '@soil-capital/ui-kit/icons';
import { Button, Tooltip } from '@soil-capital/ui-kit';
import { useSelectedCropLogic } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedCrop/useSelectedCropLogic';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const SelectedCrop = ({
    farmSeasonCropId,
    onDelete,
    onEdit,
}: {
    onEdit?: () => void;
    farmSeasonCropId: number;
    onDelete: () => void;
}) => {
    const { classes } = useSelectedCropStyles();
    const { t } = useTranslation();
    const { crop } = useSelectedCropLogic({ farmSeasonCropId });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = crop;
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <div className={classes.row}>
            <CropInfoBox className={classes.cropInfoBox} {...rest} />
            <Tooltip
                text={
                    readOnly
                        ? ''
                        : t(
                              crop.isPermanent
                                  ? 'encoding-rotation.modal.crop-list.is-permanent'
                                  : 'encoding-rotation.modal.left-panel.selected-crop-icon-tooltip-edit',
                          )
                }
            >
                <Button
                    className={classes.button}
                    variant="text"
                    onClick={onEdit}
                    disabled={readOnly || crop.isPermanent}
                >
                    <IconEdit className={classes.icon} />
                </Button>
            </Tooltip>
            <Tooltip
                text={
                    readOnly
                        ? ''
                        : t(
                              crop.isPermanent
                                  ? 'encoding-rotation.modal.crop-list.is-permanent'
                                  : 'encoding-rotation.modal.left-panel.selected-crop-icon-tooltip',
                          )
                }
            >
                <Button
                    className={classes.button}
                    variant="text"
                    onClick={onDelete}
                    disabled={readOnly || crop.isPermanent}
                >
                    <IconClose className={classes.icon} data-testid="SelectedCrop-close-icon" />
                </Button>
            </Tooltip>
        </div>
    );
};
