import { useTranslation } from 'react-i18next';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Card } from '@soil-capital/ui-kit/components';
import { IconBankNote } from '@soil-capital/ui-kit/icons';

export const HarvestingSummary = ({ totalYield }: { totalYield: number | undefined }) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropSummaryActionPageStyle();

    return (
        <Card className={classes.container}>
            <div className={classes.containerHeader}>
                <IconBankNote data-testid="icon-bank-note" />
                <Typography variant="caps">{t('encoding-technical-itinerary.field-crop-summary.yield')}</Typography>
            </div>
            <div className={classes.cardContent}>
                <Typography variant="h3">
                    {totalYield} {t('constants.unit.tons-ha')}
                </Typography>
            </div>
        </Card>
    );
};
