import { EntityState } from '@reduxjs/toolkit';
import api from '@shared/api';
import cropCache from './crop.cache';
import { ChangeStaticCropResponseT, CropBodyT, CropEndpointParamsT, CropT } from './crop.types';
import fieldCropCache from '../fieldCrop/fieldCrop.cache';

const url = (fsId?: number) => `/v3/farm-seasons/${fsId}/crops`;

export const cropApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getCrop: build.query<EntityState<CropT>, CropEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            providesTags: ['Crop'],
            transformResponse: cropCache.transformResponse,
        }),

        createCrop: build.mutation<CropT, CropEndpointParamsT & { body: CropBodyT }>({
            query: ({ body, farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Progress'],
            onQueryStarted: cropCache.add('fetch-only'),
        }),

        updateCrop: build.mutation<CropT, CropEndpointParamsT & { body: CropBodyT; id: number }>({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: cropCache.update('fetch-only'),
        }),

        deleteCrop: build.mutation<void, CropEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Progress'],
            onQueryStarted: cropCache.remove('cache-only'),
        }),

        /* --------------------------------- custom --------------------------------- */
        changeStaticCrop: build.mutation<
            ChangeStaticCropResponseT,
            CropEndpointParamsT & { body: { new_static_crop_id: number }; id: number }
        >({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}/change-static-crop`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                apiContext.queryFulfilled
                    .then(({ data: { crop_deleted, crop_updated, field_crops_created, field_crops_deleted } }) => {
                        if (crop_deleted) {
                            cropCache.remove('cache-only')({ farmSeasonId, id: crop_deleted }, apiContext);
                        }
                        if (crop_updated) {
                            cropCache.update('cache-only')(
                                { farmSeasonId, id: crop_updated.id, body: crop_updated },
                                apiContext,
                            );
                        }

                        field_crops_deleted.forEach((fieldCropId) => {
                            fieldCropCache.remove('cache-only')({ farmSeasonId, id: fieldCropId }, apiContext);
                        });
                        field_crops_created.forEach((fieldCrop) => {
                            fieldCropCache.add('cache-only')({ farmSeasonId, body: fieldCrop }, apiContext);
                        });
                    })
                    .catch((e) => console.error(e));
            },
        }),
    }),
});

export default {
    useCreate: cropApiInstance.useCreateCropMutation,
    useUpdate: cropApiInstance.useUpdateCropMutation,
    useDelete: cropApiInstance.useDeleteCropMutation,
    useGet: cropApiInstance.useGetCropQuery,
    useChangeStaticCrop: cropApiInstance.useChangeStaticCropMutation,
};
