import entities, { FieldCropT } from '@shared/entities';
import { useState } from 'react';
import { useIrrigationRouteParams } from '../../hooks/useIrrigationParams';

export default () => {
    const { seasonId, irrigationId } = useIrrigationRouteParams('irrigationForm');
    const [fieldMapSnapshotIsLoading, setFieldMapSnapshotIsLoading] = useState<boolean>(true);
    const [fieldIdentityCardIsLoading, setFieldIdentityCardIsLoading] = useState<boolean>(true);

    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: seasonId });
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: seasonId });
    const fieldId = Number(irrigationState.getById(irrigationId)?.farm_season_field_id);
    const fieldCrop = fieldCropState.getByFieldId({ fieldId })[0] as FieldCropT | undefined;

    const isLoading = fieldMapSnapshotIsLoading || fieldIdentityCardIsLoading;

    return {
        seasonId: Number(seasonId),
        fieldCropId: fieldCrop?.id,
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setFieldIdentityCardIsLoading,
    };
};
