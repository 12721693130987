import { useTranslation } from 'react-i18next';
import { useLivestockDetailsForm } from '../../hooks/useLivestockDetailsForm';
import { useLivestockDetailsFormStyles } from './LivestockDetailsForm.style';
import { MultipleSelectCheckmarks } from '@soil-capital/ui-kit/components';
import { LivestockDetailsQuantitiesForm } from '../LivestockDetailsQuantitiesForm/LivestockDetailsQuantitiesForm';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { Box } from '@soil-capital/ui-kit/material-core';
import { IconSearch } from '@soil-capital/ui-kit/icons';
import { NavigateFunction } from 'react-router-dom';

export const LivestockDetailsForm = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        staticLivestock,
        livestock,
        selectedStaticLivestock,
        updatingStaticLivestock,
        handleLivestockChange,
        readOnly,
    } = useLivestockDetailsForm({ navigate });
    const { classes } = useLivestockDetailsFormStyles();

    return (
        <div className={classes.form}>
            <form>
                <FormRow>
                    <FormQuestion question={t('encoding-livestock.details.question.types')} />
                    <MultipleSelectCheckmarks
                        values={selectedStaticLivestock}
                        options={staticLivestock}
                        className={classes.select}
                        disabledOptions={readOnly ? staticLivestock : updatingStaticLivestock}
                        placeholder={t('constants.select')}
                        getOptionLabel={(option) => t(option.translation_slug)}
                        getOptionValue={(option) => option.id}
                        getOptionKey={(option) => option.id}
                        onChange={handleLivestockChange}
                        renderValues={(values) => (
                            <Box component="span" display="flex" alignItems="center" gap={1}>
                                <IconSearch />
                                {`${values.length} ${t('constants.selected')}`}
                            </Box>
                        )}
                    />
                </FormRow>
            </form>
            {livestock.map((el) => {
                return (
                    <LivestockDetailsQuantitiesForm
                        navigate={navigate}
                        key={el.livestock_id}
                        livestock={el}
                        translationKey={
                            staticLivestock.find((staticLivestock) => staticLivestock.id === el.livestock_id)
                                ?.translation_slug || ''
                        }
                    />
                );
            })}
        </div>
    );
};
