import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { Button, HelpBox, Stack } from '@soil-capital/ui-kit';
import { Typography, Divider, Grid } from '@soil-capital/ui-kit/material-core';
import useOperationTimelineActionPageLogic from './useOperationTimelineActionPage.logic';
import OperationTimelineInputGroupCard from '../../components/OperationTimelineInputGroupCard/OperationTimelineInputGroupCard';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { ConfirmDeleteAllOperationsModal } from '../../components/ConfirmDeleteAllOperationsModal/ConfirmDeleteAllOperationsModal';
import { useDeleteAllFieldCropOperationsLogic } from './useDeleteAllFieldCropOperations.logic';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        cropName,
        getHandleBackClick,
        getHandleContinueClick,
        handleContinueClickLoading,
        harvestYear,
        isLoading,
        fieldCropId,
        seasonId,
        errorDisplayed,
        operationErrors,
        isContinueDisabled,
        readOnly,
        operations,
    } = useOperationTimelineActionPageLogic({ navigate });

    const {
        ConfirmDeleteAllOperationModalProps,
        isDeleteAllOperationsLoading,
        handleDeleteOperation,
        isDeleteAllDisabled,
    } = useDeleteAllFieldCropOperationsLogic({
        fieldCropId,
        operations,
    });

    return (
        <encodingShared.ActionPage
            isLoading={isLoading}
            title={t('encoding-technical-itinerary.timeline.title', { cropName, harvestYear })}
            leftButtonProps={{
                onClick: getHandleBackClick,
                children: t('constants.back'),
                loading: false,
            }}
            rightButtonProps={{
                onClick: getHandleContinueClick,
                children: t('encoding-technical-itinerary.timeline.continue-button'),
                disabled: isContinueDisabled,
                loading: handleContinueClickLoading,
            }}
            errorMessage={errorDisplayed}
            readOnly={readOnly}
        >
            <Stack spacing={4}>
                <Typography color="neutral">{t('encoding-technical-itinerary.timeline.description')}</Typography>
                <Grid display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Grid display="flex" flexDirection="column">
                        <Typography variant="h3">{t('encoding-technical-itinerary.timeline.subtitle')}</Typography>
                        <Typography color="neutral">
                            {t('encoding-technical-itinerary.timeline.subtitle.text')}
                        </Typography>
                    </Grid>
                    <Button
                        size="small"
                        onClick={handleDeleteOperation}
                        disabled={readOnly || isDeleteAllDisabled}
                        startIcon={<IconClose fontSize="small" />}
                    >
                        {t('encoding-technical-itinerary.timeline.delete-all')}
                    </Button>
                </Grid>
                <OperationTimelineInputGroupCard
                    period="INTERCROP"
                    title={t('encoding-technical-itinerary.timeline.intercrop-input-group')}
                    tooltip={{
                        title: t('encoding-technical-itinerary.timeline.intercrop-input-group-tooltip.title'),
                        text: t('encoding-technical-itinerary.timeline.intercrop-input-group-tooltip.text'),
                    }}
                    fieldCropId={fieldCropId}
                    seasonId={seasonId}
                    harvestYear={harvestYear}
                    displayErrors={isContinueDisabled}
                    operationErrors={operationErrors}
                />

                <div>
                    <Divider color="dark.300" />
                </div>

                <OperationTimelineInputGroupCard
                    period="CROP"
                    title={t('encoding-technical-itinerary.timeline.crop-input-group')}
                    tooltip={{
                        title: t('encoding-technical-itinerary.timeline.crop-input-group-tooltip.title'),
                        text: t('encoding-technical-itinerary.timeline.crop-input-group-tooltip.text'),
                    }}
                    fieldCropId={fieldCropId}
                    seasonId={seasonId}
                    harvestYear={harvestYear}
                    displayErrors={isContinueDisabled}
                    operationErrors={operationErrors}
                />

                <HelpBox>{t('encoding-technical-itinerary.timeline.info-box')}</HelpBox>

                <ConfirmDeleteAllOperationsModal
                    {...ConfirmDeleteAllOperationModalProps}
                    data={null}
                    confirmLoading={isDeleteAllOperationsLoading}
                />
            </Stack>
        </encodingShared.ActionPage>
    );
};
