import { IconEdit, IconCheckboxChecked, IconClose } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useEdit } from '@shared/map/hook/useEdit';
import useMapEdit from './useMapEdit';
import { EditedFieldModal } from '@modules/encoding/modules/rotation/components/EditedFieldModal/EditedFieldModal';

export const MapEdit = ({ readOnly }: { readOnly: boolean }) => {
    const { t } = useTranslation();
    const {
        edit,
        stopEditing,
        saveUpdatedField,
        editMode,
        drawMode,
        splitMode,
        mergeMode,
        editedFeatureId,
        updatedField,
        isSaveDisabled,
    } = useEdit();
    const { editFieldModalOpen, onEditFieldModalOpen, onEditFieldModalClose } = useMapEdit();

    return (
        <>
            {editMode ? (
                <>
                    <Button startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />} onClick={stopEditing}>
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!editedFeatureId || isSaveDisabled}
                        variant="contained"
                        onClick={() => {
                            editedFeatureId && saveUpdatedField(editedFeatureId);
                            onEditFieldModalOpen();
                        }}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : !drawMode && !splitMode && !mergeMode ? (
                <Button
                    onClick={edit}
                    startIcon={<IconEdit color="primary" />}
                    disabled={readOnly}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    {t('encoding-rotation.map.edit-field')}
                </Button>
            ) : null}
            {updatedField && (
                <EditedFieldModal
                    updatedField={updatedField}
                    open={editFieldModalOpen}
                    onClose={() => {
                        onEditFieldModalClose();
                        stopEditing();
                    }}
                />
            )}
        </>
    );
};
