import { NavigateFunction } from 'react-router-dom';
import { useIntroductionContentData } from './useIntroductionContentData';
import { useTranslation } from 'react-i18next';
import { HelpBox, Stack } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useIntroductionContentPageLogic } from './useIntroductionContentPageLogic';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { IrrigationFieldSelectionModal } from '../../components/IrrigationFieldSelectionModal/IrrigationFieldSelectionModal';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';

export const IntroductionContentPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        closeIrrigationFieldSelectionModal,
        handleContinue,
        isIrrigationFieldSelectionModalOpen,
        openIrrigationFieldSelectionModal,
        navigateToIrrigationForm,
    } = useIntroductionContentPageLogic({ navigate });

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    // prefetch the irrigation to smooth the irrigation modal opening
    entities.irrigation.usePrefetch({ farmSeasonId: currentSeasonId });

    const {
        fieldDataList,
        harvestYear,
        isDataLoading,
        maxIrrigationPracticeProgress,
        currentIrrigationPracticeProgress,
        maxIrrigationAssignationProgress,
        currentIrrigationAssignationProgress,
    } = useIntroductionContentData();

    return (
        <ContentPage
            Title={
                <ContentPage.Title>{t('encoding-irrigation.introduction.title', { harvestYear })}</ContentPage.Title>
            }
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={<ContentPage.Action onClick={handleContinue}>{t('constants.continue')}</ContentPage.Action>}
            isLoading={isDataLoading}
        >
            <IntroProgressLayout
                title={t('encoding-irrigation.introduction.progress-title')}
                leftSection={
                    <Stack spacing={1} mt={1}>
                        <IntroProgressItem
                            text={t('encoding-irrigation.introduction.irrigation-field-definition-progress')}
                            progress={{
                                max: maxIrrigationAssignationProgress,
                                current: currentIrrigationAssignationProgress,
                            }}
                            onClick={openIrrigationFieldSelectionModal}
                        />
                        <IntroProgressItem
                            text={t('encoding-irrigation.introduction.irrigation-practices-progress')}
                            disabled={maxIrrigationPracticeProgress === 0}
                            progress={{
                                max: maxIrrigationPracticeProgress,
                                current: currentIrrigationPracticeProgress,
                            }}
                        >
                            {fieldDataList.length > 0 && (
                                <Stack spacing={1} mt={1}>
                                    {fieldDataList.map((fieldData) => (
                                        <IntroProgressItem
                                            key={fieldData.id}
                                            text={fieldData.name}
                                            infoText={fieldData.infoText}
                                            progress={{
                                                max: fieldData.maxProgress,
                                                current: fieldData.currentProgress,
                                            }}
                                            image={fieldData.image}
                                            onClick={() => navigateToIrrigationForm({ fieldId: fieldData.id })}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </IntroProgressItem>
                    </Stack>
                }
                rightSection={
                    <HelpBox>
                        <Typography variant="h3">{t('encoding-irrigation.introduction.help-title')}</Typography>

                        {t('encoding-irrigation.introduction.help-subtitle-1', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-irrigation.introduction.help-subtitle-1')}
                            </Typography>
                        )}
                        {t('encoding-irrigation.introduction.help-description-1', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-irrigation.introduction.help-description-1', { harvestYear })}
                            </Typography>
                        )}

                        {t('encoding-irrigation.introduction.help-subtitle-2', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-irrigation.introduction.help-subtitle-2')}
                            </Typography>
                        )}
                        {t('encoding-irrigation.introduction.help-description-2', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-irrigation.introduction.help-description-2')}
                            </Typography>
                        )}

                        {t('encoding-irrigation.introduction.help-subtitle-3', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-irrigation.introduction.help-subtitle-3')}
                            </Typography>
                        )}
                        {t('encoding-irrigation.introduction.help-description-3', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-irrigation.introduction.help-description-3')}
                            </Typography>
                        )}
                    </HelpBox>
                }
            />
            <IrrigationFieldSelectionModal
                open={isIrrigationFieldSelectionModalOpen}
                onClose={closeIrrigationFieldSelectionModal}
            />
        </ContentPage>
    );
};
