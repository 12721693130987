import { useUpdateFarmSeasonProfileMutation } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { type FarmSeasonProfileUpdatePayloadT } from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';

type useCreateOrUpdateFarmSeasonProfileProps = {
    farmSeasonId: number;
    farmSeasonProfileId?: number;
};

export const useUpdateFarmSeasonProfile = ({
    farmSeasonId,
    farmSeasonProfileId,
}: useCreateOrUpdateFarmSeasonProfileProps) => {
    const [updateMutation, { isLoading }] = useUpdateFarmSeasonProfileMutation();

    const mutate = async (data: FarmSeasonProfileUpdatePayloadT) => {
        try {
            if (farmSeasonProfileId) {
                return updateMutation({
                    farmSeasonId,
                    farmSeasonProfileId,
                    payload: data,
                });
            }
        } catch (e) {
            throw new Error('Could not update Farm season profile');
        }
    };

    return { mutate, isLoading };
};
