import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useGetProgressStepMatchingCurrentRoute } from '@shared/hooks/useGetProgressStepMatchingCurrentRoute';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

export const useIsTechnicaltineraryStepCompletedGuard = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { currentProgressStep, isLoading: isLoadingCurrentProgressStep } = useGetProgressStepMatchingCurrentRoute();

    const isLoading = progressState.isLoading || isLoadingCurrentProgressStep;

    if (isLoading) return undefined;
    if (!currentProgressStep || progressState?.list?.length === 0) return false;

    const technicaltineraryStepProgress = progressState.getBySlug(PROGRESS_SLUGS.TECHNICAL_ITINERARY);
    return technicaltineraryStepProgress?.progress_done === technicaltineraryStepProgress?.progress_total;
};

export const IsTechnicalItineraryStepCompletedGuard = (props: GuardT) => {
    const passed = useIsTechnicaltineraryStepCompletedGuard();

    return <BaseGuard passed={passed} {...props} />;
};

export const IsTechnicalItineraryStepCompletedAsyncGuard = (props: GuardT) => {
    const passed = useIsTechnicaltineraryStepCompletedGuard();
    return <BaseGuard passed={passed} renderWhileLoading {...props} />;
};
