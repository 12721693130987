import entities, { FieldCropT } from '@shared/entities';
import { useState } from 'react';
import useSoilAnalysisParams from '../../hooks/useSoilAnalysisParams';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export default () => {
    const { fieldId } = useSoilAnalysisParams();
    const { currentSeasonId } = useCurrentSeasonId();
    const [fieldMapSnapshotIsLoading, setFieldMapSnapshotIsLoading] = useState<boolean>(true);
    const [fieldIdentityCardIsLoading, setFieldIdentityCardIsLoading] = useState<boolean>(true);

    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const fieldCrop = fieldCropState.getByFieldId({ fieldId })[0] as FieldCropT | undefined;

    const isLoading = fieldMapSnapshotIsLoading || fieldIdentityCardIsLoading;

    return {
        seasonId: Number(currentSeasonId),
        fieldCropId: fieldCrop?.id,
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setFieldIdentityCardIsLoading,
    };
};
