/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsToStringT } from '@shared/utils/routesHelper';

/* -------------------------------------------------------------------------- */

export const livestockRoutesConfig = {
    base: '',
    introduction: '',
    livestockDetails: 'livestock-details-encoding',
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type RotationRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
};
