import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSelectSeasonsStyles = makeStyles()(() => ({
    select: {
        width: 150,
        height: 40,
        backgroundColor: 'white',

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            boxShadow: 'none !important',
            outline: 'none !important',
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            boxShadow: 'none !important',
            outline: 'none !important',
        },

        '& *': {
            color: 'auto',
        },
    },
}));
