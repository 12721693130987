import { Typography, Box } from '@soil-capital/ui-kit/material-core';
import { HelpBox, Loader, Textarea } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
    COMMENT_INPUT_NAME,
    useFarmProfileCommentFormLogic,
} from '@modules/encoding/modules/farmProfile/hooks/useFarmProfileCommentFormLogic';

export const FarmProfileCommentForm = () => {
    const { t } = useTranslation();
    const { methods, handleSubmit, errors, isLoadingProfile } = useFarmProfileCommentFormLogic();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (e: any) => {
        methods.clearErrors();
        handleSubmit(e);
    };

    if (isLoadingProfile) {
        return (
            <HelpBox>
                <Typography variant="body">{t('encoding-farm-profile.help-box.comment.title')}</Typography>
                <Box
                    sx={{
                        height: '263px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'white',
                        boxShadow: '0px 4px 16px 4px rgba(50, 63, 69, 0.05)',
                        border: '1px solid #D7DEE2',
                        borderRadius: '8px',
                        margin: '8px',
                    }}
                >
                    <Loader size={48} />
                </Box>
            </HelpBox>
        );
    }

    return (
        <FormProvider {...methods}>
            <HelpBox>
                <Typography variant="body">{t('encoding-farm-profile.help-box.comment.title')}</Typography>
                <Textarea
                    inputProps={{
                        ...methods.register(COMMENT_INPUT_NAME),
                    }}
                    error={!!errors.comment?.message}
                    helperText={
                        errors.comment?.message &&
                        t(`encoding-farm-profile.help-box.comment.error.${errors.comment.message}`)
                    }
                    onChange={handleChange}
                    placeholder={t('encoding-farm-profile.help-box.comment.placeholder')}
                />
            </HelpBox>
        </FormProvider>
    );
};
