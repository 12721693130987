import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useToggle } from '@shared/hooks/useToggle';
import { useStartNewSeason } from '@modules/yoy/hooks/useStartNewSeason';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCanOpenNewSeason } from '@shared/hooks/useCanOpenNewSeason';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useNewSeasonModalLogic = () => {
    const {
        open: newSeasonModalOpen,
        handleOpen: onNewSeasonModalOpen,
        handleClose: onNewSeasonModalClose,
    } = useToggle();
    const { t } = useTranslation();
    const { currentSeason } = useCurrentSeason();
    const navigate = useNavigate();
    const { canOpenNewSeason, isLoading: isLoadingCanOpenNewSeason } = useCanOpenNewSeason();
    const { startNewSeason, isLoading: isStartingNewSeason } = useStartNewSeason({
        onSuccess: (nextFarmSeasonId) => {
            navigate(`/${nextFarmSeasonId}/farm-profile`);
        },
    });

    const isLoading = isStartingNewSeason || isLoadingCanOpenNewSeason;

    const handleConfirm = async () => {
        if (!currentSeason) return;
        try {
            await startNewSeason(currentSeason.id);
        } catch (e) {
            enqueueSnackbar(t('yoy.season-opening.error'), { variant: 'error' });
            return;
        }
        onNewSeasonModalClose();
    };

    useEffect(() => {
        if (canOpenNewSeason) onNewSeasonModalOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canOpenNewSeason]);

    return {
        newSeasonModalOpen,
        onNewSeasonModalOpen,
        onNewSeasonModalClose,
        showModal: canOpenNewSeason,
        isLoading,
        handleConfirm,
    };
};
