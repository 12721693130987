import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSelectedCropDestinationStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    textIndent: {
        textIndent: '-1em',
        paddingLeft: '1em',
        whiteSpace: 'pre-line',
    },
    margin: { marginBottom: theme.spacing(1) },
}));
