import { Button } from '@soil-capital/ui-kit';
import { IconArrowLeft, IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useDisplayPageSkeletonStyles } from './DisplayPageSkeleton.style';

export const DATA_TEST_ID = 'display-page-skeleton';

export const DisplayPageSkeleton = () => {
    const { classes } = useDisplayPageSkeletonStyles();
    const skeletonBgColor = 'primaryScale.800';

    return (
        <div className={classes.actionPageSkeleton} data-testid={DATA_TEST_ID}>
            <div className={classes.header}>
                <div className="left-zone">
                    <Skeleton sx={{ bgcolor: skeletonBgColor }}>
                        <Button size="large" startIcon={<IconArrowLeft />} variant="text">
                            Précédent
                        </Button>
                    </Skeleton>
                </div>

                <Skeleton sx={{ bgcolor: skeletonBgColor }}>
                    <Button size="large" startIcon={<IconCheckCircle color="inherit" />} variant="contained">
                        Continuer
                    </Button>
                </Skeleton>
            </div>

            <div className={classes.body}>
                <Skeleton sx={{ bgcolor: skeletonBgColor }} variant="text" className={classes.title} />
                <Skeleton sx={{ bgcolor: skeletonBgColor }} variant="text" className={classes.subtitle} />
                <Skeleton
                    sx={{ bgcolor: skeletonBgColor }}
                    variant="rectangular"
                    height={48}
                    className={classes.childrenSkeleton}
                />
                <Skeleton
                    sx={{ bgcolor: skeletonBgColor }}
                    variant="rectangular"
                    height={72}
                    className={classes.childrenSkeleton}
                />
                <Skeleton
                    sx={{ bgcolor: skeletonBgColor }}
                    variant="rectangular"
                    height={96}
                    className={classes.childrenSkeleton}
                />
                <Skeleton
                    sx={{ bgcolor: skeletonBgColor }}
                    variant="rectangular"
                    height={72}
                    className={classes.childrenSkeleton}
                />
            </div>
        </div>
    );
};
