import { ReactNode } from 'react';
import './fade-transition.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**
 * transition used for routing. Here is how it works:
 * 1. the entering route element appear with empty content specified with `.transition-panel` class (the element is behind the exiting due to abolute position)
 * 2. the exiting  route element disappear its content (opacity)
 * 3. the exiting route element disappear itself. Making the entering component appearing
 * 3. the entering route element make its content appear
 *
 * It doesn't work on <Routes/> but works on useRoutes(). same for <Outlet/> -> useOutlet();
 *
 * To update the duration, make sure `CSSTransition.timeout` is `2*` the `.transition-panel.transition`
 */
export default ({ children, transitionKey }: { children: ReactNode; transitionKey: string | null | undefined }) => {
    // const nodeRef = useRef(null);

    return (
        <TransitionGroup>
            <CSSTransition /* nodeRef={nodeRef} */ key={transitionKey} classNames="fade" timeout={200}>
                <>{children}</>
            </CSSTransition>
        </TransitionGroup>
    );
};
