import { useTranslation } from 'react-i18next';
import { Button } from '@soil-capital/ui-kit';
import { IconTrash } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { FarmSeasonFieldsDeleteModal } from '@modules/encoding/modules/rotation/components/FarmSeasonFieldsDeleteModal/FarmSeasonFieldsDeleteModal';
import { useToggle } from '@shared/hooks/useToggle';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const PacImportFooter = () => {
    const { t } = useTranslation();
    const { open, handleOpen, handleClose } = useToggle();
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <>
            <Button variant="text" onClick={handleOpen} disabled={readOnly}>
                <IconTrash />
                <Typography>{t('encoding-rotation.pac-file.contextual-panel.delete-all-fields-button')}</Typography>
            </Button>
            <FarmSeasonFieldsDeleteModal open={open} onClose={handleClose} />
        </>
    );
};
