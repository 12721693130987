import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';

export const useDuplicationSourceItem = ({ fieldId }: { fieldId: number }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: SharedStateT) =>
        state.fieldSelectionDuplication.loadingIds.includes(fieldId),
    );
    const setFocusedFieldId = (fieldId: number) => {
        dispatch(fieldSelectionDuplicationSlice.actions.setFocusedFieldIds([fieldId]));
    };

    return {
        isLoading,
        setFocusedFieldId,
    };
};
