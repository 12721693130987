import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import entities from '@shared/entities';
import { OperationT } from '@shared/entities/operation/operation.types';
import { useModalController } from '@shared/hooks/useModalController';

export const useDeleteAllFieldCropOperationsLogic = ({
    fieldCropId,
    operations,
}: {
    fieldCropId: number;
    operations: OperationT[];
}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [deleteAll, { isLoading: isDeleteAllOperationsLoading }] = entities.operation.useDeleteAll();
    const { open: openModalDeleteAllFieldCropOperations, ...ConfirmDeleteAllOperationModalProps } = useModalController<
        void,
        ConfirmModalCloseDataT
    >();

    const handleConfirmDeletion = async () => {
        const { confirmed } = await openModalDeleteAllFieldCropOperations();
        return confirmed;
    };

    const handleDeleteOperation = async () => {
        if (await handleConfirmDeletion()) {
            await deleteAll({ farmSeasonFieldCropId: fieldCropId, farmSeasonId: currentSeasonId });
        }
    };

    const isDeleteAllDisabled = operations.length === 0;

    return {
        ConfirmDeleteAllOperationModalProps,
        isDeleteAllOperationsLoading,
        handleDeleteOperation,
        isDeleteAllDisabled,
    };
};
