import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldSelectionFiltersStyle = makeStyles()((theme) => ({
    switchContainer: {
        textAlign: 'right',
    },
    space: { height: theme.spacing(1) },
    header: {
        height: 'auto',
    },
}));
