/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsToStringT } from '@shared/utils/routesHelper';

/* -------------------------------------------------------------------------- */

export const drainageRoutesConfig = {
    introduction: '',
} as const;

/* --------------------------------- Routes --------------------------------- */
// eslint-disable-next-line @typescript-eslint/ban-types
export type IntroductionParamsT = {};

const introduction = () => `${drainageRoutesConfig.introduction}`;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type DrainageRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    introduction,
};
