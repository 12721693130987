import { makeStyles } from '@soil-capital/ui-kit/style';

export const useLivestockDetailsFormStyles = makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    select: {
        minWidth: 250,
        maxWidth: 250,
    },
}));
