import { useParams } from 'react-router-dom';
import entities from '@shared/entities';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useIrrigationGuard = () => {
    const { seasonId: seasonIdParam, irrigationId } = useParams();
    const seasonId = Number(seasonIdParam);
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: seasonId });

    const irrigation = irrigationState.getById(Number(irrigationId));
    const isLoading = irrigationState.isLoading;

    return isLoading ? undefined : !!irrigation;
};

export const IrrigationGuard = (props: GuardT) => {
    const passed = useIrrigationGuard();

    return <BaseGuard passed={passed} {...props} />;
};

export const IrrigationAsyncGuard = (props: GuardT) => {
    const passed = useIrrigationGuard();

    return <BaseGuard passed={passed} renderWhileLoading {...props} />;
};
