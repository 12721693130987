import { useIntroductionContentProgress } from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/useIntroductionContentProgress';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useHasCropDestinationPageAccessGuard = () => {
    const { isFieldCropAssignationDone, maxCropDestinationProgress } = useIntroductionContentProgress();

    return !!maxCropDestinationProgress && isFieldCropAssignationDone;
};

export const HasCropDestinationPageAccessAsyncGuard = (props: GuardT) => {
    const passed = useHasCropDestinationPageAccessGuard();

    return <BaseGuard passed={passed} renderWhileLoading {...props} />;
};
