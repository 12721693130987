import { SelectableList } from '@soil-capital/ui-kit/components';
import { useDuplicationModalLeftPanelPropertiesListStyles } from './useDuplicationModalLeftPanelPropertiesListStyles';

export type PropertiesType = {
    key: string;
    keyLabel: string;
    valueLabel: string;
};

export type DuplicationModalLeftPanelPropertiesListProps = {
    propertiesList: PropertiesType[];
    isSelectable?: boolean;
};

export const DuplicationModalLeftPanelPropertiesList = ({
    propertiesList,
    isSelectable = false,
}: DuplicationModalLeftPanelPropertiesListProps) => {
    const { classes } = useDuplicationModalLeftPanelPropertiesListStyles();

    return (
        <SelectableList gap={1} scrollable={true} className={classes.selectableList}>
            {propertiesList.map((option) => {
                return (
                    <SelectableList.CheckboxRadio
                        key={option.key}
                        isSelectable={isSelectable}
                        name={option.key}
                        isSelected={true}
                    >
                        {option.keyLabel}: {option.valueLabel}
                    </SelectableList.CheckboxRadio>
                );
            })}
        </SelectableList>
    );
};
