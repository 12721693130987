import { useHasAtLeastTwoFieldsForACrop } from '@shared/guards/HasAtLeastTwoFieldsForCropGuard';
import { NavigateFunction, useParams } from 'react-router-dom';
import technicalItineraryRoutes, { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import { useOperationListOrder } from '../../hooks/useOperationListOrder';
import entities from '@shared/entities';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const useFieldCropSummaryActionPageLogic = (navigate: NavigateFunction) => {
    const { t } = useTranslation();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['fieldCropSummary']>();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();

    /* ------------------------------- use states ------------------------------- */
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    /* ---------------------------------- data ---------------------------------- */
    const { sortedOperations } = useOperationListOrder();
    const fieldCrop = fieldCropState.getById(fieldCropId);
    const field = fieldState.getById(fieldCrop?.farm_season_field_id);
    const crop = cropState.getById(fieldCrop?.farm_season_crop_id);
    const staticCrop = staticCropState.getById(crop?.crop_id);
    const hasAtLeastTwoFieldsForACrop = useHasAtLeastTwoFieldsForACrop();
    const { readOnly } = useFarmSeasonReadOnly();

    const continueButtonSlug = hasAtLeastTwoFieldsForACrop
        ? 'encoding-technical-itinerary.continue-to-duplication'
        : 'constants.continue';
    const isContinueDisabled =
        continueButtonSlug === 'encoding-technical-itinerary.continue-to-duplication' && readOnly;

    /* -------------------------------- functions ------------------------------- */
    const handleBackClick = () => {
        const previousOperation = sortedOperations[sortedOperations.length - 1];
        const previousStepSlug = staticOperationTypeState.getById(previousOperation?.operation_type_id)?.slug;

        navigate(
            technicalItineraryRoutes.operationForm(
                {
                    fieldCropId: fieldCropId,
                    operationId: previousOperation.id,
                },
                previousStepSlug,
            ),
        );
    };

    const handleContinueClick = async () => {
        if (hasAtLeastTwoFieldsForACrop) {
            navigate(
                technicalItineraryRoutes.operationDuplication({
                    fieldCropId: fieldCropId,
                }),
            );
        } else {
            navigate(technicalItineraryRoutes.introduction());
        }
    };

    return {
        fieldName: field?.name ?? '',
        cropName: t(staticCrop?.translation_slug ?? ''),
        isLoading: currentFarmLoading || fieldCropState.isLoading || fieldState.isLoading || cropState.isLoading,
        continueButtonSlug,
        isContinueDisabled,
        handleBackClick,
        handleContinueClick,
    };
};
