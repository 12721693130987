import { useGetFarmSeasonProfileQuery } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import {
    FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS,
    FARM_PROFILE_STATIC_DATA_TYPE,
} from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import { encodingShared } from '@modules/encoding/shared';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import entities from '@shared/entities';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useParams } from 'react-router-dom';
import useOperationTimelineList from '../../hooks/useOperationTimelineList';
import technicalItineraryRoutes, { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export default (navigate: NavigateFunction) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [track] = useTrackMutation();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['operationTimeline']>();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });

    /* -------------------------------- Entities -------------------------------- */
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const [finalizeGlobalCharacteristicsMutation, { isLoading: isFinalizeOperationsTimelineLoading }] =
        entities.fieldCrop.useFinalizeGlobalCharacteristics();
    const isPerformingMutation = globalMutationStatus === 'pending' || isFinalizeOperationsTimelineLoading;

    const farmSeasonProfile = useGetFarmSeasonProfileQuery({ farmSeasonId: currentSeasonId });
    const farmType = farmSeasonProfile.data?.static_data?.find(
        (data) => data.type === FARM_PROFILE_STATIC_DATA_TYPE['FARM_TYPE'],
    );
    const isFarmTillageConventional =
        farmType?.slug === FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS['FARM_TYPE_CONVENTIONAL'];
    const operationListForCrop = useOperationTimelineList({ period: 'CROP', fieldCropId, seasonId: currentSeasonId });
    const operationListForIntercrop = useOperationTimelineList({
        period: 'INTERCROP',
        fieldCropId,
        seasonId: currentSeasonId,
    });

    const isLoading =
        currentSeasonLoading ||
        operationListForCrop.isLoading ||
        operationListForIntercrop.isLoading ||
        currentFarmLoading ||
        fieldState.isLoading ||
        fieldCropState.isLoading ||
        cropState.isLoading ||
        staticCropState.isLoading ||
        staticSeasonState.isLoading ||
        farmSeasonProfile.isLoading;

    const currentField = fieldState.getById(fieldCropState.getById(fieldCropId)?.farm_season_field_id);
    const currentFieldCrop = fieldCropState.getById(fieldCropId);
    const cropName = t(
        staticCropState.getById(cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id)
            ?.translation_slug ?? '',
    );
    const ENcropName =
        staticCropState.getById(cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id)
            ?.name ?? '';
    const operationNbrForCrop = operationListForCrop.items.reduce((total, item) => {
        return total + item.operations.length;
    }, 0);
    const operationNbrForIntercrop = operationListForIntercrop.items.reduce((total, item) => {
        return total + item.operations.length;
    }, 0);
    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    const isContinueDisabled =
        (!isFarmTillageConventional && currentFieldCrop?.is_organic === null) ||
        currentFieldCrop?.tillage_id === null ||
        currentFieldCrop?.has_companion_crop === null ||
        (currentFieldCrop?.has_companion_crop === true && currentFieldCrop?.companion_crop_id === null);

    const { readOnly } = useFarmSeasonReadOnly();

    /* -------------------------------- Handlers -------------------------------- */

    const handleBackClick = () => () => {
        navigate(technicalItineraryRoutes.introduction());
    };
    const handleContinueClick = () => async () => {
        try {
            await finalizeGlobalCharacteristicsMutation({
                id: Number(fieldCropId),
                farmSeasonId: currentSeasonId,
            });
            track({
                event: EVENTS.CROP_OPERATION_TIMELINE_STARTED,
                properties: {
                    crop: ENcropName,
                    field: currentField?.name ?? '',
                    Interculture_operations_nb: operationNbrForIntercrop,
                    culture_operations_nb: operationNbrForCrop,
                },
            });
            !isContinueDisabled && fieldCropId && navigate(technicalItineraryRoutes.operationTimeline({ fieldCropId }));
        } catch {
            enqueueSnackbar(t('constants.error'), { variant: 'error' });
        }
    };

    /* -------------------------------------------------------------------------- */

    return {
        fieldName: currentField?.name,
        cropName,
        harvestYear,
        isLoading,
        isPerformingMutation,
        isContinueDisabled,
        handleBackClick,
        handleContinueClick,
        isFarmTillageConventional,
        readOnly,
    };
};
