import { getUsePrefetch } from '../getUsePrefetch';
import { shapeEntity } from '../shapeEntity';
import irrigationApi, { irrigationApiInstance } from './irrigation.api';
import irrigationState from './irrigation.state';

export default shapeEntity('Irrigation', {
    api: irrigationApiInstance,
    ...irrigationApi,
    useState: irrigationState,
    usePrefetch: getUsePrefetch(irrigationApi.useGet),
});
