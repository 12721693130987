import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { useTranslation } from 'react-i18next';

export type CropDestinationInfoBoxProps = {
    cropDestinationName: string;
    numberOfAssignedFields: number;
    assignedFieldsTotalArea: string;
    cropDestinationImage: string | null;
    className?: string;
};

export const CropDestinationInfoBox = ({
    cropDestinationName,
    numberOfAssignedFields,
    assignedFieldsTotalArea,
    cropDestinationImage,
    className,
}: CropDestinationInfoBoxProps) => {
    const { t } = useTranslation();

    return (
        <MediaInfoBlock
            Title={<MediaInfoBlock.Title text={cropDestinationName} />}
            Subtitle={
                <>
                    <MediaInfoBlock.Subtitle
                        text={`${numberOfAssignedFields} ${t('constants.field', {
                            count: numberOfAssignedFields,
                        })}`}
                    />
                    <MediaInfoBlock.Subtitle text={`${assignedFieldsTotalArea} HA`} />
                </>
            }
            Media={<MediaInfoBlock.Image src={cropDestinationImage} size="medium" />}
            className={className}
        />
    );
};
