import entities from '@shared/entities';
import { OperationTimelineNavDataPropsT, ProgressDetailItemT } from './OperationTimelineNav.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

export default ({ fieldCropId, seasonId }: OperationTimelineNavDataPropsT) => {
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: fieldCropId,
        farmSeasonId: seasonId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const fieldCropProgressDetails = progressState.getBySlugAndFieldCropId(
        PROGRESS_SLUGS.TECHNICAL_ITINERARY,
        fieldCropId,
    );

    const progressDetailItems: ProgressDetailItemT[] =
        fieldCropProgressDetails?.map((progressDetail) => {
            const operation = operationState.list.find(
                (o) => o.farm_season_field_crop_operation_id === progressDetail.farm_season_field_crop_operation_id,
            );

            return {
                operationId: operation?.id,
                operationTypeId: operation?.operation_type_id,
                operationDate: operation?.operation_date ?? null,
                translationSlug:
                    staticOperationTypeState.getById(operation?.operation_type_id)?.translation_slug ??
                    `encoding-technical-itinerary.contextual-panel.${progressDetail.category}`,
                done: progressDetail.is_done,
                category: progressDetail.category,
            };
        }) || [];

    return {
        progressDetailItems,
        isLoading: operationState.isLoading || staticOperationTypeState.isLoading || progressState.isLoading,
    };
};
