import entities, { SeasonT } from '@shared/entities';

export const useGetActiveSeason = (farmId?: number) => {
    const seasons = entities.season.useState({ farmId });
    const activeSeason = seasons.seasonState.list.reduce<SeasonT | null>((acc, season) => {
        if (!acc || season.season_id > acc.season_id) {
            return season;
        }
        return acc;
    }, null);

    return {
        activeSeason,
        isLoading: seasons.seasonState.isLoading,
    };
};
