import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { formatNumber } from '@shared/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import soilAnalysisRoutes from '../../soilAnalysis.routes';
import { NavigateFunction } from 'react-router-dom';

type FieldDataT = {
    id: number;
    name: string;
    image: string;
    infoText: string;
    currentFieldSoilAnalysis: number;
    maxFieldSoilAnalysis: number;
};

export const useIntroductionContentData = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const maxSoilAnalysisEncodingProgress = progressState.getBySlug(PROGRESS_SLUGS.SOIL_ANALYSIS)?.progress_total || 1;
    const currentSoilAnalysisEncodingProgress =
        progressState.getBySlug(PROGRESS_SLUGS.SOIL_ANALYSIS)?.progress_done || 0;

    const getFieldInfoText = useCallback(
        ({ fieldId, fieldArea }: { fieldId: number; fieldArea: number }) => {
            const firstFieldCrop = fieldCropState.getByFieldId({ fieldId }).at(0);
            const crop = cropState.getById(firstFieldCrop?.farm_season_crop_id);
            const staticCrop = staticCropState.getById(crop?.crop_id);
            const infoText = `${t(staticCrop?.translation_slug ?? '')} ${formatNumber(fieldArea, 2)} HA`;
            return infoText;
        },
        [cropState, fieldCropState, staticCropState, t],
    );

    const fieldDataList = useMemo(
        () =>
            harvestedFields.map<FieldDataT>((field) => {
                const infoText = getFieldInfoText({ fieldArea: field.area, fieldId: field.id });
                const fieldThumbnail = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });
                const currentFieldSoilAnalysis = [field.soil_acidity_id, field.soil_organic_carbon_id].filter(
                    (el) => el !== null,
                ).length;
                const maxFieldSoilAnalysis = 2;

                return {
                    id: field.id,
                    image: fieldThumbnail,
                    infoText,
                    name: field.name,
                    currentFieldSoilAnalysis,
                    maxFieldSoilAnalysis,
                };
            }),
        [getFieldInfoText, getFieldThumbnail, harvestedFields],
    );

    const isDataLoading = useMemo(() => {
        return (
            harvestedFieldsLoading ||
            currentFarmLoading ||
            fieldState.isLoading ||
            cropState.isLoading ||
            fieldCropState.isLoading ||
            staticCropState.isLoading
        );
    }, [
        cropState.isLoading,
        currentFarmLoading,
        fieldCropState.isLoading,
        fieldState.isLoading,
        harvestedFieldsLoading,
        staticCropState.isLoading,
    ]);

    const handleClickOnField = useCallback(
        (fieldId: number) => {
            return navigate(soilAnalysisRoutes.soilAnalysisForm({ fieldId }));
        },
        [navigate],
    );

    const redirectToNextEncodingStep = () => {
        return navigate(`../${getRouteMatchingStep(nextStepToComplete?.slug)}`);
    };

    const handleContinueClicked = () => {
        if (currentSoilAnalysisEncodingProgress === maxSoilAnalysisEncodingProgress) {
            redirectToNextEncodingStep();
        } else {
            const firstUncompletedFieldId = harvestedFields.find(
                (el) => !el.soil_acidity_id || !el.soil_organic_carbon_id,
            )?.id;
            if (firstUncompletedFieldId) {
                return navigate(soilAnalysisRoutes.soilAnalysisForm({ fieldId: firstUncompletedFieldId }));
            }
        }
    };

    const currentSoilAnalysisImportProgress = 0; // TO DO
    const maxSoilAnalysisImportProgress = 1; // TO DO

    return {
        isDataLoading,
        fieldDataList,
        currentSoilAnalysisEncodingProgress,
        maxSoilAnalysisEncodingProgress,
        currentSoilAnalysisImportProgress,
        maxSoilAnalysisImportProgress,
        handleClickOnField,
        handleContinueClicked,
    };
};
