import { encodingShared } from '@modules/encoding/shared';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { useOperationFormActionPage } from './useOperationFormActionPage.logic';
import { useFarmSeasonReadOnly } from '../../hooks/useFarmSeasonReadOnly';

export default ({
    title,
    subtitle,
    isTitleLoading,
    children,
    navigate,
    errors = new Map(),
    renderForm,
}: {
    title: string;
    subtitle?: string;
    isTitleLoading?: boolean;
    children?: ReactNode;
    navigate: NavigateFunction;
    errors?: Map<string, string>;
    renderForm?: (errorDisplayed: [string, string] | undefined) => ReactNode;
}) => {
    const { t } = useTranslation();
    const { operationSubtitle, handleBackClick, handleContinueClick, errorDisplayed } = useOperationFormActionPage(
        subtitle,
        navigate,
        errors,
    );
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <encodingShared.ActionPage
            title={title}
            subtitle={operationSubtitle}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                children: t('constants.continue'),
                onClick: handleContinueClick,
            }}
            isLoading={isTitleLoading}
            errorMessage={errorDisplayed?.[1]}
            readOnly={readOnly}
        >
            {renderForm ? renderForm(errorDisplayed) : children}
        </encodingShared.ActionPage>
    );
};
