/* ---------------------------- route parameters ---------------------------- */
import { constructRouteWithParams, RouteParamsT, RouteParamsToStringT } from '@shared/utils/routesHelper';

const fieldId = 'fieldId';
type fieldId = typeof fieldId;

/* -------------------------------------------------------------------------- */

export const agroforestryRoutesConfig = {
    introduction: '',
    agroforestry: {
        base: `:${fieldId}`,
        form: '',
        duplication: 'duplication',
    },
} as const;

/* --------------------------------- Routes --------------------------------- */
// eslint-disable-next-line @typescript-eslint/ban-types
export type IntroductionParamsT = {};

const introduction = () => `${agroforestryRoutesConfig.introduction}`;

const agroforestryForm = (params: AgroforestryFormParamsT) => {
    return constructRouteWithParams(
        `${agroforestryRoutesConfig.agroforestry.base}/${agroforestryRoutesConfig.agroforestry.form}`,
        params,
    );
};
export type AgroforestryFormParamsT = RouteParamsT<fieldId>;
const agroforestryDuplication = (params: AgroforestryDuplicationParamsT) => {
    return constructRouteWithParams(
        `${agroforestryRoutesConfig.agroforestry.base}/${agroforestryRoutesConfig.agroforestry.duplication}`,
        params,
    );
};
export type AgroforestryDuplicationParamsT = RouteParamsT<fieldId>;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type AgroforestryRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
    agroforestryForm: RouteParamsToStringT<AgroforestryFormParamsT>;
    duplication: RouteParamsToStringT<AgroforestryDuplicationParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    introduction,
    agroforestryForm,
    agroforestryDuplication,
};
