import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useFormContext } from 'react-hook-form';

export const useDuplicationSeasonSelection = () => {
    const context = useFormContext();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { watch } = context || {};

    const selectedCrops = watch('crop_ids') ?? [];
    const selectedFarmSeasonId = watch('source_season_id') ?? null;

    const selectedFarmSeason = seasonState?.list?.find((fs) => fs.season_id === selectedFarmSeasonId);
    const selectedSeason = staticSeasonState?.list?.find((s) => s.id === selectedFarmSeason?.season_id);
    const { cropState: selectedFarmSeasonCrops } = entities.crop.useState(
        { farmSeasonId: selectedFarmSeason?.id },
        { skip: !selectedFarmSeason?.id },
    );

    if (!context) {
        throw new Error('useDuplicationSeasonSelection must be used within a FormProvider');
    }

    const selectedCropsDontExistOnSelectedYear = selectedFarmSeasonId
        ? selectedCrops.filter(
              (selectedCrop: number) =>
                  !selectedFarmSeasonCrops?.list?.some((fsCrop) => fsCrop.crop_id === selectedCrop),
          )?.length > 0
        : false;

    return {
        selectedFarmSeason,
        selectedSeason,
        selectedFarmSeasonCrops,
        selectedCropsDontExistOnSelectedYear,
    };
};
