import { useParams } from 'react-router-dom';
import { IrrigationRouteParamsT } from '../irrigation.routes';

/** Get the route params for irrigation routes + convert them in number or undefined\
 * Usable under routes that match irrigation.routes.ts\
 * This expect the params are stringified numbers */
export const useIrrigationRouteParams = <T extends keyof IrrigationRouteParamsT = keyof IrrigationRouteParamsT>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _routeName: T,
) => {
    const params = useParams<IrrigationRouteParamsT[T]>();
    const keyValueArrayParams = Object.entries(params);
    const parsedKeyValueArrayParams = keyValueArrayParams.map(([paramName, paramValue]) => {
        let parsedValue = undefined;
        if (paramValue && !isNaN(Number(paramValue))) {
            parsedValue = Number(paramValue);
        }

        return [paramName, parsedValue];
    });
    const parsedParams = Object.fromEntries(parsedKeyValueArrayParams) as {
        [k in keyof IrrigationRouteParamsT[T]]: number | undefined;
    };
    return parsedParams;
};
