import { PacFieldList } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldList/PacFieldList';
import { PacFieldUpdateModal } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldUpdateModal/PacFieldUpdateModal';
import { usePacFieldUpdateFormLogic } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateFormLogic';
import { FarmSeasonFieldDeleteModal } from '@modules/encoding/modules/rotation/components/FarmSeasonFieldDeleteModal/FarmSeasonFieldDeleteModal';

export const PacImportedFields = () => {
    const {
        fieldId,
        openUpdateFieldModal,
        onEdit,
        onCloseUpdateFieldModal,
        openDeleteFieldModal,
        onDelete,
        onCloseDeleteFieldModal,
    } = usePacFieldUpdateFormLogic();

    return (
        <>
            <PacFieldList onEdit={onEdit} onDelete={onDelete} />
            {fieldId && (
                <>
                    <PacFieldUpdateModal
                        fieldId={fieldId}
                        open={openUpdateFieldModal}
                        onClose={onCloseUpdateFieldModal}
                    />
                    <FarmSeasonFieldDeleteModal
                        fieldId={fieldId}
                        open={openDeleteFieldModal}
                        onClose={onCloseDeleteFieldModal}
                    />
                </>
            )}
        </>
    );
};
