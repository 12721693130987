import { usePacFieldListStyles } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldList/PacFieldList.style';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { IconCross, IconEdit } from '@soil-capital/ui-kit/icons';
import { usePacFieldListLogic } from './usePacFieldListLogic';
import { List } from '@soil-capital/ui-kit/List';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useContext } from 'react';
import { MapContext } from '@shared/map/utils/MapProvider';
import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

type PacFieldListProps = {
    onEdit: (fieldId: number) => void;
    onDelete: (fieldId: number) => void;
};

export const PacFieldList = ({ onEdit, onDelete }: PacFieldListProps) => {
    const { classes } = usePacFieldListStyles();
    const { fields, isFetching } = usePacFieldListLogic();
    const mapContext = useContext(MapContext);
    const { recenterMapOnPolygons } = mapContext || {};
    const { t } = useTranslation();
    const { readOnly } = useFarmSeasonReadOnly();

    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    if (isFetching)
        return (
            <div className={classes.skeletonList}>
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
            </div>
        );

    return (
        <List className={classes.list}>
            {fields.map((field) => (
                <FieldCropInfoBox
                    className={classes.fieldCropInfoBox}
                    key={field.id}
                    fieldName={field.name}
                    fieldArea={`${field.area} HA`}
                    mapSnapshot={field.snapshot}
                    disabled={field.is_permanent}
                    handleFieldClick={() => recenterMapOnPolygons?.([field.polygon])}
                    icon={
                        !readOnly && (
                            <div className={classes.iconContainer}>
                                <Tooltip
                                    text={t(
                                        field.is_permanent
                                            ? 'encoding-rotation.modal.field-list.is-permanent'
                                            : 'encoding-rotation.modal.field-list.edit',
                                    )}
                                >
                                    <Button variant="text" className={classes.button} disabled={field.is_permanent}>
                                        <IconEdit
                                            data-testid={`field-${field.id}-edit`}
                                            onClick={() => onEdit(field.id)}
                                            className={classes.iconEdit}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    text={t(
                                        field.is_permanent
                                            ? 'encoding-rotation.modal.field-list.is-permanent'
                                            : 'encoding-rotation.modal.field-list.delete',
                                    )}
                                >
                                    <Button variant="text" className={classes.button} disabled={field.is_permanent}>
                                        <IconCross
                                            data-testid={`field-${field.id}-delete`}
                                            onClick={() => onDelete(field.id)}
                                            className={classes.iconCross}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    }
                />
            ))}
        </List>
    );
};
