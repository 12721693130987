import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import { formatNumber } from '@shared/utils';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const usePacFieldListLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = useGetFarmSeasonFields();
    const fields = fieldState.list;
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId });
    const { isHistoryFarmSeason, isBaseline } = useBaselineFarmSeason();

    const computedFields = fields.map((fsf) => {
        return {
            ...fsf,
            area: formatNumber(fsf.area, 1),
            snapshot: getFieldThumbnail({ fieldId: fsf.id, updatedAt: fsf.updated_at }),
            is_permanent: isHistoryFarmSeason || isBaseline ? false : fsf.is_permanent ?? false,
        };
    });

    return {
        fields: computedFields,
        isFetching: fieldState.isFetching,
    };
};
