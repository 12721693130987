import entities, { FieldT, StaticCropT } from '@shared/entities';
import { cropNamesT } from '@shared/map/types/mapTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { encodingShared } from '../..';
import { useGetFieldThumbnail } from '../../hooks/useGetFieldThumbnail';
import useCurrentSeasonId from '../../hooks/useCurrentSeasonId';
export const useFieldSelectionListLogic = ({
    filteredFieldIds,
    excludedFieldIds,
}: {
    filteredFieldIds?: Set<number> | null;
    excludedFieldIds?: number[];
}) => {
    const { t } = useTranslation();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId ?? 0 });
    const { getCropsAssignedToAField, fieldCropStateLoading } = cropState.useGetCropsAssignedToAField();
    const isLoading = cropState.isLoading || fieldCropStateLoading || staticCropState.isLoading || fieldState.isLoading;

    const fieldDataList: {
        field: FieldT;
        snapshot: string;
        crops: cropNamesT[];
    }[] = useMemo(
        () => {
            let fieldStateList = [];
            if (excludedFieldIds) {
                fieldStateList = fieldState.list.filter((el) => !excludedFieldIds.includes(el.id));
            } else {
                fieldStateList = fieldState.list;
            }
            return fieldStateList.map((field) => {
                const allCrops = getCropsAssignedToAField(field.id);
                const allStaticCrops = allCrops
                    .map((crop) => staticCropState.getById(crop.crop_id))
                    .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);

                return {
                    field,
                    snapshot: getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at }),
                    crops: allStaticCrops?.map((c) => ({
                        id: c?.id,
                        name: t(`db.crop.slug-${c.slug}`),
                    })),
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldState.list, getCropsAssignedToAField, getFieldThumbnail, staticCropState.getById, t, excludedFieldIds],
    );

    const filteredFieldDataList = useMemo(
        () =>
            !filteredFieldIds
                ? fieldDataList
                : fieldDataList.filter((fieldData) => {
                      return filteredFieldIds.has(fieldData.field.id);
                  }),
        [filteredFieldIds, fieldDataList],
    );

    return { filteredFieldDataList, isLoading };
};
