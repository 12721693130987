import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { Button, SidePanel, SelectableList, Switch, SearchInput } from '@soil-capital/ui-kit/components';
import { IconPlusCircle, IconCheckCircle, IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useOperationDuplicationRightPanelStyle } from './useOperationDuplicationRightPanel.style';
import { useTranslation } from 'react-i18next';
import { FieldCropWarningModal } from '../FieldCropWarningModal/FieldCropWarningModal';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { useDuplicationModals } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationModals';
import { formatNumber } from '@shared/utils';
import { useDuplicationFieldFilters } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationFieldFilters';
import { useCanConfirmDuplication } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useCanConfirmDuplication';
import { useFieldSelectionLogic } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useFieldSelectionLogic';
import { type DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { OPERATION_DUPLICATION_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';
import { useOperationDuplicationRightPanelMap } from './useOperationDuplicationRightPanelMap';
import { MapView } from '@shared/map/MapView';
import { useFullscreenModalMap } from '@shared/components/FullscreenModal/useFullscreenModalMap';

export type OperationDuplicationRightPanelProps = {
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
    onConfirmDuplicationClick: (selectedFieldNbr: number) => void;
};

export const OperationDuplicationRightPanel = ({
    sourceField,
    targetFields,
    onConfirmDuplicationClick,
}: OperationDuplicationRightPanelProps) => {
    const { t } = useTranslation();
    const { classes } = useOperationDuplicationRightPanelStyle();

    const { fieldCropIds } = useOperationDuplicationFormValues();

    const {
        search,
        handleResetSearch,
        handleSearchChange,
        handleToggleSelectedFields,
        toggleSelectedFields,
        doesFieldMatchFilters,
    } = useDuplicationFieldFilters();

    const isAllowedToConfirm = useCanConfirmDuplication();

    const { openWarningModal, handleCloseWarningModal, handleOpenWarningModal } = useDuplicationModals();

    const { selectedField, selectedFieldOperationTypes, handleFieldSelection, handleConfirmSelection } =
        useFieldSelectionLogic({
            sourceField,
            targetFields,
            onFieldSelected: handleOpenWarningModal,
            onConfirmSelection: handleCloseWarningModal,
        });

    const { recenterMapOnPolygons } = useOperationDuplicationRightPanelMap(sourceField, targetFields, fieldCropIds);

    const allFields = targetFields.concat(sourceField);
    const { polygons, calculateCentroid } = useFullscreenModalMap(allFields);

    return (
        <SidePanel
            className={classes.panel}
            header={
                <SidePanel.Header>
                    <Typography variant="h3" marginBottom={2}>
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.title')}
                    </Typography>
                    <Typography color="neutral" variant="body2" component="p" marginBottom={1}>
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.description')}
                    </Typography>
                    <SearchInput
                        placeholder={t('constants.search-field')}
                        value={search}
                        onClearClick={handleResetSearch}
                        onChange={handleSearchChange}
                    />
                    <Switch
                        className={classes.switch}
                        labelPlacement="start"
                        checked={toggleSelectedFields}
                        onChange={handleToggleSelectedFields}
                        label={t('encoding-technical-itinerary.duplication.modal.right-panel.toggle-selected')}
                    />
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body>
                    <ControlledSelectableList
                        name={OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS}
                        options={targetFields}
                        getOptionKey={(fc) => fc.fieldCrop.id}
                        getOptionValue={(fc) => fc.fieldCrop.id}
                        renderOption={(option, props) => {
                            const shouldBeDisplayed = doesFieldMatchFilters(option, props.isSelected);

                            return (
                                <SelectableList.Item
                                    key={option.id}
                                    className={shouldBeDisplayed ? classes.hide : ''}
                                    onClick={(e) => handleFieldSelection(e, option, props.isSelected)}
                                    {...props}
                                >
                                    <FieldCropInfoBox
                                        className={classes.fieldInfoBoxOverride}
                                        icon={
                                            props.isSelected ? <IconCheckCircle color="success" /> : <IconPlusCircle />
                                        }
                                        fieldName={option.name}
                                        fieldArea={`${formatNumber(option.area, 2)} HA`}
                                        cropNames={[
                                            {
                                                id: option.staticCrop.id,
                                                name: t(`db.crop.slug-${option.staticCrop.slug}`),
                                            },
                                        ]}
                                        mapSnapshot={option.snapshot}
                                        handleFieldClick={(event) => {
                                            event.preventDefault();
                                            recenterMapOnPolygons?.([option.polygon]);
                                        }}
                                    />
                                </SelectableList.Item>
                            );
                        }}
                    />
                </SidePanel.Body>
            }
            footer={
                <SidePanel.Footer>
                    <Button
                        onClick={() => onConfirmDuplicationClick(fieldCropIds.length)}
                        disabled={!isAllowedToConfirm}
                        fullWidth
                        variant="contained"
                        type="submit"
                    >
                        <IconLightning />
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.confirm-copy', {
                            count: fieldCropIds.length,
                        })}
                    </Button>
                </SidePanel.Footer>
            }
        >
            <MapView
                helpText={t('selection-on-map-available')}
                readOnly={true}
                coordinates={calculateCentroid?.(polygons)}
                hasOpendata={false}
                hasControls
            />
            {selectedField && selectedFieldOperationTypes.length > 0 && (
                <FieldCropWarningModal
                    open={openWarningModal}
                    onClose={handleCloseWarningModal}
                    onConfirm={handleConfirmSelection}
                    sourceFieldName={sourceField.name}
                    selectedFieldName={selectedField.name}
                    selectedOperationTypeNames={selectedFieldOperationTypes}
                />
            )}
        </SidePanel>
    );
};
