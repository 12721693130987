import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import { auth } from '@modules/auth';
import { useEffect } from 'react';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import {
    useGetNextFarmSeasonProductPlanQuery,
    useGetFarmSeasonPaymentStatusQuery,
    useGetFarmPaymentStatusQuery,
} from '@modules/payment/api/paymentApi';
import { useStartNewSeason } from '@modules/yoy/hooks/useStartNewSeason';
import { useCanOpenNewSeason } from '@shared/hooks/useCanOpenNewSeason';
import { useGetLatestStaticSeason } from '@shared/hooks/useGetLatestStaticSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useConfirmationLogic = () => {
    const { seasonId } = useParams();
    const { t } = useTranslation();
    const [track] = useTrackMutation();
    const authUser = auth.useAuthUser();
    const { currentSeason } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const farmId = currentSeason?.farm_id;
    const { data: nextFarmSeasonProductPlan, isLoading: isNextFarmSeasonProductPlanLoading } =
        useGetNextFarmSeasonProductPlanQuery({
            farmSeasonId: currentSeason?.id,
        });
    const { startNewSeason, isLoading: isStartingNewSeason } = useStartNewSeason({
        onSuccess: (nextFarmSeasonId) => navigate(`/${nextFarmSeasonId}/farm-profile`),
    });

    const farmSeasonId = nextFarmSeasonProductPlan?.farm_season_id
        ? nextFarmSeasonProductPlan?.farm_season_id
        : isNextFarmSeasonProductPlanLoading
        ? undefined
        : currentSeason?.id;
    const { data: paymentStatus, isLoading: isPaymentStatusLoading } = useGetFarmSeasonPaymentStatusQuery({
        farmSeasonId: farmSeasonId,
    });
    const {
        data: farmPaymentStatus,
        isLoading: isFarmPaymentStatusLoading,
        isFetching: isFarmPaymentStatusFetching,
    } = useGetFarmPaymentStatusQuery({
        farmId: farmId,
    });
    const isSepaPayment = paymentStatus?.status === 'pending';
    const { canOpenNewSeason, isLoading: isLoadingCanOpenNewSeason } = useCanOpenNewSeason();
    const latestSeason = useGetLatestStaticSeason(currentSeasonId);

    const isDeferredPayment = !nextFarmSeasonProductPlan && !isNextFarmSeasonProductPlanLoading;
    const navigate = useNavigate();
    const handleRedirect = async () => {
        if (isDeferredPayment) {
            navigate(`/${seasonId}/payment/pricing`);
        } else if (canOpenNewSeason) {
            if (!currentSeason || !nextFarmSeasonProductPlan) return;
            await startNewSeason(currentSeason?.id);
        } else navigate(`/${seasonId}`);
    };
    const handleSecondaryRedirect = () => navigate(`/${seasonId}/billing`);
    const harvestYear = latestSeason?.harvest_year;
    const computedTitle =
        isDeferredPayment && isSepaPayment
            ? t('pages.payment.confirmation.title.deferred-payment-sepa')
            : isDeferredPayment
            ? t('pages.payment.confirmation.title.deferred-payment')
            : isSepaPayment
            ? t('pages.payment.confirmation.title.renewal-sepa')
            : t('pages.payment.confirmation.title.renewal');

    const computedSubtitle =
        isDeferredPayment && isSepaPayment
            ? t('pages.payment.confirmation.subtitle.deferred-payment-sepa')
            : isDeferredPayment
            ? t('pages.payment.confirmation.subtitle.deferred-payment')
            : isSepaPayment && canOpenNewSeason
            ? t('pages.payment.confirmation.subtitle.renewal-sepa.season-opened')
            : isSepaPayment
            ? t('pages.payment.confirmation.subtitle.renewal-sepa.season-closed')
            : canOpenNewSeason
            ? t('pages.payment.confirmation.subtitle.renewal.season-opened')
            : t('pages.payment.confirmation.subtitle.renewal.season-closed');

    const computedCta =
        isDeferredPayment && farmPaymentStatus?.status === 'to_be_renewed'
            ? t('pages.payment.confirmation.cta.deferred-payment', { harvest_year: harvestYear })
            : canOpenNewSeason
            ? t('pages.payment.confirmation.cta.renewal.season-opened', { harvest_year: harvestYear })
            : t('pages.payment.confirmation.cta.renewal.season-closed');
    const computedSecondaryCta = isDeferredPayment
        ? t('pages.payment.confirmation.secondaryCta.deferred-payment')
        : undefined;

    useEffect(() => {
        track({
            event: EVENTS.PRICING_FLOW_ENDED,
            properties: {
                user_role: authUser?.role?.name ?? '',
                pricing_stage: isDeferredPayment ? 'deferred' : 'renewal',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading:
            isNextFarmSeasonProductPlanLoading ||
            isPaymentStatusLoading ||
            isFarmPaymentStatusLoading ||
            isFarmPaymentStatusFetching ||
            isLoadingCanOpenNewSeason,
        computedTitle,
        computedSubtitle,
        computedCta,
        computedSecondaryCta,
        handleRedirect,
        handleSecondaryRedirect,
        isCtaLoading: isStartingNewSeason,
    };
};
