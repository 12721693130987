import { Modal } from '@soil-capital/ui-kit/components';
import { useTranslation, Trans } from 'react-i18next';
import useNewSeasonModalStyle from './useNewSeasonModal.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useNewSeasonModalLogic } from './useNewSeasonModalLogic';

export const NewSeasonModal = () => {
    const { t } = useTranslation();
    const { classes } = useNewSeasonModalStyle();
    const { newSeasonModalOpen, onNewSeasonModalClose, isLoading, handleConfirm } = useNewSeasonModalLogic();

    return (
        <Modal
            size="small"
            open={newSeasonModalOpen}
            onClose={onNewSeasonModalClose}
            onConfirm={handleConfirm}
            header={
                <Modal.Header>
                    <Typography variant="h3">{t('new-season.modal.title')}</Typography>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <div className={classes.flexbox}>
                        <Typography variant="body2">
                            <Trans t={t} i18nKey={'new-season.modal.description'} values={{ newSeasonYear: '2024' }} />
                        </Typography>
                    </div>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Confirm disabled={isLoading} loading={isLoading}>
                        {t('new-season.modal.CTA')}
                        <IconArrowRight />
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
