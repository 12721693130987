import { useCreateFarmSeasonProfileMutation } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';

type useCreateFarmSeasonProfileProps = {
    farmSeasonId: number;
};

export const useCreateFarmSeasonProfile = ({ farmSeasonId }: useCreateFarmSeasonProfileProps) => {
    const [createMutation, { isLoading }] = useCreateFarmSeasonProfileMutation();

    const createProfile = async () => {
        try {
            return createMutation({
                farmSeasonId,
                payload: {
                    static_data_ids: [],
                    has_agroforestry: null,
                    has_drained_fields: null,
                    has_inter_crop_cover: null,
                    has_irrigated_fields: null,
                    has_livestock: null,
                },
            });
        } catch (e) {
            throw new Error('Could not create Farm season profile');
        }
    };

    return { createProfile, isLoading };
};
