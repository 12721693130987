import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetLatestStaticSeasonId } from './useGetLatestStaticSeasonId';
import { useGetActiveSeason } from './useGetLatestActiveSeason';

export const useIsNextStaticSeasonOpened = () => {
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const { latestStaticSeasonId } = useGetLatestStaticSeasonId();

    return latestStaticSeasonId && activeSeason?.season_id ? latestStaticSeasonId > activeSeason?.season_id : false;
};
