import { IconSearch } from '@soil-capital/ui-kit/icons';
import { MultipleSelectCheckmarks } from '@soil-capital/ui-kit/components';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { Box } from '@soil-capital/ui-kit/material-core';
import { TextFieldProps } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';

type MultiSelectCheckboxProps<T> = {
    name: string;
    options: T[];
    getOptionKey: (option: T) => string | number;
    getOptionLabel: (option: T) => string;
    getOptionValue: (option: T) => string | number;
    onChange?: (values: T[]) => void;
    onBlur?: () => void;
    onReset?: () => void;
    renderValues?: (values: T[]) => ReactNode;
    disabledOptions?: T[];
} & Omit<TextFieldProps, 'onChange' | 'onBlur'>;

export const MultiSelectCheckbox = <T,>({
    name,
    options,
    onChange,
    onBlur,
    placeholder,
    renderValues,
    getOptionKey,
    getOptionLabel,
    getOptionValue,
    disabledOptions,
    ...rest
}: MultiSelectCheckboxProps<T>) => {
    const { t } = useTranslation();
    const context = useFormContext();

    if (!context) throw new Error('MultiSelectCheckbox should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            render={({ field: { onChange: onFieldChanged, onBlur: onFieldBlur, value } }) => (
                <MultipleSelectCheckmarks
                    disabledOptions={disabledOptions}
                    values={value}
                    onChange={(values) => {
                        onFieldChanged(values);
                        onChange?.(values);
                    }}
                    onBlur={() => {
                        onFieldBlur();
                        onBlur?.();
                    }}
                    options={options}
                    getOptionKey={getOptionKey}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    placeholder={placeholder ? placeholder : t('constants.select')}
                    renderValues={
                        renderValues
                            ? renderValues
                            : (values) => (
                                  <Box component="span" display="flex" alignItems="center" gap={1}>
                                      <IconSearch />
                                      {`${values.length} ${t('constants.selected')}`}
                                  </Box>
                              )
                    }
                    {...rest}
                />
            )}
        />
    );
};
