import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { formatNumber } from '@shared/utils';
import { useDuplicationSourceItem } from './useDuplicationSourceItem';
import { DuplicationFieldT } from '../DuplicationModalLeftPanel/DuplicationModalLeftPanel';
import { useDuplicationSourceItemStyle } from './useDuplicationSourceItemStyle';

// extracted for performance enhancement
export const DuplicationSourceItem = ({ sourceField }: { sourceField: DuplicationFieldT }) => {
    const { setFocusedFieldId } = useDuplicationSourceItem({
        fieldId: sourceField.field.id,
    });
    const { classes } = useDuplicationSourceItemStyle();

    return (
        <FieldCropInfoBox
            mapSnapshot={sourceField.snapshot}
            fieldName={sourceField.field.name}
            fieldArea={`${formatNumber(sourceField.field.area, 2)} HA`}
            cropNames={sourceField.crops}
            className={classes.sourceField}
            handleFieldClick={(e) => {
                e.preventDefault();
                setFocusedFieldId(sourceField.field.id);
            }}
        />
    );
};
