import { Autocomplete } from '@soil-capital/ui-kit';
import { IconPlus } from '@soil-capital/ui-kit/icons';
import { useCropListPickerLogic } from '@modules/encoding/modules/rotation/components/CropListPicker/useCropListPickerLogic';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const CropListPicker = () => {
    const { t } = useTranslation();
    const { pickerItems, addCrop, isAddingCrop, cropPickerKey } = useCropListPickerLogic();
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <Autocomplete
            key={cropPickerKey}
            value={undefined}
            noOptionsText={t('constants.no-results')}
            onChange={(event, cropId) => {
                cropId ? addCrop(cropId as number) : undefined;
            }}
            blurOnSelect
            clearOnBlur
            adding={isAddingCrop}
            options={pickerItems[0].items.map((crop) => crop.value)}
            getOptionLabel={(cropId) => t(pickerItems[0].items.find((crop) => crop.value === cropId)?.label ?? '')}
            placeholder={t('encoding-rotation.modal.left-panel.button.add-crop')}
            startIcon={<IconPlus />}
            isOptionEqualToValue={(option, value) => {
                return option === value;
            }}
            loading={isAddingCrop}
            multiple={false}
            disabled={readOnly}
        />
    );
};
