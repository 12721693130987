import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { EncodingStep } from '@modules/encoding/modules/preparation/components/EncodingStep/EncodingStep';
import {
    IconCow,
    IconFertilizer,
    IconMap2,
    IconMicroscope,
    IconRye,
    IconTractor,
    IconHistory,
} from '@soil-capital/ui-kit/icons';
import { usePreparationContentPageLogic } from '@modules/encoding/modules/preparation/pages/PreparationContentPage/usePreparationContentPage.logic';
import { usePreparationContentPageStyle } from '@modules/encoding/modules/preparation/pages/PreparationContentPage/PreparationContentPage.style';
import { useTranslation } from 'react-i18next';

export const PreparationContentPage = () => {
    const { classes } = usePreparationContentPageStyle();
    const { t } = useTranslation();
    const { isYoYFarmSeason, isBaseline, isLoading, isUpdatingPreparationProgress, harvestYear, handleGoNext } =
        usePreparationContentPageLogic();

    return (
        <ContentPage
            isLoading={isLoading}
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Title={<ContentPage.Title>{t('encoding-preparation.header.title', { harvestYear })}</ContentPage.Title>}
            Subtitle={<ContentPage.Subtitle>{t('encoding-preparation.header.subtitle')}</ContentPage.Subtitle>}
            Action={
                <ContentPage.Action loading={isUpdatingPreparationProgress} onClick={handleGoNext}>
                    {t('encoding-preparation.header.action')}
                </ContentPage.Action>
            }
        >
            <main className={classes.container}>
                <EncodingStep
                    Icon={<IconTractor color="success" />}
                    title={t('encoding-preparation.steps.farm-profile.title')}
                    text={t('encoding-preparation.steps.farm-profile.text')}
                    estimatedTime={t('constants.min', { count: 5 })}
                />
                <EncodingStep
                    Icon={<IconMap2 color="success" />}
                    title={t('encoding-preparation.steps.fields.title')}
                    text={t('encoding-preparation.steps.fields.text')}
                    estimatedTime={t('constants.min', { count: 15 })}
                />
                <EncodingStep
                    Icon={<IconRye color="success" />}
                    title={t('encoding-preparation.steps.technical-itinerary.title')}
                    text={t('encoding-preparation.steps.technical-itinerary.text')}
                    estimatedTime={t('encoding-preparation.steps.time-depends-on-culture')}
                />
                <EncodingStep
                    Icon={<IconFertilizer color="success" />}
                    title={t('encoding-preparation.steps.drainage-irrigation-agroforestry.title')}
                    text={t('encoding-preparation.steps.drainage-irrigation-agroforestry.text')}
                    estimatedTime={t('encoding-preparation.steps.time-depends-on-culture')}
                />
                <EncodingStep
                    Icon={<IconCow color="success" />}
                    title={t('encoding-preparation.steps.livestock.title')}
                    text={t('encoding-preparation.steps.livestock.text')}
                    estimatedTime={t('constants.min', { count: 2 })}
                />
                {isYoYFarmSeason ? (
                    <></>
                ) : (
                    <EncodingStep
                        Icon={<IconMicroscope color="success" />}
                        title={t('encoding-preparation.steps.soil-analysis.title')}
                        text={t('encoding-preparation.steps.soil-analysis.text')}
                        estimatedTime={t('encoding-preparation.steps.time-depends-on-culture')}
                    />
                )}
                {isBaseline && (
                    <EncodingStep
                        Icon={<IconHistory color="success" />}
                        title={t('encoding-preparation.steps.history.title')}
                        text={t('encoding-preparation.steps.history.text')}
                        estimatedTime={t('encoding-preparation.steps.time-depends-on-culture')}
                    />
                )}
            </main>
        </ContentPage>
    );
};
