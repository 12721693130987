import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { formatNumber } from '@shared/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import technicalItineraryRoutes from '../../technicalItinerary.routes';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useFieldCropNeedFinalisation } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropNeedFinalisation';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { useTechnicalItineraryNavigation } from '../../hooks/useTechnicalItineraryNavigation';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

type FieldDataT = {
    fieldId: number;
    fieldCropId: number;
    name: string;
    area: number;
    infoText: string;
    maxProgress: number;
    currentProgress: number;
    image: string;
};
type CropDataT = {
    cropId: number;
    name: string;
    totalArea: number;
    fieldDataList: FieldDataT[];
    textProgress: string;
    maxProgress: number;
    currentProgress: number;
    image: string;
};

export const useIntroductionContentData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { harvestedCrops, harvestedCropsLoading } = cropState.useHarvestedCrops({
        countryId: currentFarm?.country_id,
    });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { operationState } = entities.operation.useState({ farmSeasonId: currentSeasonId });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const { doesFieldCropNeedFinalisation, isLoading: isLoadingFieldCropsNeedFinalisation } =
        useFieldCropNeedFinalisation();
    const { navigateToTechnicalItineraryPage } = useTechnicalItineraryNavigation();
    const { nextStepToComplete, lastCompletedStep, getRouteMatchingStep } = useProgressTracker();
    const { isHistoryFarmSeason, baselineSeason } = useBaselineFarmSeason();

    const isDataLoading =
        currentFarmLoading ||
        isLoadingHarvestYear ||
        harvestedCropsLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        fieldState.isLoading ||
        staticCropState.isLoading ||
        progressState.isLoading ||
        progressState.isFetching ||
        staticSeasonState.isLoading ||
        staticCropGroupState.isLoading ||
        isLoadingFieldCropsNeedFinalisation ||
        operationState.isLoading;

    /** build the FieldDataT[] data from the cropId */
    const getFieldDataList = useCallback(
        ({ cropId }: { cropId: number }) => {
            if (isDataLoading) {
                return [];
            }

            const fieldCrops = fieldCropState.getByCropId({ cropId });

            return fieldCrops.map<FieldDataT>((fieldCrop) => {
                const fieldCropProgress = progressState.computeProgressDetail(
                    'technical-itinerary',
                    'farm_season_field_crop_id',
                    fieldCrop.id,
                );

                const field = fieldState.getById(fieldCrop.farm_season_field_id);
                if (!field) {
                    throw Error('no field found for fieldId:' + fieldCrop.farm_season_field_id);
                }

                const needFinalisation = doesFieldCropNeedFinalisation(fieldCrop.id);

                return {
                    fieldId: field.id,
                    fieldCropId: fieldCrop.id,
                    name: field.name,
                    area: field.area,
                    currentProgress: needFinalisation ? 99 : fieldCropProgress.done,
                    maxProgress: needFinalisation ? 100 : fieldCropProgress.total || 1,
                    infoText: `${formatNumber(field.area, 2)} HA`,
                    image: getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at }),
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldCropState.getByCropId, isDataLoading, isLoadingFieldCropsNeedFinalisation],
    );

    const cropDataList = harvestedCrops.map<CropDataT>((crop) => {
        const fieldDataList = getFieldDataList({ cropId: crop.id });
        const totalArea = fieldDataList.reduce((total, field) => total + field.area, 0);
        const sortedFieldDataList = [...fieldDataList].sort((field1, field2) => field2.area - field1.area);
        const maxProgress = fieldDataList.length;
        const currentProgress = fieldDataList.reduce((current, field) => {
            const fieldProgressDone = field.maxProgress === field.currentProgress;
            return current + (fieldProgressDone ? 1 : 0);
        }, 0);

        const textProgress = t('encoding-technical-itinerary.introduction.crop-progress-text', {
            currentProgress,
            maxProgress,
        });

        const staticCrop = staticCropState.getById(crop.crop_id);
        const cropGroup = staticCropGroupState.getById(staticCrop?.crop_group_id);
        const name = t(staticCrop?.translation_slug ?? '');
        const image = encodingShared.getCropGroupImg(cropGroup?.slug);

        return {
            cropId: crop.id,
            name,
            totalArea,
            fieldDataList: sortedFieldDataList,
            currentProgress,
            maxProgress,
            textProgress,
            image,
        };
    });

    const sortedCropDataList = [...cropDataList]
        .sort((cropData1, cropData2) => cropData2.totalArea - cropData1.totalArea)
        .filter((cropData) => cropData.maxProgress > 0);

    const sortedProgressDetails = progressState.getBySlug(PROGRESS_SLUGS.TECHNICAL_ITINERARY)?.progress_details;

    const handleContinueClick = () => {
        const nextCrop = sortedCropDataList.find((cropData) => cropData.currentProgress < cropData.maxProgress);
        if (!nextCrop) return;

        const nextFieldCrop = nextCrop.fieldDataList.find(
            (fieldData) => fieldData.currentProgress < fieldData.maxProgress,
        );
        if (!nextFieldCrop) return;

        const nextFieldCropProgressDetails = sortedProgressDetails?.filter(
            (progressDetail) => progressDetail.farm_season_field_crop_id === nextFieldCrop?.fieldCropId,
        );
        const firstNotDoneProgressDetail = nextFieldCropProgressDetails?.find(
            (progressDetail) => progressDetail.is_done === false,
        );
        if (!firstNotDoneProgressDetail) {
            navigate(technicalItineraryRoutes.globalCharacteristics({ fieldCropId: nextFieldCrop.fieldCropId }));
            return;
        }

        const fieldCropId = firstNotDoneProgressDetail.farm_season_field_crop_id;
        const operation = operationState.list.find(
            (operation) =>
                operation.farm_season_field_crop_operation_id ===
                firstNotDoneProgressDetail?.farm_season_field_crop_operation_id,
        );
        if (fieldCropId) {
            navigateToTechnicalItineraryPage(fieldCropId, navigate, operation, firstNotDoneProgressDetail?.category);
        }
    };

    const handleCropClick = (fieldData: FieldDataT, cropData: CropDataT) => {
        const sortedCropProgressDetails = sortedProgressDetails?.filter((progressDetail) => {
            return (
                progressDetail.farm_season_field_id === fieldData.fieldId &&
                progressDetail.farm_season_crop_id === cropData.cropId
            );
        });
        const firstNotDoneProgressDetail = sortedCropProgressDetails?.find(
            (progressDetail) => progressDetail.is_done === false,
        );

        if (!firstNotDoneProgressDetail) {
            navigate(
                technicalItineraryRoutes.globalCharacteristics({
                    fieldCropId: fieldData.fieldCropId,
                }),
            );
        } else {
            const fieldCropId = firstNotDoneProgressDetail?.farm_season_field_crop_id;
            const operation = operationState.list.find(
                (operation) =>
                    operation.farm_season_field_crop_operation_id ===
                    firstNotDoneProgressDetail?.farm_season_field_crop_operation_id,
            );

            if (fieldCropId)
                navigateToTechnicalItineraryPage(
                    fieldCropId,
                    navigate,
                    operation,
                    firstNotDoneProgressDetail?.category,
                );
        }
    };

    const handleStepRedirect = () => {
        // Technical itinerary is last step of history FS
        if (!nextStepToComplete && isHistoryFarmSeason)
            return navigate(`/${baselineSeason?.id}/${encodingRoutesConfig.HISTORY}`);
        if (!nextStepToComplete) return navigate(`/${currentSeasonId}`);
        if (nextStepToComplete)
            return navigate(`/${currentSeasonId}/${getRouteMatchingStep(nextStepToComplete?.slug)}`);
        if (lastCompletedStep && pathname === `/${currentSeasonId}/${getRouteMatchingStep(lastCompletedStep?.slug)}`)
            return navigate(`/${currentSeasonId}/${encodingRoutesConfig.ENCODING_DONE}`);
        if (lastCompletedStep) return navigate(`/${currentSeasonId}/${getRouteMatchingStep(lastCompletedStep?.slug)}`);

        return navigate(`/${currentSeasonId}/${encodingRoutesConfig.ENCODING_DONE}`);
    };

    const hasStartedItinerary = cropDataList.some((cropData) =>
        cropData.fieldDataList.some((fieldData) => fieldData.currentProgress > 0),
    );

    const allFieldsCompleted = cropDataList.every((cropData) =>
        cropData.fieldDataList.every((fieldData) => fieldData.currentProgress === fieldData.maxProgress),
    );

    return {
        cropDataList: sortedCropDataList,
        harvestYear,
        hasStartedItinerary,
        allFieldsCompleted,
        isDataLoading,
        handleContinueClick,
        handleCropClick,
        handleStepRedirect,
    };
};
