import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCropDestinationListStyles = makeStyles()((theme) => ({
    cropDestinationInfoBox: {
        padding: 0,
    },
    selectedCropDestinationInfoBox: {
        border: `2px solid ${theme.palette.primaryScale[300]}`,
        backgroundColor: theme.palette.success.light,
        pointerEvents: 'none',
    },
}));
