/**
 * https://github.com/rook2pawn/node-intersection
 *
 * Author @rook2pawn
 */

/**
 * AB
 *
 * @private
 * @param {Array<Array<number>>} segment - 2 vertex line segment
 * @returns {Array<number>} coordinates [x, y]
 */
function ab(segment: Array<Array<number>>) {
    const start = segment[0];
    const end = segment[1];
    return [end[0] - start[0], end[1] - start[1]];
}

/**
 * Cross Product
 *
 * @private
 * @param {Array<number>} v1 coordinates [x, y]
 * @param {Array<number>} v2 coordinates [x, y]
 * @returns {Array<number>} Cross Product
 */
function crossProduct(v1: Array<number>, v2: Array<number>) {
    return v1[0] * v2[1] - v2[0] * v1[1];
}

/**
 * Add
 *
 * @private
 * @param {Array<number>} v1 coordinates [x, y]
 * @param {Array<number>} v2 coordinates [x, y]
 * @returns {Array<number>} Add
 */
function add(v1: Array<number>, v2: Array<number>) {
    return [v1[0] + v2[0], v1[1] + v2[1]];
}

/**
 * Sub
 *
 * @private
 * @param {Array<number>} v1 coordinates [x, y]
 * @param {Array<number>} v2 coordinates [x, y]
 * @returns {Array<number>} Sub
 */
function sub(v1: Array<number>, v2: Array<number>) {
    return [v1[0] - v2[0], v1[1] - v2[1]];
}

/**
 * scalarMult
 *
 * @private
 * @param {number} s scalar
 * @param {Array<number>} v coordinates [x, y]
 * @returns {Array<number>} scalarMult
 */
function scalarMult(s: number, v: Array<number>) {
    return [s * v[0], s * v[1]];
}

/**
 * Intersect Segments
 *
 * @private
 * @param {Array<Array<number>>} a 2 vertex line segment
 * @param {Array<Array<number>>} b 2 vertex line segment
 * @returns {Array<number>} intersection
 */
function intersectSegments(a: Array<Array<number>>, b: Array<Array<number>>) {
    const p = a[0];
    const r = ab(a);
    const q = b[0];
    const s = ab(b);

    const cross = crossProduct(r, s);
    const qmp = sub(q, p);
    const numerator = crossProduct(qmp, s);
    const t = numerator / cross;
    const intersection = add(p, scalarMult(t, r));
    return intersection;
}

/**
 * Is Parallel
 *
 * @private
 * @param {Array<Array<number>>} a 2 vertex line segment
 * @param {Array<Array<number>>} b 2 vertex line segment
 * @returns {boolean} true if a and b are parallel (or co-linear)
 */
function isParallel(a: Array<Array<number>>, b: Array<Array<number>>) {
    const r = ab(a);
    const s = ab(b);
    return crossProduct(r, s) === 0;
}

/**
 * Intersection
 *
 * @private
 * @param {Array<Array<number>>} a 2 vertex line segment
 * @param {Array<Array<number>>} b 2 vertex line segment
 * @returns {Array<number>|boolean} true if a and b are parallel (or co-linear)
 */
export default function intersection(a: Array<Array<number>>, b: Array<Array<number>>) {
    if (isParallel(a, b)) return false;
    return intersectSegments(a, b);
}
