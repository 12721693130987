/* eslint-disable @typescript-eslint/no-explicit-any */
import irrigationApi from './irrigation.api';
import { irrigationCacheAdapter } from './irrigation.cache';
import { IrrigationEndpointParamsT, IrrigationT } from './irrigation.types';
import createDefaultState from '../createDefaultState';
import { useCallback, useMemo } from 'react';

export default ({ farmSeasonId }: IrrigationEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(irrigationCacheAdapter, () =>
        irrigationApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    const irrigationIndexedByFieldId = useMemo(() => {
        return indexProperty('farm_season_field_id', defaultState.list);
    }, [defaultState.list]) as Record<string, IrrigationT | undefined>;

    const getByFieldId = useCallback(
        (fieldId: number) => irrigationIndexedByFieldId[fieldId],
        [irrigationIndexedByFieldId],
    );

    return {
        irrigationState: {
            getByFieldId,
            ...defaultState,
        },
    };
};

/* Take an array of items and return an object (property_indexed -> item) */
const indexProperty = (property: string, list: any[]) => {
    return Object.fromEntries(list.map((item) => [item[property], item]));
};
