import { useTranslation } from 'react-i18next';
import { useEncodingDoneContentData } from './useEncodingDoneContentData';
import {
    IconMap2,
    IconSeed,
    IconTractor,
    IconMicroscope,
    IconCoverCrop,
    IconLayersTwo1,
    IconFertilizer,
    IconCow,
    IconHistory,
} from '@soil-capital/ui-kit/icons';
import { Stack } from '@soil-capital/ui-kit/components';
import { NavigateFunction } from 'react-router-dom';
import { encodingRoutesConfig } from '../encoding.routes';
import { useEncodingDoneContentStyle } from './useEncodingDoneContentStyle';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useParams } from 'react-router-dom';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { EncodingDoneModal } from '@modules/encoding/pages/EncodingDoneModal/EncodingDoneModal';
import { useToggle } from '@shared/hooks/useToggle';

export const EncodingDoneContentPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { open, handleClose } = useToggle({ defaultOpen: true });
    const { t } = useTranslation();
    const { classes } = useEncodingDoneContentStyle();
    const { harvestYear, getIsStepVisible, getStepProgress, isDataLoading } = useEncodingDoneContentData();
    const { seasonId } = useParams();

    const isLoading = isDataLoading;

    const onEncodingDoneModalConfirm = () => navigate(`/${seasonId}/results/summary`);

    return (
        <>
            <EncodingDoneModal open={open} onClose={handleClose} onConfirm={onEncodingDoneModalConfirm} />
            <ContentPage
                Title={<ContentPage.Title>{t('encoding.encoding-done.title', { harvestYear })}</ContentPage.Title>}
                Subtitle={<ContentPage.Subtitle>{t('encoding.encoding-done.subtitle')}</ContentPage.Subtitle>}
                Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
                isLoading={isLoading}
            >
                <IntroProgressLayout
                    leftSection={
                        <>
                            <Typography variant="h3">{t('encoding.encoding-done.finalised')}</Typography>
                            <Stack spacing={1} mt={1}>
                                {getIsStepVisible(PROGRESS_SLUGS.FARM_PROFILE) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.farm-profile-progress')}
                                        onClick={() => navigate(encodingRoutesConfig.FARM_PROFILE)}
                                        image={<IconTractor className={classes.icon} />}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.FARM_PROFILE).max,
                                            current: getStepProgress(PROGRESS_SLUGS.FARM_PROFILE).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.ROTATION) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.rotation-progress')}
                                        image={<IconMap2 className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.ROTATION)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.ROTATION).max,
                                            current: getStepProgress(PROGRESS_SLUGS.ROTATION).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.TECHNICAL_ITINERARY) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.technical-itinerary-progress')}
                                        image={<IconSeed className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.TECHNICAL_ITINERARY)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.TECHNICAL_ITINERARY).max,
                                            current: getStepProgress(PROGRESS_SLUGS.TECHNICAL_ITINERARY).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.DRAINAGE) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.drainage-progress')}
                                        image={<IconLayersTwo1 className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.DRAINAGE)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.DRAINAGE).max,
                                            current: getStepProgress(PROGRESS_SLUGS.DRAINAGE).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.IRRIGATION) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.irrigation-progress')}
                                        image={<IconFertilizer className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.IRRIGATION)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.IRRIGATION).max,
                                            current: getStepProgress(PROGRESS_SLUGS.IRRIGATION).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.AGROFORESTRY) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.agroforestry-progress')}
                                        image={<IconCoverCrop className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.AGROFORESTRY)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.AGROFORESTRY).max,
                                            current: getStepProgress(PROGRESS_SLUGS.AGROFORESTRY).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.LIVESTOCK) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.livestock-progress')}
                                        image={<IconCow className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.LIVESTOCK)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.LIVESTOCK).max,
                                            current: getStepProgress(PROGRESS_SLUGS.LIVESTOCK).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.SOIL_ANALYSIS) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.soil-analysis-progress')}
                                        image={<IconMicroscope className={classes.icon} />}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.SOIL_ANALYSIS).max,
                                            current: getStepProgress(PROGRESS_SLUGS.SOIL_ANALYSIS).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}

                                {getIsStepVisible(PROGRESS_SLUGS.HISTORY) && (
                                    <IntroProgressItem
                                        text={t('encoding.encoding-done.history')}
                                        image={<IconHistory className={classes.icon} />}
                                        onClick={() => navigate(encodingRoutesConfig.HISTORY)}
                                        progress={{
                                            max: getStepProgress(PROGRESS_SLUGS.HISTORY).max,
                                            current: getStepProgress(PROGRESS_SLUGS.HISTORY).current,
                                        }}
                                        className={classes.encodingDoneProgressItem}
                                    />
                                )}
                            </Stack>
                        </>
                    }
                />
            </ContentPage>
        </>
    );
};
