import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMergedFieldModalStyles } from './SplittedFieldModal.style';
import { Input, Modal, ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { useSplittedFieldModalFormLogic, FIELD_SPLITTING_PROPERTIES } from './useSplittedFieldModalFormLogic';
import { Typography } from '@mui/material';
import { SplittedFieldSameOriginT } from '@shared/map/hook/useSplit';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { FieldPolygonT } from '@shared/entities/field/field.types';

export type SplitFieldModalPropsT = {
    newPolygons: SplittedFieldSameOriginT[] | null;
    modalController: ModalPropsT<{ newPolygon: FieldPolygonT }, boolean>;
};

export const SplittedFieldModal = ({ newPolygons, modalController }: SplitFieldModalPropsT) => {
    const { classes } = useMergedFieldModalStyles();
    const { t } = useTranslation();
    const { methods, isEditingField, onConfirm, treshold, errors, onClose } = useSplittedFieldModalFormLogic({
        newPolygons,
        modalController,
    });

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    size={'small'}
                    open={modalController.isOpen}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>{t('encoding-rotation.field-creation-modal.title')}</Modal.HeaderTitle>
                            <Modal.Close />
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Input
                                label={t('constants.name')}
                                data-testid="field_name"
                                type="text"
                                inputProps={{
                                    ...methods.register(FIELD_SPLITTING_PROPERTIES.FIELD_NAME),
                                }}
                            />
                            {errors.field_name && (
                                <Typography color="error" className={classes.error}>
                                    {errors.field_name.message}
                                </Typography>
                            )}
                            <div className={classes.container}>
                                <div className={classes.label}>
                                    <Typography variant="body" color="darkScale.900">
                                        {t('constants.area').toUpperCase()}
                                    </Typography>
                                    <ConditionalTooltip
                                        title={t('encoding.rotation.map.draw.area-tooltip', { treshold })}
                                        scheme="dark"
                                        display="flex"
                                    />
                                </div>
                                <Input
                                    type="number"
                                    suffix={t('constants.hectares')}
                                    placeholder={modalController.data?.newPolygon.properties.area.toFixed(2)}
                                    defaultValue={modalController.data?.newPolygon.properties.area.toFixed(2)}
                                    inputProps={{
                                        ...methods.register(FIELD_SPLITTING_PROPERTIES.AREA, {
                                            setValueAs: (v) => Number(v),
                                        }),
                                    }}
                                />

                                {errors.area ? (
                                    <Typography color="error" className={classes.description}>
                                        {errors.area.message}
                                    </Typography>
                                ) : (
                                    <Typography color="GrayText" className={classes.description}>
                                        {t('encoding-rotation.map.draw.area-description', { treshold })}
                                    </Typography>
                                )}
                            </div>
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Confirm loading={isEditingField}>{t('constants.save')}</Modal.Confirm>
                            <Modal.Cancel onClick={onClose}>{t('constants.cancel')}</Modal.Cancel>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    );
};
