import { SelectableList } from '@soil-capital/ui-kit';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { CropDestinationInfoBox } from '../CropDestinationInfoBox/CropDestinationInfoBox';
import { useCropDestinationListStyles } from './CropDestinationList.style';
import { FIELD_CROP_DESTINATION } from '@modules/encoding/modules/rotation/pages/CropDestinationPage/useCropDestinationPageFormLogic';

type CropDestinationInfoBoxDataT = {
    id: number;
    cropDestinationName: string;
    cropDestinationImage: string;
    numberOfAssignedFields: number;
    assignedFieldsTotalArea: string;
};

export const CropDestinationList = ({
    cropDestinationList,
}: {
    cropDestinationList: CropDestinationInfoBoxDataT[];
}) => {
    const { classes } = useCropDestinationListStyles();

    return (
        <ControlledSelectableList
            name={FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID}
            singleValue
            options={cropDestinationList}
            getOptionKey={(option) => option.id}
            getOptionValue={(option) => option.id}
            renderOption={({ id, ...option }, props) => {
                return (
                    <div key={id}>
                        <SelectableList.Item
                            {...props}
                            className={props.isSelected ? classes.selectedCropDestinationInfoBox : ''}
                        >
                            <CropDestinationInfoBox {...option} className={classes.cropDestinationInfoBox} />
                        </SelectableList.Item>
                    </div>
                );
            }}
        />
    );
};
