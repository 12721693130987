import { FieldSelectionPanel } from '@modules/encoding/shared/components/FieldSelectionPanel/FieldSelectionPanel';
import { ImagePanel } from '@modules/encoding/shared/components/ImagePanel/ImagePanel';
import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useDrainageFieldSelectionModalLogic } from './useDrainageFieldSelectionModal.logic';

export type DrainageFieldSelectionModalProps = {
    open: boolean;
    onClose: () => void;
};

export const DrainageFieldSelectionModal = ({ open, onClose }: DrainageFieldSelectionModalProps) => {
    const { t } = useTranslation();

    const {
        handleCloseDrainageFieldSelectionModal,
        handleCreateDrainage,
        handleDeleteDrainage,
        isCloseLoading,
        harvestYear,
        isLoading,
        excludedFieldIds,
    } = useDrainageFieldSelectionModalLogic({
        onClose,
    });

    return (
        <FullscreenModal
            open={open}
            onClose={() => undefined}
            title={t('encoding-drainage.modal.title')}
            leftPanel={<ImagePanel src={'/assets/images/drainage_irrigation.svg'} />}
            rightPanel={
                <FieldSelectionPanel
                    title={t('encoding-drainage.modal.right-panel.title')}
                    description={t('encoding-drainage.modal.right-panel.description', { harvestYear })}
                    isLoading={isLoading}
                    onDeselect={handleDeleteDrainage}
                    onSelect={handleCreateDrainage}
                    excludedFieldIds={excludedFieldIds}
                />
            }
            headerButton={
                <Button
                    onClick={handleCloseDrainageFieldSelectionModal}
                    variant="contained"
                    color="primary"
                    loading={isCloseLoading}
                >
                    {t('constants.finish')}
                </Button>
            }
        />
    );
};
