import { useCallback, useContext, useEffect, useState } from 'react';
import { MapContext } from '../utils/MapProvider';
import { FieldT } from '@shared/entities';
import { POLYGON_SLUGS } from '../types/mapTypes';
import buffer from '@turf/buffer';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import intersect from '@turf/intersect';
import { toleranceOptions } from '../utils/polygonHelper';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import simplify from '@turf/simplify';
import union from '@turf/union';

export const useMerge = () => {
    const mapContext = useContext(MapContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { map, fields, editMode, drawMode, mergeMode, setMergeMode, selectedFields, setSelectedFields, splitMode } =
        mapContext ?? {};

    const [selectableFields, setSelectableFields] = useState<FieldT[] | undefined>([]);
    const [mergedFieldModalOpen, setMergedFieldModalOpen] = useState<boolean>(false);
    const [newMergedPolygon, setNewMergedPolygon] = useState<FieldPolygonT | null>(null);

    // onClick Event Callback
    const handleMergeUpdate = useCallback(
        (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            if (mergeMode) {
                const fieldId = e.features[0].id;
                const field = fields?.find((f) => f.id === fieldId);
                if (field) {
                    const isFieldAlreadySelected = selectedFields?.some(
                        (selectedField) => selectedField.id === field.id,
                    );
                    if (isFieldAlreadySelected) {
                        // If field already selected, remove it from selectedFields
                        setSelectedFields?.(
                            selectedFields?.filter((selectedField) => selectedField.id !== field.id) || [],
                        );
                        // We should also check if the new polygon is still valid.
                    } else setSelectedFields?.([...(selectedFields || []), field]);
                    // Otherwise add it from selectedFields
                }
            }
        },
        [mergeMode, fields, selectedFields, setSelectedFields],
    );

    // onHover Event Callback
    const handleMergeHover = useCallback(
        (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            if (mergeMode && map) {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: selectableFields?.map((field) => `${POLYGON_SLUGS.POLYGON}-${field.id}`),
                });
                if (features.length) {
                    map.getCanvas().style.cursor = 'pointer';
                } else {
                    map.getCanvas().style.cursor = '';
                }
            }
        },
        [mergeMode, map, selectableFields],
    );

    const computeSelectableFields = useCallback(() => {
        const alreadySelectedFields = selectedFields || [];
        const adjacentFields: FieldT[] = [];

        // Get only the fields that are not already selected
        const availableFields = fields?.filter((obj1) => !selectedFields?.some((obj2) => obj2.id === obj1.id));

        // Add tolerance buffer to each available field
        const bufferedAvailableFields = availableFields?.map((field) => {
            const bufferedField = { ...field };
            bufferedField.polygon = getBufferedPolygon(field.polygon);
            return bufferedField;
        });

        // Find adjacent fields to already selected fields
        selectedFields?.forEach((selectedField) => {
            bufferedAvailableFields?.forEach((availableField) => {
                if (arePolygonsContiguous(selectedField.polygon, availableField.polygon)) {
                    adjacentFields.push(availableField);
                }
            });
        });

        return alreadySelectedFields.concat(adjacentFields);
    }, [selectedFields, fields]);

    const getBufferedPolygon = (polygon: FieldPolygonT) => {
        return buffer(polygon, toleranceOptions.TOLERANCE_MERGE_BUFFER) as FieldPolygonT;
    };

    // STARTING POINT
    useEffect(() => {
        if (mergeMode) {
            if (!selectedFields?.length) {
                // If no fields are selected, set all fields as selectable. (Starting point)
                setSelectableFields(fields);
            } else {
                // if 1 or more fields are selected, compute which fields are selectable.
                const selectableFields = computeSelectableFields(); // Should we send this to BE instead ?
                setSelectableFields(selectableFields);
            }
        } else {
            setSelectableFields([]);
        }
    }, [map, mergeMode, fields, selectedFields, computeSelectableFields]);

    useEffect(() => {
        selectableFields?.forEach((field) => {
            map?.on('click', `${POLYGON_SLUGS.POLYGON}-${field.id}`, handleMergeUpdate);
        });
        return () => {
            selectableFields?.forEach((field) => {
                map?.off('click', `${POLYGON_SLUGS.POLYGON}-${field.id}`, handleMergeUpdate);
            });
        };
    }, [selectableFields, handleMergeUpdate, map]);

    // Set pointer to cursor for each selectable fields
    useEffect(() => {
        if (mergeMode && map) map.on('mousemove', handleMergeHover);
        return () => {
            map?.off('mousemove', handleMergeHover);
        };
    }, [map, selectableFields, mergeMode, handleMergeHover]);

    // change the color of the layers depending on their selectability
    useEffect(() => {
        if (mergeMode && selectedFields?.length) {
            const nonSelectableFields = fields?.filter(
                (field) => !selectableFields?.some((selectableField) => selectableField.id === field.id),
            );
            nonSelectableFields?.forEach((field) => {
                map?.setPaintProperty(`${POLYGON_SLUGS.POLYGON}-${field.id}`, 'fill-opacity', 0.1);
            });
            selectableFields?.forEach((field) => {
                map?.setPaintProperty(`${POLYGON_SLUGS.POLYGON}-${field.id}`, 'fill-color', 'white');
                map?.setPaintProperty(`${POLYGON_SLUGS.POLYGON}-${field.id}`, 'fill-opacity', 0.6);
            });
        }
    }, [selectableFields, fields, map, mergeMode, selectedFields]);

    const arePolygonsContiguous = (poly1: FieldPolygonT, poly2: FieldPolygonT) => {
        return intersect(poly1, poly2);
    };

    const checkPolygonsContinguous = (polyList: FieldPolygonT[]) => {
        // Start with the first polygon as the base
        let newPolygon = polyList[0];
        const polygonsLeftToBeChecked = [...polyList.slice(1)];

        let polygonsAddedInLastIteration = true;

        // Keep iterating until no new polygons are added
        while (polygonsAddedInLastIteration && polygonsLeftToBeChecked.length > 0) {
            polygonsAddedInLastIteration = false;

            for (let i = polygonsLeftToBeChecked.length - 1; i >= 0; i--) {
                const polygon = polygonsLeftToBeChecked[i];

                // Check if the current polygon is contiguous with the newPolygon
                if (arePolygonsContiguous(getBufferedPolygon(polygon), getBufferedPolygon(newPolygon))) {
                    let newUnion = union(polygon, newPolygon) as FieldPolygonT;
                    if (newUnion?.geometry?.type === ('MultiPolygon' as string)) {
                        const bufferedUnion = union(
                            buffer(polygon, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                            buffer(newPolygon, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                        );
                        if (bufferedUnion?.geometry?.type === 'Polygon') {
                            newUnion = simplify(buffer(bufferedUnion, -toleranceOptions.TOLERANCE_MERGE_BUFFER), {
                                tolerance: 0.0001,
                                highQuality: true,
                                mutate: true,
                            }) as FieldPolygonT;
                        } else {
                            return;
                        }
                    }

                    // Check if the resulting geometry is valid (a single polygon)
                    if (newUnion?.geometry?.type === 'Polygon') {
                        newPolygon = newUnion;
                        polygonsLeftToBeChecked.splice(i, 1);
                        polygonsAddedInLastIteration = true;
                    }
                }
            }
        }
        // After the loop, check if there are any polygons left that couldn't be merged
        if (polygonsLeftToBeChecked.length > 0) return false;
        else return newPolygon;
    };

    const resetLayerStyle = () => {
        fields?.forEach((field) => {
            map?.setPaintProperty(`${POLYGON_SLUGS.POLYGON}-${field.id}`, 'fill-color', 'white');
            map?.setPaintProperty(`${POLYGON_SLUGS.POLYGON}-${field.id}`, 'fill-opacity', 0.6);
        });
    };

    const onFinishMerge = () => {
        if (selectedFields?.length && selectedFields.length >= 2) {
            const newPoly = checkPolygonsContinguous(selectedFields.map((field) => field.polygon));
            if (newPoly) {
                setNewMergedPolygon(newPoly);
                setMergedFieldModalOpen(true);
            } else
                enqueueSnackbar(t('encoding-rotation.pac-file.merge-field-error.field-not-contiguous'), {
                    variant: 'error',
                });
        }
    };

    const onMergedFieldModalClose = () => {
        setMergedFieldModalOpen(false);
        stopMerging(true);
    };

    const merge = () => {
        if (map) {
            setMergeMode?.(true);
        }
    };

    const stopMerging = (resetFields: boolean) => {
        if (map) {
            setMergeMode?.(false);
            resetLayerStyle();

            if (resetFields) {
                setSelectedFields?.([]);
            }
        }
    };

    return {
        merge,
        stopMerging,
        fields,
        editMode,
        drawMode,
        mergeMode,
        setMergeMode,
        onFinishMerge,
        newMergedPolygon,
        selectedFields,
        mergedFieldModalOpen,
        onMergedFieldModalClose,
        splitMode,
    };
};
