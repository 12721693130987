import { Button } from '@soil-capital/ui-kit';
import { useYoyRotationHeaderLogic } from '@modules/yoy/components/YoyRotationHeader/useYoyRotationHeaderLogic';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@soil-capital/ui-kit/material-core';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const YoyRotationHeader = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { t } = useTranslation();
    const { harvestYear, shouldDisplayHeader, copyRotation, isCopyingRotation } = useYoyRotationHeaderLogic();

    return (
        <>
            {shouldDisplayHeader && (
                <>
                    <Typography mt={1} align="center" color="neutral" variant="body2">
                        {t('constants.or')}
                    </Typography>
                    <Box mt={1}>
                        <Button
                            loading={isCopyingRotation}
                            disabled={isCopyingRotation}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => copyRotation(currentSeasonId)}
                        >
                            {t('encoding-rotation.pac-file.copy-from-baseline-button', {
                                harvest_year: harvestYear,
                            })}
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};
