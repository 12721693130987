import api from '@shared/api';
import { fieldYearDataT } from '@shared/components/MapPopup/MapPopup.types';

export const MapPopupFieldOverviewApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMapPopupfield: builder.query<fieldYearDataT[], { fieldId: number; farmSeasonId: number }>({
            query: ({ fieldId, farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/fields/${fieldId}/field-overview`,
                method: 'GET',
            }),
            keepUnusedDataFor: 100,
        }),
    }),
});

export const { useGetMapPopupfieldQuery } = MapPopupFieldOverviewApi;
