import { FARM_PROFILE_STATIC_DATA_TYPE } from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { useGetFarmSeasonProfileStaticDataByTypeQuery } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import {
    FarmProfileDifficultyStaticDataT,
    FarmProfileExpectationStaticDataT,
    FarmProfileFarmTypeStaticDataT,
    FarmProfileObjectiveStaticDataT,
    FarmProfileStaticDataT,
    FarmProfileTillagePracticeStaticDataT,
} from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';
import { FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY } from '@modules/encoding/modules/farmProfile/constants';
import { useTranslation } from 'react-i18next';
import entities from '@shared/entities';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useFarmProfileOptions = () => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: objectives, isLoading: isLoadingObjectives } = useGetFarmSeasonProfileStaticDataByTypeQuery({
        type: FARM_PROFILE_STATIC_DATA_TYPE.FARM_OBJECTIVES,
    });
    const { data: difficulties, isLoading: isLoadingDifficulties } = useGetFarmSeasonProfileStaticDataByTypeQuery({
        type: FARM_PROFILE_STATIC_DATA_TYPE.FARM_DIFFICULTIES,
    });
    const { data: expectations, isLoading: isLoadingExpectations } = useGetFarmSeasonProfileStaticDataByTypeQuery({
        type: FARM_PROFILE_STATIC_DATA_TYPE.FARM_EXPECTATIONS,
    });
    const { data: farmTypes, isLoading: isLoadingFarmTypes } = useGetFarmSeasonProfileStaticDataByTypeQuery({
        type: FARM_PROFILE_STATIC_DATA_TYPE.FARM_TYPE,
    });
    const { data: tillagePractices, isLoading: isLoadingTillagePractices } =
        useGetFarmSeasonProfileStaticDataByTypeQuery({
            type: FARM_PROFILE_STATIC_DATA_TYPE.FARM_TILLAGE_PRACTICE,
        });
    const { livestockState } = entities.livestock.useState({ farmSeasonId: currentSeasonId });

    const sortStaticDataAlphabetically = <T extends FarmProfileStaticDataT[] | undefined>(staticData: T) => {
        return staticData
            ? [...staticData]?.sort((a, b) =>
                  t(FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY[a?.slug]).localeCompare(
                      t(FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY[b?.slug]),
                  ),
              )
            : [];
    };

    const isLoading =
        isLoadingObjectives ||
        isLoadingDifficulties ||
        isLoadingExpectations ||
        isLoadingFarmTypes ||
        isLoadingTillagePractices ||
        livestockState.isLoading;

    return {
        objectives: sortStaticDataAlphabetically(objectives) as unknown as FarmProfileObjectiveStaticDataT[],
        difficulties: sortStaticDataAlphabetically(difficulties) as unknown as FarmProfileDifficultyStaticDataT[],
        expectations: sortStaticDataAlphabetically(expectations) as unknown as FarmProfileExpectationStaticDataT[],
        farmTypes: sortStaticDataAlphabetically(farmTypes) as unknown as FarmProfileFarmTypeStaticDataT[],
        tillagePractices: sortStaticDataAlphabetically(
            tillagePractices,
        ) as unknown as FarmProfileTillagePracticeStaticDataT[],
        isLoading,
    };
};
