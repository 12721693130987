import entities, { OperationT } from '@shared/entities';
import { NavigateFunction } from 'react-router-dom';
import technicalItineraryRoutes from '../technicalItinerary.routes';
import { PROGRESS_DETAIL_CATEGORIES } from '@shared/entities/progress/progress.types';

export const useTechnicalItineraryNavigation = () => {
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const navigateToTechnicalItineraryPage = (
        fieldCropId: number,
        navigate: NavigateFunction,
        operation?: OperationT,
        category?: string,
    ) => {
        if (operation) {
            const operationSlug = staticOperationTypeState.getById(operation.operation_type_id)?.slug;
            navigate(technicalItineraryRoutes.operationForm({ fieldCropId, operationId: operation.id }, operationSlug));
        } else if (category === PROGRESS_DETAIL_CATEGORIES.GLOBAL_CHARACTERISTICS) {
            navigate(technicalItineraryRoutes.globalCharacteristics({ fieldCropId }));
        } else if (category === PROGRESS_DETAIL_CATEGORIES.OPERATIONS_TIMELINE) {
            navigate(technicalItineraryRoutes.operationTimeline({ fieldCropId }));
        } else if (category === 'summary') {
            navigate(technicalItineraryRoutes.fieldCropSummary({ fieldCropId }));
        } else if (category === 'duplication') {
            navigate(technicalItineraryRoutes.operationDuplication({ fieldCropId }));
        } else {
            navigate(technicalItineraryRoutes.introduction());
        }
    };

    return {
        navigateToTechnicalItineraryPage,
    };
};
