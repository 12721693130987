import { makeStyles } from '@soil-capital/ui-kit/style';

export const useHomePageStyles = makeStyles()((theme) => ({
    page: {
        position: 'relative',
    },
    title: {
        display: 'block',
        width: '100%',
        minWidth: '100%',
        textAlign: 'center',
        paddingTop: theme.spacing(3),
        fontSize: theme.typography.h1.fontSize,
        background: 'none',
    },
    content: {
        marginTop: theme.spacing(1),
        position: 'relative',
        maxWidth: 750,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
    },
    rings: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        [theme.breakpoints.down('lg')]: {
            right: '-15%',
            bottom: '-15%',
        },
        width: '409px',
        height: '270px',
        zIndex: 3,
    },
    messageTitle: {
        zIndex: 4,
        position: 'relative',
    },
    messageDescription: {
        zIndex: 4,
        position: 'relative',
        marginBottom: theme.spacing(4),
    },
}));
