import { useNavigate, useRoutes } from 'react-router-dom';
import { drainageRoutesConfig } from './drainage.routes';
import { encodingShared } from '@modules/encoding/shared';
import { IntroductionContentPage } from './pages/IntroductionContentPage/IntroductionContentPage';
import { Guard } from '@shared/guards/Guard';
import { IsStepVisibleAsyncGuard } from '@shared/guards/IsStepVisibleGuard';
import { IsTechnicalItineraryStepCompletedAsyncGuard } from '@shared/guards/IsTechnicaltineraryStepCompletedGuard';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: drainageRoutesConfig.introduction,
            element: (
                <Guard
                    element={<IntroductionContentPage navigate={navigate} />}
                    guards={[IsStepVisibleAsyncGuard, IsTechnicalItineraryStepCompletedAsyncGuard]}
                />
            ),
        },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
