import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { Card, IconButton, NumberInput } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useLivestockDetailsQuantitiesFormStyles } from './LivestockDetailsQuantitiesForm.style';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { LivestockT } from '@shared/entities/livestock/livestock.types';
import { useLivestockDetailsForm } from '../../hooks/useLivestockDetailsForm';
import { useMemo, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const LivestockDetailsQuantitiesForm = ({
    navigate,
    livestock,
    translationKey,
}: {
    navigate: NavigateFunction;
    livestock: LivestockT;
    translationKey: string;
}) => {
    const { t } = useTranslation();
    const { classes } = useLivestockDetailsQuantitiesFormStyles();
    const { onDelete, onUpdateQuantity, updatingLivestock } = useLivestockDetailsForm({ navigate });
    const [quantity, setQuantity] = useState<number | null>(livestock.quantity);
    const updatingLivestockIds = useMemo(() => updatingLivestock.map((el) => el.id), [updatingLivestock]);
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <Card spacing={2}>
            <div className={classes.headerContainer}>
                <div>
                    <Typography display="block" className={classes.title}>
                        {t('encoding-livestock.details.animal.type').toLocaleUpperCase()}
                    </Typography>
                    <Typography variant="h3" display="block">
                        {t(translationKey)}
                    </Typography>
                </div>
                <div className={classes.iconCloseZone}>
                    <IconButton
                        disabled={updatingLivestockIds.includes(livestock.id) || readOnly}
                        size="small"
                        onClick={() => onDelete({ livestockId: livestock.id })}
                    >
                        <IconClose className={classes.iconClose} />
                    </IconButton>
                </div>
            </div>
            <form>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-livestock.details.type.quantities', {
                            livestockName: t(translationKey),
                        })}
                    />
                    <NumberInput
                        min={0}
                        disabled={livestock.id < 0 || readOnly}
                        incrementationStep={1}
                        placeholder={t('encoding-livestock.details.type.quantities.placeholder')}
                        value={quantity}
                        onChange={(v) => {
                            setQuantity(v);
                            onUpdateQuantity({ ...livestock, quantity: v });
                        }}
                    ></NumberInput>
                </FormRow>
            </form>
        </Card>
    );
};
