import { Navigate } from 'react-router-dom';
import { PropsWithChildren, ReactElement } from 'react';

export type GuardT = PropsWithChildren<{
    redirectUrl: string;
    /** display a `Loader` component while checking the guard. Will not be used if BaseGuard has renderWhileLoading=true
     */
    Loader?: ReactElement;
}>;

type BaseGuardProps = GuardT & {
    passed: boolean | undefined;
    /**
     * Allow the children to render while loading.\
     * This to handle loaders by the children page (avoid skeleton cascade + avoid chaining data fetches)
     */
    renderWhileLoading?: boolean;
};

export const BaseGuard = ({ passed, redirectUrl, children, Loader, renderWhileLoading = false }: BaseGuardProps) => {
    const isLoading = passed === undefined;

    if (isLoading) {
        if (renderWhileLoading) {
            return <>{children}</>;
        }

        return Loader || null;
    }

    if (!passed) return <Navigate to={redirectUrl} />;

    return <>{children}</>;
};
