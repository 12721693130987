import { MainScreenLayout } from '@shared/components';
import { Header } from '@shared/components/Header/Header';
import { GlobalNav } from '@shared/components/GlobalNav/GlobalNav';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { Trans } from 'react-i18next';
import { useHomePageStyles } from './HomePage.style';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { HomePageMessage } from './HomePageMessage';
import { Rings } from '@shared/components/Rings/Rings';
import { PaymentCTAModal } from './components/PaymentCTAModal/PaymentCTAModal';
import { NewSeasonModal } from '@modules/yoy/components/NewSeasonModal/NewSeasonModal';
import { DeferredPaymentInfoBox } from './components/DeferredPaymentInfoBox/DeferredPaymentInfoBox';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { classes } = useHomePageStyles();
    const { farmer } = useGetFarmer();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { data } = useGetFarmPaymentStatusQuery({ farmId: currentSeason?.farm_id });
    const { data: partnerInfo } = useGetPartnerInfoQuery({ userId: farmer?.id });
    const isPartnerPrepaying = !!partnerInfo?.is_partner_prepaying;
    const hasToPayForBaseline = !!(
        data?.status === 'deferred' &&
        currentSeason?.has_results_published &&
        !isPartnerPrepaying
    );

    useEffect(() => {
        // no season found? redirect to / will trigger the useEffect in encoding guard file & redirect the user
        if (!currentSeason && !currentSeasonLoading) navigate('/');
    }, [currentSeason, currentSeasonLoading, navigate]);

    return (
        <MainScreenLayout
            headerComponent={<Header />}
            navComponent={<GlobalNav />}
            contentBgColor={theme.palette.darkScale[50]}
        >
            <ContentPage
                Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
                Title={
                    <ContentPage.Title variant="body2" className={classes.title}>
                        <Trans i18nKey="pages.home.title" values={{ userName: farmer?.first_name }} />
                    </ContentPage.Title>
                }
                actionBox={hasToPayForBaseline ? <DeferredPaymentInfoBox /> : undefined}
            >
                <div className={classes.content}>
                    <HomePageMessage />
                </div>
            </ContentPage>
            <Rings className={classes.rings} />
            <PaymentCTAModal userName={farmer?.first_name || ''} />
            <NewSeasonModal />
        </MainScreenLayout>
    );
};
