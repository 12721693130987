import { useNavigate, useRoutes } from 'react-router-dom';
import { livestockRoutesConfig } from './livestock.routes';
import { Guard } from '@shared/guards/Guard';
import { encodingShared } from '@modules/encoding/shared';
import { IntroductionContentPage } from './pages/IntroductionContentPage/IntroductionContentPage';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import LivestockDetailsActionPage from './pages/LivestockDetailsActionPage/LivestockDetailsActionPage';
import { LivestockContextualPanel } from './pages/LivestockContextualPanel/LivestockContextualPanel';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { IsTechnicalItineraryStepCompletedAsyncGuard } from '@shared/guards/IsTechnicaltineraryStepCompletedGuard';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: livestockRoutesConfig.introduction,
            element: (
                <Guard
                    element={<IntroductionContentPage navigate={navigate} />}
                    loader={<ActionPageSkeleton />}
                    guards={[IsStepVisibleGuard, IsTechnicalItineraryStepCompletedAsyncGuard]}
                />
            ),
        },
        {
            path: livestockRoutesConfig.base,
            element: <LivestockContextualPanel />,
            children: [
                {
                    path: livestockRoutesConfig.livestockDetails,
                    element: (
                        <Guard
                            element={<LivestockDetailsActionPage navigate={navigate} />}
                            loader={<ActionPageSkeleton />}
                            guards={[IsStepVisibleGuard, IsTechnicalItineraryStepCompletedAsyncGuard]}
                        />
                    ),
                },
            ],
        },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
