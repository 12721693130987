import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetStaticCrops } from '@shared/hooks/useGetStaticCrops';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useFieldCropNeedFinalisation } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropNeedFinalisation';
import { useGetHistoryDuplicationDataQuery } from '@modules/encoding/modules/history/api/historyApi';

export const useDuplicationCropList = () => {
    const { t } = useTranslation();

    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { cropState: farmSeasonCrops } = useGetFarmSeasonCrops();
    const { harvestedCrops, harvestedCropsLoading } = farmSeasonCrops.useHarvestedCrops({
        countryId: currentFarm?.country_id,
    });
    const { staticCropState } = useGetStaticCrops();
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { doesFieldCropNeedFinalisation } = useFieldCropNeedFinalisation();
    const { data: historyDuplication } = useGetHistoryDuplicationDataQuery({ farmSeasonId: currentSeasonId });

    const isLoading =
        seasonState.isLoading ||
        staticSeasonState.isLoading ||
        fieldCropState.isLoading ||
        farmSeasonCrops.isLoading ||
        staticCropState.isLoading ||
        currentFarmLoading ||
        staticCropGroupState.isLoading ||
        progressState.isLoading ||
        harvestedCropsLoading ||
        fieldState.isLoading;

    const getFieldDataList = ({ cropId }: { cropId: number }) => {
        const fieldCrops = fieldCropState.getByCropId({ cropId });

        return fieldCrops.map((fieldCrop) => {
            const fieldCropProgress = progressState.computeProgressDetail(
                'technical-itinerary',
                'farm_season_field_crop_id',
                fieldCrop.id,
            );

            const field = fieldState.getById(fieldCrop.farm_season_field_id);
            if (!field) {
                throw Error('no field found for fieldId:' + fieldCrop.farm_season_field_id);
            }

            const needFinalisation = doesFieldCropNeedFinalisation(fieldCrop.id);

            return {
                fieldId: field.id,
                fieldCropId: fieldCrop.id,
                name: field.name,
                area: field.area,
                currentProgress: needFinalisation ? 99 : fieldCropProgress.done,
                maxProgress: needFinalisation ? 100 : fieldCropProgress.total || 1,
            };
        });
    };

    const formattedCropList = harvestedCrops?.map((fsc) => {
        const staticCrop = staticCropState.getById(fsc.crop_id);
        const cropGroup = staticCropGroupState.getById(staticCrop?.crop_group_id);
        const image = encodingShared.getCropGroupImg(cropGroup?.slug);
        const fieldDataList = getFieldDataList({ cropId: fsc.id });
        const maxProgress = fieldDataList.length;
        const currentProgress = fieldDataList.reduce((current, field) => {
            const fieldProgressDone = field.maxProgress === field.currentProgress;
            return current + (fieldProgressDone ? 1 : 0);
        }, 0);

        const textProgress = t('encoding-technical-itinerary.introduction.crop-progress-text', {
            currentProgress,
            maxProgress,
        });

        const cropDuplicationData = historyDuplication?.find((data) => data?.target_farm_season_crop_id === fsc.id);
        const sourceFarmSeason = seasonState?.getById(cropDuplicationData?.source_farm_season_id);
        const duplicatedFrom = staticSeasonState?.getById(sourceFarmSeason?.season_id)?.harvest_year;

        return {
            id: fsc.id,
            cropId: staticCrop?.id,
            name: t(staticCrop?.translation_slug ?? ''),
            totalArea: fieldDataList.reduce((total, field) => total + field.area, 0),
            image,
            progress: textProgress as string,
            maxProgress,
            currentProgress,
            duplicatedFrom,
        };
    });

    const sortedCropDataList = [...formattedCropList]
        .sort((cropData1, cropData2) => cropData2.totalArea - cropData1.totalArea)
        .filter((cropData) => cropData.maxProgress > 0);

    return { farmSeasonCrops: sortedCropDataList, isLoading };
};
