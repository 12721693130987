import { makeStyles } from '@soil-capital/ui-kit/style';

export const useImagePanelStyle = makeStyles()((theme) => ({
    imgContainer: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        height: '100%',
        borderRadius: theme.shape.borderRadiusM,
        width: '370px',
    },
    img: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        borderRadius: theme.shape.borderRadiusS,
        objectFit: 'cover',
    },
    fadeIn: {
        opacity: 0,
        transition: 'opacity 0.2s ease-out',
    },
    visible: {
        opacity: 1,
    },
}));
