import { useForm } from '@shared/hooks';
import * as z from 'zod';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetFarmSeasonProfileQuery } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { usePostProfileComment } from '@modules/encoding/modules/farmProfile/hooks/usePostProfileComment';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useEffect } from 'react';

export const COMMENT_INPUT_NAME = 'comment';

export const COMMENT_ERRORS = {
    TOO_LONG: 'too-long',
    WHITESPACE: 'whitespace',
    STRIP_TAGS: 'strip-tags',
} as const;

const schema = z.object({
    [COMMENT_INPUT_NAME]: z
        .string()
        .max(1000, { message: COMMENT_ERRORS.TOO_LONG })
        .refine((value) => value === '' || (value !== '' && value.trim() !== ''), {
            message: COMMENT_ERRORS.WHITESPACE,
        })
        .refine((value) => !/<[^>]*>/g.test(value), { message: COMMENT_ERRORS.STRIP_TAGS })
        .optional(),
});

export const useFarmProfileCommentFormLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();

    const { data, isLoading: isLoadingProfile } = useGetFarmSeasonProfileQuery(
        currentSeasonId ? { farmSeasonId: currentSeasonId } : skipToken,
    );

    const { postComment } = usePostProfileComment();

    const methods = useForm({
        schema,
        defaultValues: {
            [COMMENT_INPUT_NAME]: data?.comment ?? '',
        },
    });

    useEffect(() => {
        methods.reset({ [COMMENT_INPUT_NAME]: data?.comment ?? '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingProfile]);

    const handleSubmit = useStableDebounce(
        methods.handleSubmit(async (values) => {
            if (values?.comment && data?.id)
                await postComment({
                    farmSeasonId: currentSeasonId,
                    farmSeasonProfileId: data.id,
                    comment: values.comment,
                });
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    return { methods, handleSubmit, isLoadingProfile, errors: methods.formState.errors };
};
