import { FunctionComponent, PropsWithChildren, ReactElement, ReactNode, SVGProps } from 'react';
import useContentPageStyle from './ContentPage.style';
import { Button, ButtonPropsT } from '@soil-capital/ui-kit';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Box, Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { TypographyProps } from '@soil-capital/ui-kit/material-core';

const ContentPage = ({
    children,
    Image,
    Title,
    Action,
    Subtitle,
    isLoading = false,
    actionBox,
    Footer,
}: {
    children: ReactNode;
    Image?: ReactElement;
    Title?: ReactElement;
    Action?: ReactElement;
    Subtitle?: ReactElement;
    isLoading?: boolean;
    actionBox?: ReactElement;
    Footer?: ReactElement;
}) => {
    const { classes, cx } = useContentPageStyle();

    return (
        <div className={classes.contentPage}>
            <div className={cx('transition-panel', classes.root)}>
                {actionBox}
                {Image}
                <Box sx={{ pt: 2, pr: 4, pb: 4, pl: 4 }}>
                    <Box className={classes.container}>
                        {Title}
                        {Action}
                    </Box>
                    {Subtitle}
                    {children}
                </Box>

                <div
                    className={`${classes.contentPageSkeleton} ${isLoading ? classes.contentPageLoading : ''}`}
                    data-testid="skeleton-section"
                >
                    <Skeleton className={classes.imageSkeleton} />
                    <Box sx={{ p: 4 }}>
                        <Box display="flex" justifyContent="space-between" marginBottom={1}>
                            <Skeleton variant="text" className={classes.titleSkeleton} />
                            <Box display="flex" alignItems="center" marginLeft={8}>
                                <Skeleton>
                                    <Button
                                        variant="contained"
                                        endIcon={<IconArrowRight />}
                                        size="large"
                                        className={classes.actionButton}
                                    />
                                </Skeleton>
                            </Box>
                        </Box>
                        <Skeleton variant="text" className={classes.subtitleSkeleton} />
                        <>
                            <Skeleton variant="rectangular" className={classes.childrenSkeleton} />
                            <Skeleton variant="rectangular" className={classes.childrenSkeleton} />
                        </>
                    </Box>
                </div>
            </div>
            {Footer}
        </div>
    );
};

const ContentPageImage = ({
    src,
    alt = '',
}: {
    src?: string | FunctionComponent<SVGProps<SVGElement>>;
    alt?: string;
}) => {
    const { classes } = useContentPageStyle();

    return (
        <div>
            {typeof src === 'string' ? (
                <img src={src} className={classes.image} alt={alt} loading="lazy" />
            ) : (
                src && src({ className: classes.image })
            )}
        </div>
    );
};

const ContentPageTitle = ({ children, variant = 'h2', className, ...rest }: PropsWithChildren<TypographyProps>) => {
    const { classes, cx } = useContentPageStyle();

    return (
        <Typography className={cx(classes.titleSection, className)} variant={variant} {...rest}>
            {children}
        </Typography>
    );
};

const ContentPageSubtitle = ({
    children,
    variant = 'body',
    className,
    ...rest
}: PropsWithChildren<TypographyProps>) => {
    const { classes, cx } = useContentPageStyle();

    return (
        <Typography className={cx(classes.subtitle, className)} variant={variant} {...rest}>
            {children}
        </Typography>
    );
};

const ContentPageAction = ({
    children,
    className,
    variant = 'contained',
    endIcon = <IconArrowRight />,
    size = 'large',
    onClick,
    ...rest
}: PropsWithChildren<ButtonPropsT>) => {
    const { classes, cx } = useContentPageStyle();

    return (
        <Button
            className={cx(classes.actionButton, className)}
            variant={variant}
            endIcon={endIcon}
            size={size}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Button>
    );
};

const ContentPageFooter = ({ children, className }: { children: ReactNode; className?: string }) => {
    const { classes, cx } = useContentPageStyle();

    return <div className={cx(classes.footer, className)}>{children}</div>;
};

ContentPage.Image = ContentPageImage;
ContentPage.Title = ContentPageTitle;
ContentPage.Subtitle = ContentPageSubtitle;
ContentPage.Action = ContentPageAction;
ContentPage.Footer = ContentPageFooter;

export { ContentPage };
