import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useFieldCropAssignationMap = ({
    readOnly,
    selectedCropId,
}: {
    readOnly: boolean;
    selectedCropId: number | null;
}) => {
    const { t } = useTranslation();

    const mapBannerHelpText = readOnly
        ? undefined
        : !selectedCropId
        ? t('encoding-rotation.modal.right-panel.field-selection-disabled-as-no-crop-selected')
        : t('selection-on-map-available');

    const dispatch = useDispatch();
    const setHoveredFieldId = (fieldId: number | null) => {
        dispatch(fieldSelectionAssignationSlice.actions.setHoveredFieldId(fieldId));
    };
    const setFocusedFieldId = (fieldId: number) => {
        dispatch(fieldSelectionAssignationSlice.actions.setFocusedFieldIds([fieldId]));
    };

    return {
        setFocusedFieldId,
        mapBannerHelpText,
        setHoveredFieldId,
    };
};
