export type RouteParamsT<P extends string> = { [k in P]: number | string | undefined };
export type RouteParamsToStringT<P extends void | { [k in string]: number | string | undefined }> = {
    [k in keyof P | 'seasonId']: string;
};

/** replace the '/:param' with the param value passed. */
export const constructRouteWithParams = <P extends string>(
    path: P,
    params: Record<string, string | number | undefined>,
) => {
    const routeWithParamValues = Object.entries(params).reduce((pathToReplace, [param, value]) => {
        if (!pathToReplace.includes(`:${param}`)) throw new Error(`path does not include provided param ${param}`);
        return pathToReplace.replace(`:${param}`, String(value));
    }, path as string) as P;

    // remove ending "/" if exists. Can happen as we build the route with `${config.A}/${config.B}`
    const hasTrailingSlash = routeWithParamValues.at(-1) === '/';
    const routeWithoutTrailingSlash = hasTrailingSlash ? routeWithParamValues.slice(0, -1) : routeWithParamValues;

    return routeWithoutTrailingSlash;
};
