import { Card, Button } from '@soil-capital/ui-kit/components';
import { useTranslation, Trans } from 'react-i18next';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { useUpdatePaymentMeanLogic } from './useUpdatePaymentMeanLogic';
import { useUpdatePaymentMeanStyle } from './UpdatePaymentMean.style';

export const UpdatePaymentMean = ({
    farmId,
    priceToPay,
    currency,
}: {
    farmId: number;
    priceToPay: number;
    currency: string;
}) => {
    const { t } = useTranslation();
    const { classes } = useUpdatePaymentMeanStyle();
    const {
        paymentMethodLast4,
        isPaymentMethodExpired,
        paymentMethodType,
        isPaymentMethodLoading,
        isRedirectUrlLoading,
        handleBillingPortalSession,
    } = useUpdatePaymentMeanLogic(farmId);

    return isPaymentMethodLoading ? (
        <Skeleton width="100%" height="154px" />
    ) : (
        <Card className={isPaymentMethodExpired || !paymentMethodLast4 ? classes.error : classes.padding}>
            <Typography variant="body2">
                <Trans
                    i18nKey={
                        !!paymentMethodLast4
                            ? isPaymentMethodExpired
                                ? 'pages.payment.billing-info.update-payment-mean.text-expired'
                                : 'pages.payment.billing-info.update-payment-mean.text'
                            : 'pages.payment.billing-info.update-payment-mean.text-none'
                    }
                    values={{
                        price: priceToPay,
                        last_four_digits: paymentMethodLast4,
                        currency,
                        payment_mean:
                            paymentMethodType === 'sepa_debit' ? t('constants.bank-account') : t('constants.bank-card'),
                    }}
                />
            </Typography>
            <Button
                className={classes.dark}
                variant="text"
                endIcon={<IconArrowRight />}
                loading={isRedirectUrlLoading}
                onClick={handleBillingPortalSession}
            >
                {!!paymentMethodLast4
                    ? t('pages.payment.billing-info.update-payment-mean.cta')
                    : t('pages.payment.billing-info.add-payment-mean.cta')}
            </Button>
        </Card>
    );
};
