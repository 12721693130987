import api from '@shared/api';
import { CropDestinationStaticDataT } from './cropDestinationApi.types';
export const cropDestinationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCropDestinations: builder.query<CropDestinationStaticDataT[], void>({
            query: () => ({
                url: `/v3/static/static-data/CROP_DESTINATION`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetCropDestinationsQuery } = cropDestinationApi;
