import { IrrigationEndpointParamsT, IrrigationT } from './irrigation.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';

export const irrigationCacheAdapter = createEntityAdapter<IrrigationT>({
    sortComparer: getDefaultSortComparerById<IrrigationT>(),
});

const irrigationCacheQueryHelper = createCacheQueryHelper<IrrigationEndpointParamsT, IrrigationT, 'Irrigation'>(
    api,
    'Irrigation',
    irrigationCacheAdapter,
);

export default {
    transformResponse: irrigationCacheQueryHelper.transformResponse,
    add: irrigationCacheQueryHelper.add,
    update: irrigationCacheQueryHelper.update,
    delete: irrigationCacheQueryHelper.remove,
    upsertMany: irrigationCacheQueryHelper.upsertMany,
};
