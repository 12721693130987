import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { useSoilAnalysisFormActionPageLogic } from './useSoilAnalysisFormActionPage.logic';
import { SoilAnalysisForm } from '../../components/SoilAnalysisForm';

export default function SoilAnalysisFormActionPage({ navigate }: { navigate: NavigateFunction }) {
    const { t } = useTranslation();
    const {
        handleBackClick,
        handleContinueClick,
        fieldName,
        handleFormChange,
        initialFormValues,
        isLoading,
        soilAnalysisStaticData,
        continueButtonIsDisabled,
        isCloseLoading,
    } = useSoilAnalysisFormActionPageLogic({ navigate });

    return (
        <encodingShared.ActionPage
            title={t('encoding-soil-analysis.soil-analysis-form-action-page.title', { fieldName })}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                children: t('constants.continue'),
                onClick: handleContinueClick,
                disabled: continueButtonIsDisabled,
                loading: isCloseLoading,
            }}
            isLoading={isLoading}
        >
            <SoilAnalysisForm
                handleFormChange={handleFormChange}
                initialFormValues={initialFormValues}
                soilAnalysisStaticData={soilAnalysisStaticData}
            />
        </encodingShared.ActionPage>
    );
}
