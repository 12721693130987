import { useGetResultsQuery } from '@modules/results/api/results.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

export const useResultsEarning = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();

    const { data: results, isLoading: isLoadingResults } = useGetResultsQuery({ farmSeasonId: currentSeasonId });
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();

    const isLoading = isLoadingResults || isLoadingHarvestYear || currentSeasonLoading;
    const isNetEmitter = results?.ghg_balance ? results.ghg_balance > 0 : null;
    const isAuditDone = currentSeason?.audit_status === 'done';

    return { currentSeasonId, results, harvestYear, isLoading, isNetEmitter, isAuditDone };
};
