import { ConfirmModal, ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { useConfirmDeleteAllOperationsModalStyles } from './useConfirmDeleteAllOperationsModal.style';
import { ModalPropsT } from '@shared/hooks/useModalController';

type ConfirmDeleteAllOperationModalProps = ModalPropsT<void, ConfirmModalCloseDataT> & {
    confirmLoading: boolean;
};

export const ConfirmDeleteAllOperationsModal = ({
    isOpen,
    onClose,
    confirmLoading,
}: ConfirmDeleteAllOperationModalProps) => {
    const { t } = useTranslation();
    const { classes } = useConfirmDeleteAllOperationsModalStyles();

    return (
        <ConfirmModal
            isOpen={isOpen || confirmLoading}
            onClose={onClose}
            title={t('encoding-technical-itinerary.timeline.delete-all.modal.title')}
            secondaryText={{
                translationKey: 'encoding-technical-itinerary.timeline.delete-all.modal.text',
            }}
            confirmText={t('encoding-technical-itinerary.timeline.delete-all.modal.cta')}
            cancelText={t('constants.cancel')}
            confirmButtonClassName={classes.redButton}
            confirmLoading={confirmLoading}
        />
    );
};
