import { SidePanel, Stack } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { SelectedCrop } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedCrop/SelectedCrop';
import { CropList } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/CropList/CropList';
import { useFieldCropAssignationModalLeftPanelLogic } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalLeftPanel/useFieldCropAssignationModalLeftPanelLogic';
import { CropListPicker } from '@modules/encoding/modules/rotation/components/CropListPicker/CropListPicker';
import { FarmSeasonCropDeleteModal } from '@modules/encoding/modules/rotation/components/FarmSeasonCropDeleteModal/FarmSeasonCropDeleteModal';
import { FarmSeasonCropEditModal } from '../../../FarmSeasonCropEditModal/FarmSeasonCropEditModal';

export const FieldCropAssignationModalLeftPanel = () => {
    const { t } = useTranslation();
    const {
        harvestYear,
        selectedCrop,
        numberOfFarmSeasonCrops,
        onDeleteIconClicked,
        confirmDeleteCropModal,
        changeStaticCropModal,
    } = useFieldCropAssignationModalLeftPanelLogic();

    return (
        <>
            <SidePanel
                header={
                    <SidePanel.Header>
                        <Typography variant="h3" mb={2}>
                            {t('encoding-rotation.modal.left-panel.title')}
                        </Typography>
                        <Typography color="neutral" variant="body2" mb={2}>
                            {t('encoding-rotation.modal.left-panel.description', { harvestYear })}
                        </Typography>
                        <Stack gap={3}>
                            <CropListPicker />
                            {numberOfFarmSeasonCrops > 0 && (
                                <>
                                    <div>
                                        <Typography
                                            variant="caption"
                                            textTransform="uppercase"
                                            component="p"
                                            marginBottom={1}
                                        >
                                            {t('encoding-rotation.modal.left-panel.selected-crop-title')}
                                        </Typography>
                                        {selectedCrop ? (
                                            <SelectedCrop
                                                farmSeasonCropId={selectedCrop.id}
                                                onDelete={() => onDeleteIconClicked(selectedCrop.id)}
                                                onEdit={() =>
                                                    changeStaticCropModal.open({ farmSeasonCropId: selectedCrop.id })
                                                }
                                            />
                                        ) : (
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                color="neutral"
                                                style={{ fontSize: 14 }}
                                            >
                                                {t('encoding-rotation.modal.left-panel.selected-crop-placeholder')}
                                            </Typography>
                                        )}
                                    </div>
                                    <Typography variant="caption" textTransform="uppercase" component="p">
                                        {t('encoding-rotation.modal.left-panel.crop-list-title', {
                                            cropsAmount: numberOfFarmSeasonCrops,
                                        })}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </SidePanel.Header>
                }
                body={
                    <SidePanel.Body>
                        {numberOfFarmSeasonCrops > 0 && (
                            <CropList
                                onDelete={onDeleteIconClicked}
                                onEdit={(cropId) => changeStaticCropModal.open({ farmSeasonCropId: cropId })}
                            />
                        )}
                    </SidePanel.Body>
                }
            />
            <FarmSeasonCropDeleteModal modalController={confirmDeleteCropModal} />
            <FarmSeasonCropEditModal modalController={changeStaticCropModal} />
        </>
    );
};
