import { useDuplicationActionPanelStyles } from './DuplicationActionPanel.style';
import { encodingShared } from '@modules/encoding/shared';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Button } from '@soil-capital/ui-kit/components';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { DuplicationActionPanelSkeleton } from './DuplicationActionPanelSkeleton';
import { DuplicationModal } from '../DuplicationModal/DuplicationModal';
import { DuplicationFieldT, DuplicationModalLeftPanel } from '../DuplicationModalLeftPanel/DuplicationModalLeftPanel';
import { FieldSelectionPanel } from '../../FieldSelectionPanel/FieldSelectionPanel';
import { PropertiesType } from '../DuplicationModalLeftPanelPropertiesList/DuplicationModalLeftPanelPropertiesList';
import { ConfirmModal, ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type DuplicationActionPanelProps = {
    // Panel props
    panelTitle: string;
    panelSubtitle: string;
    copyButtonLabel: string;
    exceptionButtonLabel: string;
    isLoading: boolean;
    isExceptionButtonLoading?: boolean;
    handleExceptionClick: () => void;
    handleDuplicateClick: () => void;
    // Modal props
    sourceField?: DuplicationFieldT;
    excludedFieldIds?: number[];
    propertiesList: PropertiesType[];
    onTargetFieldSelect: (fieldId: number) => void;
    onTargetFieldDeselect: (fieldId: number) => void;
    openDuplicationModal: boolean;
    toggleDuplicationModal: () => void;
    // Modal Left Panel props
    modalTitle: string;
    modalLeftPanelTitle: string;
    modalLeftPanelSubtitle: string;
    // Modal Right Panel props
    modalRightPanelTitle: string;
    modalRightPanelSubtitle: string;
    onModalRightPanelCopyButtonClick: () => Promise<unknown>;
    modalRightPanelCopyButtonisDisabled: boolean;
    modalRightPanelCopyButtonLabel: string;
    confirmOverrideWarningModalProps?: {
        isOpen: boolean;
        data: void | null;
        onClose: ((closeData: ConfirmModalCloseDataT) => void) | null;
    };
    warningModalTitle?: string;
    warningModalDescription?: string;
    warningModalDescriptionSecondary?: string;
};

export const DuplicationActionPanel = ({
    panelTitle,
    panelSubtitle,
    copyButtonLabel,
    exceptionButtonLabel,
    isLoading,
    isExceptionButtonLoading,
    handleExceptionClick,
    handleDuplicateClick,
    sourceField,
    excludedFieldIds,
    propertiesList,
    onTargetFieldSelect,
    onTargetFieldDeselect,
    openDuplicationModal,
    toggleDuplicationModal,
    modalTitle,
    modalLeftPanelTitle,
    modalLeftPanelSubtitle,
    modalRightPanelTitle,
    modalRightPanelSubtitle,
    onModalRightPanelCopyButtonClick,
    modalRightPanelCopyButtonisDisabled,
    modalRightPanelCopyButtonLabel,
    confirmOverrideWarningModalProps,
    warningModalTitle,
    warningModalDescription,
    warningModalDescriptionSecondary,
}: DuplicationActionPanelProps) => {
    const { classes } = useDuplicationActionPanelStyles();
    const [isDoingCopy, setIsDoingCopy] = useState<boolean>(false);
    const { t } = useTranslation();
    if (isLoading || !sourceField) return <DuplicationActionPanelSkeleton />;

    return (
        <encodingShared.ActionPanel>
            <div className={classes.content}>
                <Typography variant="h2" className={classes.title}>
                    {panelTitle}
                </Typography>
                <Typography>{panelSubtitle}</Typography>
            </div>
            <img className={classes.image} src="/assets/images/duplication/duplication-intro.png" alt="duplication" />
            <div className={classes.actions}>
                <Button variant="contained" color="white" onClick={handleDuplicateClick}>
                    <IconLightning /> <span>{copyButtonLabel}</span>
                </Button>
                <Button variant="text" color="white" onClick={handleExceptionClick} loading={isExceptionButtonLoading}>
                    {exceptionButtonLabel}
                </Button>
            </div>
            <DuplicationModal
                title={modalTitle}
                leftPanel={
                    <DuplicationModalLeftPanel
                        title={modalLeftPanelTitle}
                        description={modalLeftPanelSubtitle}
                        sourceField={sourceField}
                        propertiesList={propertiesList}
                    />
                }
                rightPanel={
                    <FieldSelectionPanel
                        title={modalRightPanelTitle}
                        description={modalRightPanelSubtitle}
                        isLoading={false}
                        onDeselect={onTargetFieldDeselect}
                        onSelect={onTargetFieldSelect}
                        excludedFieldIds={excludedFieldIds}
                        onButtonAction={onModalRightPanelCopyButtonClick}
                        isButtonDisabled={modalRightPanelCopyButtonisDisabled}
                        buttonLabel={modalRightPanelCopyButtonLabel}
                        isDuplication={true}
                        setIsDoingCopy={setIsDoingCopy}
                    />
                }
                open={openDuplicationModal}
                onClose={toggleDuplicationModal}
                isDoingCopy={isDoingCopy}
            />
            {confirmOverrideWarningModalProps && (
                <ConfirmModal
                    {...confirmOverrideWarningModalProps}
                    title={warningModalTitle}
                    primaryText={{
                        translationKey: warningModalDescription || '',
                    }}
                    secondaryText={{
                        translationKey: warningModalDescriptionSecondary || '',
                    }}
                    type="warning"
                    confirmText={t('constants.confirm-deletion')}
                    cancelText={t('constants.cancel')}
                />
            )}
        </encodingShared.ActionPanel>
    );
};
