import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetRenewalInformationQuery, useGetNextFarmSeasonProductPlanQuery } from '@modules/payment/api/paymentApi';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { useStartNewSeason } from '@modules/yoy/hooks/useStartNewSeason';
import { useCanOpenNewSeason } from '@shared/hooks/useCanOpenNewSeason';
import { useGetLatestStaticSeason } from '@shared/hooks/useGetLatestStaticSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useNetOffLogic = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { currentSeason } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);

    const farmId = currentSeason?.farm_id;
    const { data: renewalInformation } = useGetRenewalInformationQuery({ farmId });
    const { data: nextFarmSeasonProductPlan } = useGetNextFarmSeasonProductPlanQuery({
        farmSeasonId: activeSeason?.id,
    });
    const { canOpenNewSeason, isLoading: isLoadingCanOpenNewSeason } = useCanOpenNewSeason();
    const { startNewSeason, isLoading: isStartingNewSeason } = useStartNewSeason({
        onSuccess: (nextFarmSeasonId) => navigate(`/${nextFarmSeasonId}/farm-profile`),
    });
    const latestSeason = useGetLatestStaticSeason(currentSeasonId);

    const handleRedirect = async () => {
        if (canOpenNewSeason) {
            if (!currentSeason || !nextFarmSeasonProductPlan) return;
            await startNewSeason(currentSeason?.id);
        } else navigate(`/${activeSeason?.id}`);
    };
    const harvestYear = latestSeason?.harvest_year;
    const currency = renewalInformation?.currency.symbol ?? '€';
    const price = nextFarmSeasonProductPlan?.subtotal ?? 0;

    useEffect(() => {
        if (renewalInformation && nextFarmSeasonProductPlan) {
            setIsLoading(false);
        }
    }, [renewalInformation, nextFarmSeasonProductPlan]);

    return {
        isLoading: isLoading || isLoadingCanOpenNewSeason,
        canOpenNewSeason,
        handleRedirect,
        harvestYear,
        currency,
        price,
        isCtaLoading: isStartingNewSeason,
    };
};
