// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-enter .transition-panel {
    opacity: 0;
}

.fade-enter-active .transition-panel {
    transition: ease-in opacity 0.1s 0.1s;

    opacity: 1;
}

.fade-exit {
    opacity: 1;
    z-index: 999;
}

.fade-exit-active {
    opacity: 0;
    transition: ease-out opacity 0.1s 0.1s;
    z-index: 999;
}

.fade-exit .transition-panel {
    opacity: 1;
}

.fade-exit-active .transition-panel {
    opacity: 0;
    transition: ease-out opacity 0.1s;
}
`, "",{"version":3,"sources":["webpack://./src/modules/encoding/shared/components/FadeTransition/fade-transition.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,qCAAqC;;IAErC,UAAU;AACd;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,sCAAsC;IACtC,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,iCAAiC;AACrC","sourcesContent":[".fade-enter .transition-panel {\n    opacity: 0;\n}\n\n.fade-enter-active .transition-panel {\n    transition: ease-in opacity 0.1s 0.1s;\n\n    opacity: 1;\n}\n\n.fade-exit {\n    opacity: 1;\n    z-index: 999;\n}\n\n.fade-exit-active {\n    opacity: 0;\n    transition: ease-out opacity 0.1s 0.1s;\n    z-index: 999;\n}\n\n.fade-exit .transition-panel {\n    opacity: 1;\n}\n\n.fade-exit-active .transition-panel {\n    opacity: 0;\n    transition: ease-out opacity 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
