import { MenuItem, Select } from '@soil-capital/ui-kit';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { useMemo } from 'react';

export type SelectOptionItemT = {
    label: string;
    value: string | number;
};

type ValueT = string | number | null | undefined;
type SelectOptionT = {
    value: ValueT;
    options: SelectOptionItemT[];
    onChange: (v: ValueT) => void;
    placeholder?: string;
    disabled?: boolean;
    // width // hardcoded to 250px for now
};

const FAKE_EMPTY_VALUE = 'fake-empty-value-for-placeholder';

/**
 * The ui-kit already provide a Select. But still with a mindset of flexibility. This brings some boilerplade that is copy/pasted.
 * This Select is more restrictif and straight forward to avoid repeat detail implementation like placeholder, list items ...
 */
export const SelectOption = ({ onChange, options, value, disabled, placeholder }: SelectOptionT) => {
    const optionsMappingLabel = useMemo(() => {
        return Object.fromEntries(options?.map((option) => [option.value, option.label]));
    }, [options]);
    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnChange = (e: any) => {
        const newValue = e.target?.value === FAKE_EMPTY_VALUE ? null : e.target?.value;
        onChange(newValue);
    };

    return (
        <Select
            value={value === '' || value === null || value === undefined ? FAKE_EMPTY_VALUE : value}
            style={{ width: '250px' }}
            disabled={disabled}
            onChange={handleOnChange}
            renderValue={(v: unknown) => {
                return v === FAKE_EMPTY_VALUE ? (
                    <span style={{ color: theme.palette.darkScale[700] }}>{placeholder}</span>
                ) : (
                    <span>{optionsMappingLabel[v?.toString() ?? '']}</span>
                );
            }}
        >
            {/* empty value hidden */}
            <MenuItem sx={{ display: 'none' }} value={FAKE_EMPTY_VALUE} />
            {options.map((option) => (
                <MenuItem key={option.value + option.label} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};
