import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldSelectionAssignationSlice, fieldSelectionDuplicationSlice } from '../../store/fieldSelectionSlice';

export const useFieldSelectionListItemLogic = ({
    fieldId,
    isDuplication,
}: {
    fieldId: number;
    isDuplication?: boolean;
}) => {
    const dispatch = useDispatch();
    const isHovered = useSelector((state: SharedStateT) =>
        isDuplication
            ? state.fieldSelectionDuplication.hoveredId === fieldId
            : state.fieldSelectionAssignation.hoveredId === fieldId,
    );
    const isDisabled = useSelector((state: SharedStateT) => {
        return isDuplication
            ? state.fieldSelectionDuplication.disabledIds.includes(fieldId)
            : state.fieldSelectionAssignation.disabledIds.includes(fieldId);
    });
    const isSelected = useSelector((state: SharedStateT) =>
        isDuplication
            ? state.fieldSelectionDuplication.selectedIds.includes(fieldId)
            : state.fieldSelectionAssignation.selectedIds.includes(fieldId),
    );
    const isLoading = useSelector((state: SharedStateT) =>
        isDuplication
            ? state.fieldSelectionDuplication.loadingIds.includes(fieldId)
            : state.fieldSelectionAssignation.loadingIds.includes(fieldId),
    );
    const setHoveredFieldId = (fieldId: number | null) => {
        if (isDuplication) {
            dispatch(fieldSelectionDuplicationSlice.actions.setHoveredFieldId(fieldId));
        } else {
            dispatch(fieldSelectionAssignationSlice.actions.setHoveredFieldId(fieldId));
        }
    };
    const setFocusedFieldId = (fieldId: number) => {
        if (isDuplication) {
            dispatch(fieldSelectionDuplicationSlice.actions.setFocusedFieldIds([fieldId]));
        } else {
            dispatch(fieldSelectionAssignationSlice.actions.setFocusedFieldIds([fieldId]));
        }
    };

    return {
        isSelected,
        isDisabled,
        isHovered,
        isLoading,
        setFocusedFieldId,
        setHoveredFieldId,
    };
};
