import { usePostCommentMutation } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { PostProfileCommentPayloadT } from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';

export const usePostProfileComment = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    const [postCommentMutation, { isLoading }] = usePostCommentMutation();

    const postComment = async ({ farmSeasonId, farmSeasonProfileId, comment }: PostProfileCommentPayloadT) => {
        try {
            await postCommentMutation({ farmSeasonId, farmSeasonProfileId, comment });
            onSuccess?.();
        } catch (e) {
            console.error('Could not post profile comment');
        }
    };

    return { postComment, isLoading };
};
