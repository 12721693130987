import { useState, useCallback, useMemo, useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import entities, { StaticCropT } from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { PropertiesType } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanelPropertiesList/DuplicationModalLeftPanelPropertiesList';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { encodingShared } from '@modules/encoding/shared';
import { DuplicationFieldT } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanel/DuplicationModalLeftPanel';
import { useModalController } from '@shared/hooks/useModalController';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { enqueueSnackbar } from 'notistack';
import useSoilAnalysisParams from '../../hooks/useSoilAnalysisParams';
import soilAnalysisRoutes from '../../soilAnalysis.routes';

interface SoilAnalysisDuplicationActionPageData {
    sourceField?: DuplicationFieldT;
    excludedFieldIds: number[] | undefined;
    selectedFieldIds: number[] | null;
    propertiesList: PropertiesType[];
    isLoading: boolean;
    openSoilAnalysisDuplicationModal: boolean;
    toggleSoilAnalysisDuplicationModal: () => void;
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    handleDuplicateClick: () => void;
    handleExceptionClick: () => void;
    onModalRightPanelCopyButtonClick: () => Promise<unknown>;
    confirmOverrideSoilAnalysisWarningModalProps: {
        isOpen: boolean;
        data: void | null;
        onClose: ((closeData: ConfirmModalCloseDataT) => void) | null;
    };
}

export const useSoilAnalysisDuplicationActionPageData = ({
    navigate,
}: {
    navigate: NavigateFunction;
}): SoilAnalysisDuplicationActionPageData => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm } = encodingShared.useCurrentFarm();

    // Modal state
    const [openSoilAnalysisDuplicationModal, setOpenSoilAnalysisDuplicationModal] = useState<boolean>(false);

    const toggleSoilAnalysisDuplicationModal = useCallback(() => {
        setOpenSoilAnalysisDuplicationModal((prev) => !prev);
    }, []);

    // Route parameters
    const { fieldId } = useSoilAnalysisParams();
    if (!fieldId) {
        throw new Error('A parameter is unexpectedly missing in the soil analysis duplication route');
    }

    // Entity states
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const [duplicateSoilAnalysis] = entities.field.useDuplicateSoilAnalysis();
    const { notHarvestedFields, notHarvestedFieldsLoading } = fieldState.useNotHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const notHarvestedFieldsIds = notHarvestedFields.map((el) => el.id);
    const sourceField = fieldState.getById(fieldId);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.selectedIds);
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId ?? 0 });
    const { getCropsAssignedToAField, fieldCropStateLoading } = cropState.useGetCropsAssignedToAField();
    const { staticPhState } = entities.staticPh.useState();
    const { staticOrganicCarbonLevelState } = entities.staticOrganicCarbonLevel.useState();

    const sourceFieldEnriched: DuplicationFieldT | undefined = useMemo(() => {
        if (sourceField?.id) {
            const allCrops = getCropsAssignedToAField(sourceField.id);
            const allStaticCrops = allCrops
                .map((crop) => staticCropState.getById(crop.crop_id))
                .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);

            return {
                field: sourceField,
                snapshot: sourceField
                    ? getFieldThumbnail({ fieldId: sourceField.id, updatedAt: sourceField.updated_at })
                    : '',
                crops: allStaticCrops?.map((c) => ({
                    id: c?.id,
                    name: t(`db.crop.slug-${c.slug}`),
                })),
            };
        }
    }, [getCropsAssignedToAField, getFieldThumbnail, sourceField, staticCropState, t]);

    const isLoading =
        cropState.isLoading ||
        fieldState.isLoading ||
        staticCropState.isLoading ||
        fieldCropStateLoading ||
        notHarvestedFieldsLoading ||
        !sourceField;

    const addPercentage = useCallback((str?: string) => {
        if (!str) {
            return;
        }
        str = str.replace(/ *- */g, ' - ');
        str = str.trim();
        if (!str.endsWith('%')) {
            return str + '%';
        }
        return str;
    }, []);

    // Properties list
    const propertiesList = useMemo(
        () => [
            {
                key: 'soil_acidity_id',
                keyLabel: t('encoding-soil-analysis.soil-analysis-form-action-page.acidity-question'),
                valueLabel: addPercentage(staticPhState.getById(sourceField?.soil_acidity_id)?.name) || '',
            },
            {
                key: 'soil_organic_carbon_id',
                keyLabel: t('encoding-soil-analysis.soil-analysis-form-action-page.organic-carbon-question'),
                valueLabel:
                    addPercentage(staticOrganicCarbonLevelState.getById(sourceField?.soil_organic_carbon_id)?.name) ||
                    '',
            },
        ],
        [
            addPercentage,
            sourceField?.soil_acidity_id,
            sourceField?.soil_organic_carbon_id,
            staticOrganicCarbonLevelState,
            staticPhState,
            t,
        ],
    );

    // Warning Modal Controller
    const { open: openOverrideSoilAnalysisWarningModal, ...confirmOverrideSoilAnalysisWarningModalProps } =
        useModalController<void, ConfirmModalCloseDataT>();

    const handleOpenOverrideSoilAnalysisWarningModal = useCallback(
        async (fieldId: number) => {
            const field = fieldState.getById(fieldId);
            const isNotEmpty = field?.soil_acidity_id || field?.soil_organic_carbon_id;
            if (isNotEmpty) {
                const { confirmed } = await openOverrideSoilAnalysisWarningModal();
                return confirmed;
            }
            return true;
        },
        [fieldState, openOverrideSoilAnalysisWarningModal],
    );

    useEffect(() => {
        dispatch(fieldSelectionDuplicationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handlers
    const onSelect = useCallback(
        async (fieldId: number) => {
            const confirmed = await handleOpenOverrideSoilAnalysisWarningModal(fieldId);
            if (confirmed) {
                dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldId]));
            }
        },
        [dispatch, handleOpenOverrideSoilAnalysisWarningModal, selectedFieldIds],
    );

    const onDeselect = useCallback(
        (fieldId: number) => {
            const newSelectedFieldIds = [...selectedFieldIds].filter((el) => el !== fieldId);
            dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds(newSelectedFieldIds));
        },
        [dispatch, selectedFieldIds],
    );

    const handleDuplicateClick = useCallback(() => {
        toggleSoilAnalysisDuplicationModal();
    }, [toggleSoilAnalysisDuplicationModal]);

    const handleExceptionClick = useCallback(() => {
        navigate(soilAnalysisRoutes.introduction());
    }, [navigate]);

    const onModalRightPanelCopyButtonClick = useCallback(async () => {
        if (!sourceField?.id) {
            enqueueSnackbar(t('encoding-soil-analysis.duplication.error'), { variant: 'error' });
            return;
        }
        const response = await duplicateSoilAnalysis({
            farmSeasonId: currentSeasonId,
            id: sourceField.id,
            body: {
                target_field_ids: selectedFieldIds,
            },
        });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('encoding-soil-analysis.duplication.error'), { variant: 'error' });
        } else {
            await lazyGetProgress({ seasonId: currentSeasonId });
            navigate(soilAnalysisRoutes.introduction());
        }
    }, [currentSeasonId, duplicateSoilAnalysis, lazyGetProgress, navigate, selectedFieldIds, sourceField?.id, t]);

    return {
        sourceField: sourceFieldEnriched,
        excludedFieldIds: sourceFieldEnriched
            ? [...notHarvestedFieldsIds, sourceFieldEnriched?.field?.id]
            : [...notHarvestedFieldsIds],
        selectedFieldIds,
        propertiesList,
        isLoading,
        openSoilAnalysisDuplicationModal,
        toggleSoilAnalysisDuplicationModal,
        onSelect,
        onDeselect,
        handleDuplicateClick,
        handleExceptionClick,
        onModalRightPanelCopyButtonClick,
        confirmOverrideSoilAnalysisWarningModalProps,
    };
};
