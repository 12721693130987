import { Grid } from '@soil-capital/ui-kit/material-core';
import { useEffect, useState } from 'react';
import { useImagePanelStyle } from './useImagePanelStyle';

// keep track of img src that has been loaded and is now in cache.
const alreadyLoadedSrcSet = new Set();

/** Image panel that fade the img for the first load. */
export const ImagePanel = ({ src }: { src: string }) => {
    const { classes, cx } = useImagePanelStyle();
    const [imgLoaded, setImgLoaded] = useState(alreadyLoadedSrcSet.has(src));

    useEffect(() => {
        if (!alreadyLoadedSrcSet.has(src) && imgLoaded) {
            alreadyLoadedSrcSet.add(src);
        }
    }, [imgLoaded, src]);

    return (
        <Grid key={src} className={classes.imgContainer}>
            <img
                onLoad={() => setImgLoaded(true)}
                src={src}
                className={cx(classes.img, classes.fadeIn, imgLoaded && classes.visible)}
            />
        </Grid>
    );
};
