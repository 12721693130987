import MapPopup from '@shared/components/MapPopup/MapPopup';
import { MapBanner } from '@shared/map/components/MapBanner/MapBanner';
import { featureSelectionLayers, MapControl, MapLayers, MapPortal } from '@shared/map2';
import { useFieldSelectionMapLogic } from './useFieldSelectionMapLogic';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';

export const FieldSelectionMap = ({
    hiddenFieldIds,
    onSelect,
    onDeselect,
    mapBannerHelpText,
    initialFieldIdsfocused,
    isDuplication,
    excludedFieldIds,
}: {
    hiddenFieldIds?: number[];
    initialFieldIdsfocused: number[] | undefined;
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    mapBannerHelpText?: string;
    isDuplication?: boolean;
    excludedFieldIds?: number[];
}) => {
    const fieldIdHovered = useSelector((state: SharedStateT) =>
        isDuplication ? state.fieldSelectionDuplication.hoveredId : state.fieldSelectionAssignation.hoveredId,
    );
    const fieldIdsFocused = useSelector((state: SharedStateT) =>
        isDuplication ? state.fieldSelectionDuplication.focusedIds : state.fieldSelectionAssignation.focusedIds,
    );

    const {
        hoveredFeatureFieldOverview,
        defaultMapBannerHelpText,
        onFeatureClick,
        onFeatureHover,
        polygons,
        mapLayerSource,
        selectedFieldIds,
    } = useFieldSelectionMapLogic({
        hiddenFieldIds,
        onSelect,
        onDeselect,
        isDuplication,
        excludedFieldIds,
    });

    return (
        <MapPortal>
            <MapBanner helpText={mapBannerHelpText === undefined ? defaultMapBannerHelpText : mapBannerHelpText} />
            <MapControl
                polygons={polygons}
                fallbackCoord={[
                    // as we are in a modal after the field assignation and use initialFieldIdsfocused, we don't need a real fallback. We always have polygons
                    4.8151,
                    50.6242,
                ]}
            />
            <MapLayers
                onFeatureClick={onFeatureClick}
                onFeatureHover={onFeatureHover}
                focusedIds={fieldIdsFocused || initialFieldIdsfocused}
                selectedIds={selectedFieldIds}
                hoveredId={fieldIdHovered}
                dataSource={mapLayerSource}
                layers={featureSelectionLayers}
            />
            <MapPopup hoveredFeatureFieldOverview={hoveredFeatureFieldOverview} />
        </MapPortal>
    );
};
