import { NavigateFunction } from 'react-router-dom';
import entities, { StaticOrganicCarbonLevelT, StaticPhT } from '@shared/entities';
import useSoilAnalysisParams from '../../hooks/useSoilAnalysisParams';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import soilAnalysisRoutes from '../../soilAnalysis.routes';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useMemo, useCallback, useState } from 'react';
import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import { encodingShared } from '@modules/encoding/shared';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import api from '@shared/api';
import { useDispatch } from 'react-redux';

export type SoilAnalysisStaticFormOptionsT = {
    pHLevels: {
        value: number;
        label: string;
    }[];
    organicCarbonLevels: {
        value: number;
        label: string;
    }[];
};

export type SoilAnalysisInitialFormValuesT = {
    pHLevel?: number | null;
    organicCarbonLevel?: number | null;
};

export const useSoilAnalysisFormActionPageLogic = ({ navigate }: { navigate: NavigateFunction }) => {
    const dispatch = useDispatch();
    const { fieldId } = useSoilAnalysisParams();
    const { currentSeasonId } = useCurrentSeasonId();
    const [isCloseLoading, setIsCloseLoading] = useState(false);
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { readOnly } = useFarmSeasonReadOnly();
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const [updateMutation] = entities.field.useUpdate();
    const { staticPhState } = entities.staticPh.useState();
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const { staticOrganicCarbonLevelState } = entities.staticOrganicCarbonLevel.useState();
    const globalSaveStatusData = useGlobalSaveStatusData();
    const { globalMutationPromiseRef } = useGlobalSaveStatusLogic({ dataContext: globalSaveStatusData });
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext: globalSaveStatusData });
    const isPerformingMutation = globalMutationStatus === 'pending';

    if (!fieldId) {
        throw Error('A param is unexpectedly missing in the soil analysis form route');
    }

    const currentField = fieldState.getById(fieldId);

    const addPercentage = useCallback((str: string) => {
        str = str.replace(/ *- */g, ' - ');
        str = str.trim();
        if (!str.endsWith('%')) {
            return str + '%';
        }
        return str;
    }, []);

    const _getSelectOptionItem = useCallback(
        (item: StaticPhT | StaticOrganicCarbonLevelT) => ({
            value: item.id,
            label: addPercentage(item.name),
        }),
        [addPercentage],
    );

    const initialFormValues: SoilAnalysisInitialFormValuesT | null = useMemo(() => {
        if (currentField?.id) {
            return {
                pHLevel: currentField.soil_acidity_id,
                organicCarbonLevel: currentField.soil_organic_carbon_id,
            };
        }
        return null;
    }, [currentField?.id, currentField?.soil_acidity_id, currentField?.soil_organic_carbon_id]);

    const soilAnalysisStaticData: SoilAnalysisStaticFormOptionsT = useMemo(
        () => ({
            pHLevels: staticPhState.list.map(_getSelectOptionItem),
            organicCarbonLevels: staticOrganicCarbonLevelState.list.map(_getSelectOptionItem),
        }),
        [staticPhState.list, staticOrganicCarbonLevelState.list, _getSelectOptionItem],
    );

    const handleFormChange = useStableDebounce(async (formValues: SoilAnalysisInitialFormValuesT) => {
        await updateMutation({
            farmSeasonId: currentSeasonId,
            id: fieldId,
            body: {
                soil_acidity_id: formValues.pHLevel,
                soil_organic_carbon_id: formValues.organicCarbonLevel,
            },
        });
        dispatch(api.util.invalidateTags(['Progress']));
    }, FORM_SUBMIT_DEBOUNCE_TIME_MS);

    const handleBackClick = useCallback(() => {
        navigate(soilAnalysisRoutes.introduction());
    }, [navigate]);

    const handleContinueClick = useCallback(async () => {
        setIsCloseLoading(true);
        await globalMutationPromiseRef.current;
        await lazyGetProgress({ seasonId: currentSeasonId });
        setIsCloseLoading(false);
        if (harvestedFields.length > 1 && !readOnly) {
            navigate(soilAnalysisRoutes.soilAnalysisDuplication({ fieldId }));
        } else {
            navigate(soilAnalysisRoutes.introduction());
        }
    }, [
        currentSeasonId,
        fieldId,
        globalMutationPromiseRef,
        harvestedFields.length,
        lazyGetProgress,
        navigate,
        readOnly,
    ]);

    const continueButtonIsDisabled = useMemo(() => {
        return (
            currentField?.soil_acidity_id === null ||
            currentField?.soil_organic_carbon_id === null ||
            isPerformingMutation
        );
    }, [currentField?.soil_acidity_id, currentField?.soil_organic_carbon_id, isPerformingMutation]);

    const isLoading = useMemo(() => {
        return (
            fieldState.isLoading ||
            staticOrganicCarbonLevelState.isLoading ||
            staticPhState.isLoading ||
            harvestedFieldsLoading
        );
    }, [
        fieldState.isLoading,
        harvestedFieldsLoading,
        staticOrganicCarbonLevelState.isLoading,
        staticPhState.isLoading,
    ]);

    return {
        fieldName: currentField?.name,
        handleBackClick,
        handleContinueClick,
        initialFormValues,
        soilAnalysisStaticData,
        handleFormChange,
        isLoading,
        continueButtonIsDisabled,
        isCloseLoading,
    };
};
