import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import entities from '@shared/entities';
import { useIrrigationRouteParams } from '../../hooks/useIrrigationParams';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { useDispatch } from 'react-redux';
import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { useModalController } from '@shared/hooks/useModalController';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';

export const useIrrigationFieldSelectionModalLogic = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const [isCloseLoading, setIsCloseLoading] = useState(false);
    const globalSaveStatusData = useGlobalSaveStatusData();
    const { globalMutationPromiseRef } = useGlobalSaveStatusLogic({ dataContext: globalSaveStatusData });
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const { seasonId } = useIrrigationRouteParams('introduction');
    const { currentFarm } = useCurrentFarm();
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: seasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: seasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const harvestedFieldsIds = harvestedFields.map((item) => item.id);
    const excludedFieldIds = fieldState.list.filter((el) => !harvestedFieldsIds.includes(el.id)).map((el) => el.id);

    const [createIrrigation] = entities.irrigation.useCreate();
    const [deleteIrrigation] = entities.irrigation.useDelete();

    const { readOnly } = useFarmSeasonReadOnly();
    const confirmDeleteIrrigationController = useModalController<{ fieldId: number }, { confirmed: boolean }>();

    const hasDataChange = useRef(false);

    const handleCreateIrrigation = useCallback(
        (fieldId: number) => {
            const irrigation = irrigationState.getByFieldId(fieldId);
            if (irrigation?.id && irrigation.id > 0) {
                throw Error('Try to create an existing irrigation');
            }
            hasDataChange.current = true;
            createIrrigation({
                farmSeasonId: seasonId,
                body: {
                    farm_season_field_id: fieldId,
                    horizontal_distance: null,
                    irrigation_method_id: null,
                    irrigation_power_source_id: null,
                    irrigation_water_source_id: null,
                    pumping_depth: null,
                    total_amount: null,
                },
            });
        },
        [createIrrigation, irrigationState, seasonId],
    );

    const handleDeleteIrrigation = useCallback(
        async (fieldId: number) => {
            const irrigation = irrigationState.getByFieldId(fieldId);
            if (!irrigation?.id || irrigation.id <= 0) {
                throw Error('Try to delete an unexisting irrigation');
            }
            // check if irrigation is complete
            if (
                irrigation.horizontal_distance !== null ||
                irrigation.irrigation_method_id !== null ||
                irrigation.irrigation_power_source_id !== null ||
                irrigation.irrigation_water_source_id !== null ||
                irrigation.pumping_depth !== null ||
                irrigation.total_amount !== null
            ) {
                const { confirmed } = await confirmDeleteIrrigationController.open({
                    fieldId: irrigation.farm_season_field_id,
                });
                if (!confirmed) {
                    return;
                }
            }
            hasDataChange.current = true;
            deleteIrrigation({ id: irrigation.id, farmSeasonId: seasonId });
        },
        [deleteIrrigation, irrigationState, seasonId, confirmDeleteIrrigationController],
    );

    // Sync fieldSelection data
    useEffect(() => {
        const selectedFieldIds = irrigationState.list.map((irrigation) => irrigation.farm_season_field_id);
        const loadingFieldIds = irrigationState.list
            .filter((irrigation) => irrigation.id < 0)
            .map((irrigation) => irrigation.farm_season_field_id);
        const disabledFieldIds = readOnly ? fieldState.list.map((field) => field.id) : loadingFieldIds;

        dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds(selectedFieldIds));
        dispatch(fieldSelectionAssignationSlice.actions.setLoadingFieldIds(loadingFieldIds));
        dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(disabledFieldIds));
    }, [dispatch, fieldState.list, irrigationState.list, readOnly]);

    const { harvestYear } = useGetCurrentHarvestYear();

    const handleCloseIrrigationFieldSelectionModal = async () => {
        if (hasDataChange.current === false) {
            return onClose();
        }
        setIsCloseLoading(true);
        // wait until mutations are done
        await globalMutationPromiseRef.current;
        // wait the progress refetch
        await lazyGetProgress({ seasonId });

        setIsCloseLoading(false);
        onClose();
        // reset the form dirty check for next opening
        hasDataChange.current = false;
    };

    return {
        handleCloseIrrigationFieldSelectionModal,
        isCloseLoading,
        harvestYear,
        handleCreateIrrigation,
        handleDeleteIrrigation,
        isIrrigationLoading: irrigationState.isLoading || fieldState.isLoading || harvestedFieldsLoading,
        readOnly,
        confirmDeleteIrrigationController,
        excludedFieldIds,
    };
};
