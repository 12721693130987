import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useGetProgressStepMatchingCurrentRoute } from '@shared/hooks/useGetProgressStepMatchingCurrentRoute';

export const useIsStepVisible = () => {
    const { currentProgressStep, isLoading } = useGetProgressStepMatchingCurrentRoute();

    return isLoading ? undefined : !!currentProgressStep?.is_visible;
};

export const IsStepVisibleGuard = (props: GuardT) => {
    const passed = useIsStepVisible();

    return <BaseGuard passed={passed} {...props} />;
};

export const IsStepVisibleAsyncGuard = (props: GuardT) => {
    const passed = useIsStepVisible();
    return <BaseGuard passed={passed} renderWhileLoading {...props} />;
};
