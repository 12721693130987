import { ReactNode } from 'react';
import { useSkeletonContainerStyle } from './useSkeletonContainerStyle';

export const SkeletonContainer = ({
    Content,
    Skeletons,
    isLoading,
    className,
    bgColor = 'white',
}: {
    Content: ReactNode;
    Skeletons: ReactNode;
    isLoading: boolean;
    className?: string;
    bgColor?: string;
}) => {
    const { classes, cx } = useSkeletonContainerStyle({ bgColor });
    return (
        <div className={cx(classes.skeletonContainer, isLoading && classes.overflowHidden, className)}>
            {Content}
            <div className={`${classes.skeleton} ${isLoading ? classes.skeletonLoading : ''}`}>{Skeletons}</div>
        </div>
    );
};
