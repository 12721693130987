import { makeStyles } from 'tss-react/mui';

export const useYoyDuplicationAccordionStyles = makeStyles()((theme) => ({
    accordion: {
        backgroundColor: theme.palette.darkScale[50],
        boxShadow: 'none',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    actionLabel: {
        marginRight: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        color: theme.palette.darkScale[600],
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        fontWeight: 'bold',
    },
    select: {
        width: 280,
        height: 40,
    },
}));
