import { EntityState } from '@reduxjs/toolkit';
import api from '@shared/api';
import { LivestockBodyT, LivestockEndpointParamsT, LivestockT } from './livestock.types';
import livestockCache from './livestock.cache';

export const livestockApiInstance = api.injectEndpoints({
    endpoints: (builder) => ({
        getLivestock: builder.query<EntityState<LivestockT>, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/livestocks`,
                method: 'GET',
            }),
            transformResponse: livestockCache.transformResponse,
            providesTags: ['Livestock'],
        }),
        createLivestock: builder.mutation<EntityState<LivestockT>, LivestockEndpointParamsT & { body: LivestockBodyT }>(
            {
                query: ({ body, farmSeasonId }) => ({
                    url: `/v3/farm-seasons/${farmSeasonId}/livestocks`,
                    method: 'POST',
                    body,
                }),
                onQueryStarted: livestockCache.add('cache-then-fetch'),
                invalidatesTags: ['Progress'],
            },
        ),
        deleteLivestock: builder.mutation<
            EntityState<LivestockT>,
            { farmSeasonId: number; farmSeasonLivestockId: number }
        >({
            query: ({ farmSeasonId, farmSeasonLivestockId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/livestocks/${farmSeasonLivestockId}`,
                method: 'DELETE',
            }),
            onQueryStarted: async ({ farmSeasonId, farmSeasonLivestockId }, apiContext) => {
                livestockCache.remove('cache-only')({ farmSeasonId, id: farmSeasonLivestockId }, apiContext);
            },
            invalidatesTags: ['Progress'],
        }),
        updateLivestock: builder.mutation<
            EntityState<LivestockT>,
            { farmSeasonId: number; farmSeasonLivestockId: number; body: LivestockBodyT }
        >({
            query: ({ farmSeasonId, farmSeasonLivestockId, body }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/livestocks/${farmSeasonLivestockId}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId, farmSeasonLivestockId, body }, apiContext) => {
                livestockCache.update('cache-only')({ farmSeasonId, id: farmSeasonLivestockId, body }, apiContext);
            },
            invalidatesTags: ['Progress'],
        }),
        updateLivestockProgress: builder.mutation<EntityState<LivestockT>, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/livestocks-progress`,
                method: 'PUT',
            }),
            invalidatesTags: ['Progress'],
        }),
    }),
});

export default {
    useGetAll: livestockApiInstance.useGetLivestockQuery,
    useDelete: livestockApiInstance.useDeleteLivestockMutation,
    useCreate: livestockApiInstance.useCreateLivestockMutation,
    useUpdate: livestockApiInstance.useUpdateLivestockMutation,
    useUpdateProgress: livestockApiInstance.useUpdateLivestockProgressMutation,
};
