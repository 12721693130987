import {
    IrrigationCreateBodyT,
    IrrigationDuplicateBodyT,
    IrrigationEndpointParamsT,
    IrrigationT,
    IrrigationUpdateBodyT,
} from './irrigation.types';
import { EntityState } from '@reduxjs/toolkit';
import irrigationCache from './irrigation.cache';
import api from '@shared/api';

const url = (farmSeasonId?: number) => `/v3/farm-seasons/${farmSeasonId}/irrigations`;

export const irrigationApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getIrrigation: build.query<EntityState<IrrigationT>, IrrigationEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            providesTags: ['Irrigation'],
            transformResponse: irrigationCache.transformResponse,
        }),
        updateIrrigation: build.mutation<
            IrrigationT,
            IrrigationEndpointParamsT & { body: IrrigationUpdateBodyT; id: number }
        >({
            query: ({ body, id, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: irrigationCache.update('cache-only'),
            invalidatesTags: ['Progress'],
        }),
        createIrrigation: build.mutation<IrrigationT, IrrigationEndpointParamsT & { body: IrrigationCreateBodyT }>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}`,
                method: 'POST',
                body,
            }),
            onQueryStarted: irrigationCache.add('cache-then-fetch'),
        }),
        deleteIrrigation: build.mutation<IrrigationT, IrrigationEndpointParamsT & { id: number }>({
            query: ({ id, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: irrigationCache.delete('cache-only'),
        }),
        duplicateIrrigation: build.mutation<
            { irrigations_updated_or_created: IrrigationT[] },
            IrrigationEndpointParamsT & { id: number; body: IrrigationDuplicateBodyT }
        >({
            query: ({ id, body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${id}/duplicate`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: duplicateIrrigationResponse } = await apiContext.queryFulfilled;
                await irrigationCache.upsertMany('cache-only')(
                    { farmSeasonId, entities: duplicateIrrigationResponse.irrigations_updated_or_created },
                    apiContext,
                );
            },
        }),
    }),
});

export default {
    useUpdate: irrigationApiInstance.useUpdateIrrigationMutation,
    useCreate: irrigationApiInstance.useCreateIrrigationMutation,
    useDelete: irrigationApiInstance.useDeleteIrrigationMutation,
    useDuplicate: irrigationApiInstance.useDuplicateIrrigationMutation,
    useGet: irrigationApiInstance.useGetIrrigationQuery,
};
