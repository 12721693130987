import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';
import { LivestockEndpointParamsT, LivestockT } from './livestock.types';

export const livestockCacheAdapter = createEntityAdapter<LivestockT>({
    sortComparer: getDefaultSortComparerById<LivestockT>(),
});

const livestockCacheQueryHelper = createCacheQueryHelper<LivestockEndpointParamsT, LivestockT, 'Livestock'>(
    api,
    'Livestock',
    livestockCacheAdapter,
);

export default {
    transformResponse: livestockCacheQueryHelper.transformResponse,
    update: livestockCacheQueryHelper.update,
    add: livestockCacheQueryHelper.add,
    remove: livestockCacheQueryHelper.remove,
};
