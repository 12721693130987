import { useCopyFarmProfileFromPreviousMutation } from '@modules/yoy/api/yoyApi';

export const useCopyFarmSeasonProfileFromPrevious = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    const [copyProfile, { isLoading }] = useCopyFarmProfileFromPreviousMutation();

    const copyProfileFromPrevious = async (farmSeasonId: number) => {
        try {
            await copyProfile({ farmSeasonId });
            onSuccess?.();
        } catch (e) {
            console.error(e);
        }
    };

    return {
        copyProfileFromPrevious,
        isLoading,
    };
};
