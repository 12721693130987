import api from '@shared/api';
import {
    GetHistoricalFarmSeasonsParamsT,
    HistoryDuplicationDataT,
    HistoryFarmSeasonT,
} from '@modules/encoding/modules/history/api/historyApi.types';

export const historyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getHistoricalFarmSeasons: builder.query<HistoryFarmSeasonT[], GetHistoricalFarmSeasonsParamsT>({
            queryFn: async (args, api, extraOptions, baseQuery) => {
                const { baselineFarmSeasonId } = args;

                // GET the historical farm seasons
                const { data, error } = await baseQuery({
                    url: `/v3/farm-seasons/${baselineFarmSeasonId}/history/farm-seasons`,
                    method: 'GET',
                });

                const historyFarmSeasons = data as HistoryFarmSeasonT[];

                if (error) throw error;
                return { data: historyFarmSeasons };
            },
            providesTags: ['Season', 'FieldCrop', 'Field', 'Crop', 'Progress'],
        }),
        createHistoricalFarmSeasons: builder.mutation<HistoryFarmSeasonT[], { baselineFarmSeasonId: number }>({
            query: ({ baselineFarmSeasonId }) => ({
                url: `/v3/farm-seasons/${baselineFarmSeasonId}/history/farm-seasons`,
                method: 'POST',
            }),
            invalidatesTags: ['Season'],
        }),
        getHistoryDuplicationData: builder.query<HistoryDuplicationDataT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/history/duplication`,
                method: 'GET',
            }),
            providesTags: ['HistoryDuplicationData'],
        }),
    }),
});

export const {
    useGetHistoricalFarmSeasonsQuery,
    useLazyGetHistoricalFarmSeasonsQuery,
    useCreateHistoricalFarmSeasonsMutation,
    useGetHistoryDuplicationDataQuery,
} = historyApi;
