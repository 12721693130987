import { SidePanel } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@soil-capital/ui-kit/material-core';
import { CropDestinationList } from '../CropDestinationList/CropDestinationList';
import { useSelectedCropDestinationStyles } from './FieldCropDestinationLeftPanel.style';
import { useFieldCropDestinationLeftPanelLogic } from './FieldCropDestinationLeftPanelLogic';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';

export const FieldCropDestinationLeftPanel = () => {
    const { t } = useTranslation();
    const { classes } = useSelectedCropDestinationStyles();
    const { harvestYear, isLoading, cropDestinationList } = useFieldCropDestinationLeftPanelLogic();

    return (
        <>
            <SidePanel
                header={
                    <SidePanel.Header>
                        <Typography variant="h3" mb={2} className={classes.textIndent}>
                            {t('encoding-rotation.crop-destination.left-panel.title')}
                        </Typography>
                        <Typography color="neutral" variant="body2">
                            {t('encoding-rotation.crop-destination.left-panel.description', { harvestYear })}
                        </Typography>
                        <Typography color="neutral" variant="body" mb={2}>
                            {t('encoding-rotation.crop-destination.left-panel.description-help')}
                        </Typography>
                    </SidePanel.Header>
                }
                body={
                    <SidePanel.Body className={classes.container}>
                        <SkeletonContainer
                            isLoading={isLoading}
                            Skeletons={
                                <>
                                    <Skeleton height="64px" variant="rounded" className={classes.margin} />
                                    <Skeleton height="64px" variant="rounded" />
                                </>
                            }
                            Content={<CropDestinationList cropDestinationList={cropDestinationList} />}
                        />
                    </SidePanel.Body>
                }
            />
        </>
    );
};
