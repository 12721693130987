import { makeStyles } from '@soil-capital/ui-kit/style';

export const useDuplicationModalLeftPanelStyles = makeStyles()((theme) => ({
    sourceFieldTitle: {
        textTransform: 'uppercase',
        color: theme.palette.darkScale[600],
        marginBottom: theme.spacing(1),
    },
    fieldCropInfos: {
        border: `1px solid ${theme.palette.darkScale[300]}`,
    },
}));
