import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { formatNumber } from '@shared/utils';
import { Loader, SelectableList } from '@soil-capital/ui-kit/components';
import { IconCheckCircle, IconPlusCircle } from '@soil-capital/ui-kit/icons';
import { FieldT } from '@shared/entities';
import { cropNamesT } from '@shared/map/types/mapTypes';
import { memo, MutableRefObject } from 'react';
import { useFieldSelectionListItemStyle } from './useFieldSelectionListItemStyle';
import { useFieldSelectionListItemLogic } from './useFieldSelectionListItemLogic';

// extracted for performance enhancement
export const FieldSelectionListItem = memo(
    ({
        field,
        crops,
        snapshot,
        onDeselectRef,
        onSelectRef,
        isDuplication,
    }: {
        field: FieldT;
        snapshot: string;
        crops: cropNamesT[];
        onSelectRef: MutableRefObject<(fieldId: number) => void>;
        onDeselectRef: MutableRefObject<(fieldId: number) => void>;
        isDuplication?: boolean;
    }) => {
        const { classes } = useFieldSelectionListItemStyle();
        const { isSelected, isDisabled, isHovered, isLoading, setFocusedFieldId, setHoveredFieldId } =
            useFieldSelectionListItemLogic({
                fieldId: field.id,
                isDuplication,
            });

        return (
            <SelectableList.Item
                onChange={() => {
                    isSelected ? onDeselectRef.current(field.id) : onSelectRef.current(field.id);
                }}
                onMouseEnter={isDisabled ? undefined : () => setHoveredFieldId(field.id)}
                onMouseLeave={() => setHoveredFieldId(null)}
                isSelected={isSelected}
                isSelectable={!isDisabled}
                setHover={isHovered}
            >
                <FieldCropInfoBox
                    disabled={isDisabled}
                    mapSnapshot={snapshot}
                    fieldName={field.name}
                    fieldArea={`${formatNumber(field.area, 2)} HA`}
                    cropNames={crops}
                    className={classes.noPadding}
                    handleFieldClick={(e) => {
                        e.preventDefault();
                        setFocusedFieldId(field.id);
                    }}
                    icon={
                        isLoading ? (
                            <Loader size={24} />
                        ) : isSelected ? (
                            <IconCheckCircle color="success" />
                        ) : (
                            <IconPlusCircle />
                        )
                    }
                />
            </SelectableList.Item>
        );
    },
    (oldProps, newProps) => oldProps.field.id === newProps.field.id,
);
