import { makeStyles } from 'tss-react/mui';

export const useYoyDuplicationCropListStyles = makeStyles()((theme) => ({
    check: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        maxWidth: 700,
    },
    cropList: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        marginLeft: theme.spacing(3),
        minHeight: '170px',
    },
    progressContainer: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));
