import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { NOT_FOUND_ROUTE } from '@shared/errors';

export const useGuardsRedirect = ({ redirectTo }: { redirectTo?: string } = {}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { firstNotDoneStep, lastCompletedStep, getRouteMatchingStep, isLoading } = useProgressTracker();

    const formatRoute = (route?: string | null) =>
        `${currentSeasonId ? `/${currentSeasonId}` : ''}${route?.startsWith('/') ? route ?? '' : `/${route}`}`;

    if (isLoading) return { redirectUrl: '' };
    if (redirectTo) return { redirectUrl: redirectTo };
    if (firstNotDoneStep) return { redirectUrl: formatRoute(getRouteMatchingStep(firstNotDoneStep.slug)) };
    if (lastCompletedStep) return { redirectUrl: formatRoute(getRouteMatchingStep(lastCompletedStep.slug)) };

    return { redirectUrl: formatRoute(NOT_FOUND_ROUTE) };
};
