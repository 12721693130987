import { useCallback } from 'react';
import createDefaultState from '../createDefaultState';
import treeApi from './tree.api';
import { treeCacheAdapter } from './tree.cache';
import { TreeEndpointParamsT } from './tree.types';

export default ({ farmSeasonId }: TreeEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(treeCacheAdapter, () =>
        treeApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    const getByFieldId = useCallback(
        (fieldId: number) => defaultState.list.filter((el) => el.farm_season_field_id === fieldId),
        [defaultState.list],
    );

    return {
        treeState: {
            getByFieldId,
            ...defaultState,
        },
    };
};
