import { MapDraw } from './MapDraw/MapDraw';
import { MapEdit } from './MapEdit/MapEdit';
import { MapSplit } from './MapSplit/MapSplit';
import { MapMerge } from './MapMerge/MapMerge';
import useFieldControlStyle from './FieldControls.style';

export const FieldControls = ({ readOnly }: { readOnly: boolean }) => {
    const { classes } = useFieldControlStyle();

    return (
        <div className={classes.container}>
            <MapDraw readOnly={readOnly} />
            <MapEdit readOnly={readOnly} />
            <MapSplit readOnly={readOnly} />
            <MapMerge readOnly={readOnly} />
        </div>
    );
};
