import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { formatNumber } from '@shared/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import agroforestryRoutes from '../../agroforestry.routes';
import { PROGRESS_DETAIL_CATEGORIES } from '@shared/entities/progress/progress.types';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useLogErrorMutation } from '@shared/api/logErrorApi';

type FieldDataT = {
    id: number;
    name: string;
    image: string;
    infoText: string;
    maxProgress: number;
    currentProgress: number;
};

export const useIntroductionContentData = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const [logError] = useLogErrorMutation();
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { treeState } = entities.tree.useState({ farmSeasonId: currentSeasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;
    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const [isAgroforestryFieldSelectionModalOpen, setIsAgroforestryFieldSelectionModalOpen] = useState(false);
    const openAgroforestryFieldSelectionModal = () => setIsAgroforestryFieldSelectionModalOpen(true);
    const closeAgroforestryFieldSelectionModal = () => setIsAgroforestryFieldSelectionModalOpen(false);

    const isDataLoading =
        harvestedFieldsLoading ||
        currentFarmLoading ||
        currentSeasonLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading ||
        progressState.isLoading ||
        treeState.isLoading ||
        staticSeasonState.isLoading;

    const getFieldInfoText = ({ fieldId, fieldArea }: { fieldId: number; fieldArea: number }) => {
        const firstFieldCrop = fieldCropState.getByFieldId({ fieldId }).at(0);
        const crop = cropState.getById(firstFieldCrop?.farm_season_crop_id);
        const staticCrop = staticCropState.getById(crop?.crop_id);

        const infoText = `${t(staticCrop?.translation_slug ?? '')} ${formatNumber(fieldArea, 2)} HA`;

        return infoText;
    };

    const FieldsWithAgroforestry = harvestedFields.filter((field) => field.has_agroforestry);
    const fieldDataList = FieldsWithAgroforestry.map<FieldDataT>((field) => {
        const infoText = getFieldInfoText({ fieldArea: field.area, fieldId: field.id });
        const fieldThumbnail = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });
        const currentProgressDetail = progressState.getBySlugAndFieldId('agroforestry', field.id)?.[0];

        const currentProgress = currentProgressDetail?.is_done
            ? 1
            : treeState.getByFieldId(field.id).length > 0
            ? 0.5
            : 0;

        return { currentProgress, id: field.id, image: fieldThumbnail, infoText, maxProgress: 1, name: field.name };
    });

    const handleClickOnField = ({ fieldId }: { fieldId: number }) => {
        navigate(agroforestryRoutes.agroforestryForm({ fieldId }));
    };

    // Assignation progress
    const maxAgroforestryAssignationProgress = 1; // We know there is only 1 field definition step
    const progressDetails = progressState.getBySlug('agroforestry')?.progress_details;
    const fieldAssignationProgress = progressDetails?.find(
        (pd) => pd.category === PROGRESS_DETAIL_CATEGORIES.AGROFORESTRY_FIELD_ASSIGNATION,
    );
    const currentAgroforestryAssignationProgress = fieldAssignationProgress?.is_done ? 1 : 0;

    // Form (Practices) Progress
    const agroforestryFormProgress = useMemo(() => {
        return progressState.computeProgressDetail(
            'agroforestry',
            'category',
            PROGRESS_DETAIL_CATEGORIES.ARGOFORESTRY_FORM,
        );
    }, [progressState]);
    const maxAgroforestryPracticeProgress = agroforestryFormProgress.total;
    const currentAgroforestryPracticeProgress = agroforestryFormProgress.done;

    const redirectToNextEncodingStep = useCallback(() => {
        return navigate(`../${getRouteMatchingStep(nextStepToComplete?.slug)}`);
    }, [getRouteMatchingStep, navigate, nextStepToComplete]);

    const handleContinue = useCallback(() => {
        if (
            currentAgroforestryPracticeProgress === maxAgroforestryPracticeProgress &&
            currentAgroforestryAssignationProgress === maxAgroforestryAssignationProgress
        ) {
            return redirectToNextEncodingStep();
        }
        // When progress step is not done AND field-assignation progress detail is NOT done, → open modal "agroforestry field assignation”
        const fieldAssignationProgress = progressState
            .getProgressDetailsByCategory('agroforestry', 'agroforestry-field-assignation')
            .at(0);

        if (!fieldAssignationProgress?.is_done) {
            return openAgroforestryFieldSelectionModal();
        }

        const firstNotCompletedField = fieldDataList.find((el) => el.currentProgress !== el.maxProgress);
        if (!firstNotCompletedField) {
            return logError({
                errors: [
                    "handleContinue can't find the next irrigation to navigate related to the progress detail undone",
                ],
            });
        }
        navigate(agroforestryRoutes.agroforestryForm({ fieldId: firstNotCompletedField.id }));
    }, [
        currentAgroforestryAssignationProgress,
        currentAgroforestryPracticeProgress,
        fieldDataList,
        logError,
        maxAgroforestryPracticeProgress,
        navigate,
        progressState,
        redirectToNextEncodingStep,
    ]);

    return {
        isDataLoading,
        fieldDataList,
        harvestYear,
        handleContinue,
        handleClickOnField,
        maxAgroforestryPracticeProgress,
        currentAgroforestryPracticeProgress,
        maxAgroforestryAssignationProgress,
        currentAgroforestryAssignationProgress,
        isAgroforestryFieldSelectionModalOpen,
        openAgroforestryFieldSelectionModal,
        closeAgroforestryFieldSelectionModal,
    };
};
