import { SharedStateT } from '@shared/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import saveStatusSlice from '../../store/saveStatus.slice';

export const useGlobalSaveStatusData = () => {
    const dispatch = useDispatch();
    const requestStatuses = useSelector((state: SharedStateT) => state.saveStatus.requests);

    const resetRequestStatusList = () => {
        dispatch(saveStatusSlice.actions.removeRequestStatus({ statuses: ['failed', 'pending', 'success'] }));
    };

    return { requestStatusesList: Object.values(requestStatuses), resetRequestStatusList };
};
