import { PacImportFooter } from '@modules/encoding/modules/rotation/components/PacImportFooter/PacImportFooter';
import { PacImportHeader } from '@modules/encoding/modules/rotation/components/PacImportHeader/PacImportHeader';
import { PacImportedFields } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacImportedFields';
import { usePacImportContextualPanelStyles } from '@modules/encoding/modules/rotation/pages/PacImportContextualPanel/PacImportContextualPanel.style';
import { usePacImportContextualPanelData } from '@modules/encoding/modules/rotation/pages/PacImportContextualPanel/usePacImportContextualPanelData';
import { encodingShared } from '@modules/encoding/shared';
import { HelpBox } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PacImportContextualPanel = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = usePacImportContextualPanelStyles();
    const { harvestYear, baselineHarvestYear, showCopiedFromBaselineInfobox, showCapFileAccessInfobox, isLoading } =
        usePacImportContextualPanelData();

    return (
        <encodingShared.ContextualPanel classes={{ contextualSection: classes.panel }} isLoading={isLoading}>
            <div className={classes.container}>
                <PacImportHeader />
                {showCopiedFromBaselineInfobox && (
                    <HelpBox className={classes.helpbox}>
                        {t('encoding-rotation.pac-file.copied-from-baseline-helpbox', {
                            harvest_year: baselineHarvestYear,
                        })}
                    </HelpBox>
                )}
                {showCapFileAccessInfobox && (
                    <HelpBox className={classes.helpbox}>
                        {t('encoding-rotation.pac-file.history-cant-access-pac-file', {
                            harvestYear,
                            baselineHarvestYear,
                        })}
                    </HelpBox>
                )}
                <div className={classes.content}>
                    <PacImportedFields />
                </div>
                <div className={classes.footer}>
                    <PacImportFooter />
                </div>
            </div>
        </encodingShared.ContextualPanel>
    );
};
