import { useDuplicateOperationsFromYearMutation } from '@modules/yoy/api/yoyApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useDuplicateOperationsFromYear = ({
    onSuccess,
    onError,
}: { onSuccess?: () => void; onError?: () => void } = {}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [duplicate, { isLoading }] = useDuplicateOperationsFromYearMutation();

    const duplicateFromYear = async ({ seasonId, cropIds }: { seasonId: number; cropIds: number[] }) => {
        try {
            await duplicate({
                farmSeasonId: currentSeasonId,
                body: { source_season_id: seasonId, crop_ids: cropIds },
            }).unwrap();
            onSuccess?.();
        } catch (e) {
            onError?.();
        }
    };

    return { duplicateFromYear, isLoading };
};
