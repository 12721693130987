import { SelectableList } from '@soil-capital/ui-kit/components';
import { useFieldSelectionListLogic } from './useFieldSelectionListLogic';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useRef } from 'react';
import { FieldSelectionListItem } from '../FieldSelectionListItem/FieldSelectionListItem';

export const FieldSelectionList = ({
    onDeselect,
    onSelect,
    filteredFieldIds,
    isLoading = false,
    excludedFieldIds,
    isDuplication,
}: {
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    filteredFieldIds?: Set<number> | null;
    isLoading?: boolean;
    excludedFieldIds?: number[];
    isDuplication?: boolean;
}) => {
    const { filteredFieldDataList, isLoading: internalLoading } = useFieldSelectionListLogic({
        filteredFieldIds,
        excludedFieldIds,
    });

    // keep Refs to make the rerendered callbacks accessible in the memoized FieldSelectionListItem
    const onDeselectRef = useRef(onDeselect);
    onDeselectRef.current = onDeselect;
    const onSelectRef = useRef(onSelect);
    onSelectRef.current = onSelect;

    return (
        <SkeletonContainer
            isLoading={isLoading || internalLoading}
            Content={
                <SelectableList>
                    {filteredFieldDataList.map(({ field, crops, snapshot }) => {
                        return (
                            <FieldSelectionListItem
                                key={field.id}
                                field={field}
                                crops={crops}
                                snapshot={snapshot}
                                onSelectRef={onSelectRef}
                                onDeselectRef={onDeselectRef}
                                isDuplication={isDuplication}
                            />
                        );
                    })}
                </SelectableList>
            }
            Skeletons={
                <div style={{ minHeight: 600 }}>
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2, mt: 0.5 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                    <Skeleton variant="rounded" sx={{ height: '60px', mb: 2, mx: 2 }} />
                </div>
            }
        />
    );
};
