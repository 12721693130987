import { makeStyles } from '@soil-capital/ui-kit/style';

export const useAgroforestryDetailsFormStyles = makeStyles()((theme) => ({
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        marginBottom: theme.spacing(2),
    },
    title: {
        color: theme.palette.darkScale[600],
    },
    iconClose: {
        color: theme.palette.darkScale[900],
    },
    iconCloseZone: {
        height: '34px',
        width: '34px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    input: {
        width: '50%',
    },
}));
