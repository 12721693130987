import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPaymentMethodQuery, usePostBillingPortalSessionMutation } from '@modules/billing/api/billingApi';
import { PostBillingPortalSessionBodyT } from '@modules/billing/api/billing.types';

export const useUpdatePaymentMeanLogic = (farmId: number) => {
    const { i18n } = useTranslation();
    const { seasonId } = useParams();
    const { data: paymentMethod, isLoading: isPaymentMethodLoading } = useGetPaymentMethodQuery({ farmId });
    const [postBillingPortalSession, { isLoading: isRedirectUrlLoading }] = usePostBillingPortalSessionMutation();

    const handleBillingPortalSession = async () => {
        try {
            const body: PostBillingPortalSessionBodyT = {
                locale: i18n.language,
                return_url: `${process.env.REACT_APP_URL_V3}/${seasonId}/payment/billing-info`,
            };
            const response = await postBillingPortalSession({ farmId, body }).unwrap();
            return (window.location.href = response.billing_portal_session_url);
            // eslint-disable-next-line
        } catch (e: any) {
            console.error(e);
        }
    };

    const paymentMethodLast4 = paymentMethod?.last4;
    const isPaymentMethodExpired = paymentMethod?.is_expired;
    const paymentMethodType = paymentMethod?.type;

    return {
        paymentMethodLast4,
        isPaymentMethodExpired,
        paymentMethodType,
        isPaymentMethodLoading,
        isRedirectUrlLoading,
        handleBillingPortalSession,
    };
};
