import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateFarmSeasonPreparationMutation } from '../../api/preparationApi';
import { useEncodingDoneContentData } from '@modules/encoding/pages/useEncodingDoneContentData';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const usePreparationContentPageLogic = () => {
    const { seasonId } = useParams();
    // We know from encoding guard that this param exists
    const farmSeasonId = Number(seasonId as string);
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const [createMutation, { isLoading: isUpdatingPreparationProgress }] = useCreateFarmSeasonPreparationMutation();
    const { getStepProgress } = useEncodingDoneContentData();
    const { isBaseline } = useBaselineFarmSeason();

    const isLoading = currentSeasonLoading || isLoadingHarvestYear;

    const isYoYFarmSeason = !!currentSeason?.carbon_programme_year && currentSeason.carbon_programme_year > 0;

    const navigate = useNavigate();

    const handleGoNext = async () => {
        if (getStepProgress('preparation').current < getStepProgress('preparation').max) {
            await createMutation({ farmSeasonId: farmSeasonId });
        }

        navigate(`../${encodingRoutesConfig.FARM_PROFILE}`);
    };

    return { isYoYFarmSeason, isBaseline, harvestYear, isUpdatingPreparationProgress, isLoading, handleGoNext };
};
