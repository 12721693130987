import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMapRootStyle = makeStyles()(() => ({
    fadeIn: {
        opacity: 0,
        transition: 'opacity 0.5s ease-out',
    },
    visible: {
        opacity: 1,
    },
    mapRoot: {
        height: '100%',
        flex: '1',
        position: 'relative',
    },
}));
