import { makeStyles } from '@soil-capital/ui-kit/style';

const useDisplayPageStyle = makeStyles()((theme) => ({
    displayPage: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primaryScale[700],
        borderRadius: theme.shape.borderRadiusM,
        height: '100%',
    },
    header: {
        borderBottom: `1px solid ${theme.palette.primaryScale[500]}`,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        '.left-zone': {
            flex: 1,
        },
    },
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primaryScale[800],
            border: `5px solid ${theme.palette.primaryScale[700]}`,
        },
    },
    title: {
        color: theme.palette.common.white,
    },
    subtitle: {
        color: theme.palette.common.white,
    },
    children: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
}));

export default useDisplayPageStyle;
