import { makeStyles } from '@soil-capital/ui-kit/style';

export const useDisplayPageSkeletonStyles = makeStyles()((theme) => ({
    actionPageSkeleton: {
        backgroundColor: theme.palette.primaryScale[700],
        borderRadius: theme.shape.borderRadiusM,
        position: 'absolute',
        zIndex: 900,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
    },
    header: {
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.primaryScale[800]}`,
        display: 'flex',
        alignItems: 'center',
        '.left-zone': {
            flex: 1,
        },
    },
    body: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,
        flex: 1,
        minHeight: 0,
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    title: {
        color: theme.palette.darkScale[700],
        width: '100%',
        height: 78,
    },
    subtitle: {
        color: theme.palette.darkScale[700],
        width: '100%',
        height: 22,
        marginBottom: theme.spacing(2),
    },
    childrenSkeleton: {
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusM,
    },
}));
