import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';

export const useGetLatestStaticSeasonId = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { farmState } = entities.farm.useState({ farmSeasonId: currentSeasonId });
    const farmCountryId = farmState?.list?.[0]?.country?.id;
    const { staticSeasonState } = entities.staticSeason.useState();
    const { staticCountryState } = entities.staticCountry.useState();
    const currentCountryGroupId =
        staticCountryState?.list?.find((country) => country.id === farmCountryId)?.country_group_id || 1;
    const latestStaticSeason = staticSeasonState?.list?.find(
        (season) => season.active === true && season.country_group_id === currentCountryGroupId,
    );

    return {
        latestStaticSeasonId: latestStaticSeason?.id,
    };
};
