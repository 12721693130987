import api from '@shared/api';
import { FeatureCollection } from '@turf/helpers';

export const FarmSeasonBoundaries = api.injectEndpoints({
    endpoints: (builder) => ({
        getFarmSeasonBoundaries: builder.query<FeatureCollection, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/farm-boundaries`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetFarmSeasonBoundariesQuery } = FarmSeasonBoundaries;
