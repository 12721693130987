import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { Stack } from '@soil-capital/ui-kit';
import { useIntroductionContentData } from '@modules/encoding/modules/technicalItinerary/pages/IntroductionContentPage/useIntroductionContentData';

export const TechnicalItineraryCropList = () => {
    const { cropDataList, handleCropClick } = useIntroductionContentData();

    return (
        <Stack spacing={1} mt={1}>
            {cropDataList.map((cropData) => (
                <IntroProgressItem
                    image={cropData.image}
                    key={cropData.cropId}
                    text={cropData.name}
                    progress={{
                        max: cropData.maxProgress,
                        current: cropData.currentProgress,
                        text: cropData.textProgress,
                    }}
                >
                    {cropData.fieldDataList.length > 0 && (
                        <Stack spacing={1} mt={1}>
                            {cropData.fieldDataList.map((fieldData) => (
                                <IntroProgressItem
                                    key={fieldData.fieldId}
                                    onClick={() => handleCropClick(fieldData, cropData)}
                                    text={fieldData.name}
                                    infoText={fieldData.infoText}
                                    image={fieldData.image}
                                    progress={{
                                        max: fieldData.maxProgress,
                                        current: fieldData.currentProgress,
                                    }}
                                />
                            ))}
                        </Stack>
                    )}
                </IntroProgressItem>
            ))}
        </Stack>
    );
};
