import { SelectOptionItemT } from '@shared/components/SelectOption/SelectOption';
import { useEffect, useState } from 'react';
import z from 'zod';

const irrigationFormSchema = z.object({
    irrigationMethodId: z.coerce.number().nullable(),
    irrigationPowerSourceId: z.coerce.number().nullable(),
    irrigationWaterSourceId: z.coerce.number().nullable(),
    pumpingDepth: z.coerce.number().nonnegative().nullable(),
    horizontalDistance: z.coerce.number().nonnegative().nullable(),
    waterAmount: z.coerce.number().nonnegative().nullable(),
});
export type IrrigationFormValuesT = z.infer<typeof irrigationFormSchema>;

export type FormStaticDataT = {
    methodsList: SelectOptionItemT[];
    powerSourceList: SelectOptionItemT[];
    waterSourceList: SelectOptionItemT[];
};

export const useIrrigationFormLogic = ({
    initialFormValues,
    onFormValuesChange,
}: {
    initialFormValues: IrrigationFormValuesT | null;
    onFormValuesChange: (formValues: IrrigationFormValuesT) => void;
}) => {
    const [values, setValues] = useState<IrrigationFormValuesT | null>(null);

    useEffect(() => {
        if (initialFormValues && !values) {
            setValues(initialFormValues);
        }
    }, [initialFormValues, values]);

    const handleFormValuesChange = <T extends keyof IrrigationFormValuesT>(property: T, value: unknown) => {
        setValues((values) => {
            const formValues = irrigationFormSchema.parse({ ...values, [property]: value || null });
            onFormValuesChange(formValues);
            return formValues;
        });
    };

    return { values, handleFormValuesChange };
};
