import { makeStyles } from '@soil-capital/ui-kit/style';

const useActionPageStyle = makeStyles()((theme) => ({
    actionPage: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadiusM,
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
    },
    actionPageSkeleton: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadiusM,
        position: 'absolute',
        zIndex: 900,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        opacity: 0.1,
        visibility: 'hidden',
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
    },
    actionPageLoading: {
        opacity: 1,
        visibility: 'visible',
    },

    header: {
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.darkScale[300]}`,
        display: 'flex',
        alignItems: 'center',
        '.left-zone': {
            flex: 1,
        },
    },
    body: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    subtitle: {
        color: theme.palette.darkScale[700],
    },
    childrenSkeleton: {
        height: '70px',
        marginBottom: '40px',
    },
}));

export default useActionPageStyle;
