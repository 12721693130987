import { Button, Loader, Select } from '@soil-capital/ui-kit';
import { MenuItem } from '@soil-capital/ui-kit/components';
import { Menu, Divider } from '@soil-capital/ui-kit/material-core';
import * as packageInfo from '../../../../package.json';
import { useUserMenuStyles } from '@shared/components/UserMenu/UserMenu.styles';
import { useTranslation } from 'react-i18next';
import { IconLanguage, IconLogout } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import entities from '@shared/entities';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { auth } from '@modules/auth';
import { useGetTermsAndConditions } from '@shared/hooks/useGetTermsAndConditions';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';

type UserMenuProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
};

export const UserMenu = ({ anchorEl, open, handleClose }: UserMenuProps) => {
    const { classes } = useUserMenuStyles();
    const { t } = useTranslation();

    const authUser = auth.useAuthUser();
    const { termsAndConditionsPdfLink } = useGetTermsAndConditions();
    const { staticLanguageState } = entities.staticLanguage.useState();
    const currentLanguage = useCurrentLanguage();

    const { changeLanguage, changeLanguageLoading } = auth.useChangeLanguage();
    const { logout: logoutAndRedirect } = auth.useLogout();

    return (
        <Menu
            className={classes.menu}
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            disableAutoFocusItem
            keepMounted
        >
            {authUser && (
                <TextEllipsisTooltip
                    typographyProps={{
                        variant: 'caption',
                        classes: {
                            root: classes.email,
                        },
                    }}
                    text={authUser?.email}
                />
            )}
            {!staticLanguageState?.isLoading && (
                <Select
                    renderValue={(v: unknown) =>
                        changeLanguageLoading ? (
                            <Loader color="primary" size={16} />
                        ) : (
                            staticLanguageState?.list?.find((item) => item.iso_code === v)?.name
                        )
                    }
                    className={classes.select}
                    startAdornment={<IconLanguage className={classes.icon} />}
                    value={currentLanguage?.iso_code}
                    fullWidth
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e) => changeLanguage({ isoCode: e?.target.value as any })}
                >
                    {staticLanguageState?.list?.map((item) => (
                        <MenuItem key={item.id} value={item.iso_code} disableRipple>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
            <Divider className={classes.divider} />
            <MenuItem disableRipple>
                <Button className={classes.logout} fullWidth variant="text" onClick={logoutAndRedirect}>
                    <IconLogout />
                    {t('pages.homepage.logout')}
                </Button>
            </MenuItem>
            <a href={termsAndConditionsPdfLink} target="_blank" rel="noreferrer" className={classes.termsOfUse}>
                {t('pages.homepage.terms-of-use')}
            </a>
            <Typography component="div" variant="body2" className={classes.version}>
                {t('constants.version')}: {packageInfo.version}
            </Typography>
        </Menu>
    );
};
