import { useToggle } from '@shared/hooks/useToggle';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const useYoyDuplicationModule = () => {
    const { isBaseline } = useBaselineFarmSeason();
    const { open, handleOpen, handleClose, handleToggle } = useToggle();

    return {
        shouldDisplay: isBaseline !== undefined && !isBaseline,
        isModuleOpened: open,
        openModule: handleOpen,
        closeModule: handleClose,
        toggleModule: handleToggle,
    };
};
