import { useState, useCallback, useMemo, useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import entities, { FieldT, StaticCropT, TreeT } from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { PropertiesType } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanelPropertiesList/DuplicationModalLeftPanelPropertiesList';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { encodingShared } from '@modules/encoding/shared';
import { DuplicationFieldT } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanel/DuplicationModalLeftPanel';
import { useModalController } from '@shared/hooks/useModalController';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { enqueueSnackbar } from 'notistack';
import useAgroforestryParams from '../../hooks/useAgroforestryParams';
import agroforestryRoutes from '../../agroforestry.routes';

interface AgroforestryDuplicationActionPageData {
    sourceField: DuplicationFieldT;
    excludedFieldIds: number[];
    selectedFieldIds: number[] | null;
    propertiesList: PropertiesType[];
    isLoading: boolean;
    openAgroforestryDuplicationModal: boolean;
    toggleAgroforestryDuplicationModal: () => void;
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    handleDuplicateClick: () => void;
    handleExceptionClick: () => void;
    onModalRightPanelCopyButtonClick: () => Promise<unknown>;
    confirmOverrideAgroforestryWarningModalProps: {
        isOpen: boolean;
        data: void | null;
        onClose: ((closeData: ConfirmModalCloseDataT) => void) | null;
    };
}

export const useAgroforestryDuplicationActionPageData = ({
    navigate,
}: {
    navigate: NavigateFunction;
}): AgroforestryDuplicationActionPageData => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Modal state
    const [openAgroforestryDuplicationModal, setOpenAgroforestryDuplicationModal] = useState<boolean>(false);

    const toggleAgroforestryDuplicationModal = useCallback(() => {
        setOpenAgroforestryDuplicationModal((prev) => !prev);
    }, []);

    // Route parameters
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldId: sourceFieldId } = useAgroforestryParams();
    if (!currentSeasonId || !sourceFieldId) {
        throw new Error('A parameter is unexpectedly missing in the agroforestry duplication route');
    }

    // Entity states
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { treeState } = entities.tree.useState({ farmSeasonId: currentSeasonId });
    const [duplicateAgroforestry] = entities.tree.useDuplicate();
    const fieldWithoutAgroforestryIds = fieldState.list.filter((el) => !el.has_agroforestry).map((el) => el.id);
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.selectedIds);
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId ?? 0 });
    const { getCropsAssignedToAField, fieldCropStateLoading } = cropState.useGetCropsAssignedToAField();
    const { staticTreeState } = entities.staticTree.useState();

    // Loading state

    const sourceField: DuplicationFieldT = useMemo(() => {
        const field = fieldState.getById(sourceFieldId) as FieldT;
        const allCrops = getCropsAssignedToAField(sourceFieldId);
        const allStaticCrops = allCrops
            .map((crop) => staticCropState.getById(crop.crop_id))
            .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);

        return {
            field: field,
            snapshot: field ? getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at }) : '',
            crops: allStaticCrops?.map((c) => ({
                id: c?.id,
                name: t(`db.crop.slug-${c.slug}`),
            })),
        };
    }, [fieldState, getCropsAssignedToAField, getFieldThumbnail, sourceFieldId, staticCropState, t]);

    const isLoading =
        cropState.isLoading ||
        treeState.isLoading ||
        fieldState.isLoading ||
        staticCropState.isLoading ||
        staticTreeState.isLoading ||
        fieldCropStateLoading ||
        !sourceField;

    // Properties list
    const propertiesList = useMemo(() => {
        const sourceFieldAgroforestry: TreeT[] = treeState.getByFieldId(sourceFieldId);
        const res = sourceFieldAgroforestry.map((el) => {
            const treeType = staticTreeState.getById(el.tree_type_id);
            return [
                {
                    key: `${el.id}-quantity`,
                    keyLabel: t('encoding-agroforestry.form.details.quantity.question', {
                        treeName: t(treeType?.translation_slug || ''),
                    }),
                    valueLabel: `${el.quantity_per_ha} ${t('constants.unit.any-ha')}`,
                },
                {
                    key: `${el.id}-diameter`,
                    keyLabel: t('encoding-agroforestry.form.details.diameter.question-enriched', {
                        treeName: t(treeType?.translation_slug || ''),
                    }),
                    valueLabel: `${el.diameter} ${t('constants.unit.cm')}`,
                },
            ];
        });
        return res.flat();
    }, [sourceFieldId, staticTreeState, t, treeState]);

    // Warning Modal Controller
    const { open: openOverrideAgroforestryWarningModal, ...confirmOverrideAgroforestryWarningModalProps } =
        useModalController<void, ConfirmModalCloseDataT>();

    const handleOpenOverrideAgroforestryWarningModal = useCallback(
        async (fieldId: number) => {
            const hasAgroforestryData = treeState.getByFieldId(fieldId).length > 0;
            if (hasAgroforestryData) {
                const { confirmed } = await openOverrideAgroforestryWarningModal();
                return confirmed;
            }
            return true;
        },
        [openOverrideAgroforestryWarningModal, treeState],
    );

    useEffect(() => {
        dispatch(fieldSelectionDuplicationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handlers
    const onSelect = useCallback(
        async (fieldId: number) => {
            const confirmed = await handleOpenOverrideAgroforestryWarningModal(fieldId);
            if (confirmed) {
                dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldId]));
            }
        },
        [dispatch, handleOpenOverrideAgroforestryWarningModal, selectedFieldIds],
    );

    const onDeselect = useCallback(
        (fieldId: number) => {
            const newSelectedFieldIds = [...selectedFieldIds].filter((el) => el !== fieldId);
            dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds(newSelectedFieldIds));
        },
        [dispatch, selectedFieldIds],
    );

    const handleDuplicateClick = useCallback(() => {
        toggleAgroforestryDuplicationModal();
    }, [toggleAgroforestryDuplicationModal]);

    const handleExceptionClick = useCallback(() => {
        navigate(agroforestryRoutes.introduction());
    }, [navigate]);

    const onModalRightPanelCopyButtonClick = useCallback(async () => {
        const response = await duplicateAgroforestry({
            farmSeasonId: currentSeasonId,
            farmSeasonFieldId: sourceFieldId,
            body: {
                farm_season_field_ids: selectedFieldIds,
            },
        });
        await lazyGetProgress({ seasonId: currentSeasonId });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('encoding-agroforestry.duplication.error'), { variant: 'error' });
        } else {
            navigate(agroforestryRoutes.introduction());
        }
    }, [currentSeasonId, duplicateAgroforestry, lazyGetProgress, navigate, selectedFieldIds, sourceFieldId, t]);

    return {
        sourceField,
        excludedFieldIds: [...fieldWithoutAgroforestryIds, sourceField?.field?.id],
        selectedFieldIds,
        propertiesList,
        isLoading,
        openAgroforestryDuplicationModal,
        toggleAgroforestryDuplicationModal,
        onSelect,
        onDeselect,
        handleDuplicateClick,
        handleExceptionClick,
        onModalRightPanelCopyButtonClick,
        confirmOverrideAgroforestryWarningModalProps,
    };
};
