import { Navigate, useRoutes } from 'react-router-dom';
import { historyRouteConfig } from '@modules/encoding/modules/history/history.routes';
import { HistoryContentPage } from '@modules/encoding/modules/history/pages/HistoryContentPage/HistoryContentPage';
import { encodingShared } from '@modules/encoding/shared';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { NOT_FOUND_ROUTE } from '@shared/errors';
import { Guard } from '@shared/guards/Guard';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { IsTechnicalItineraryStepCompletedAsyncGuard } from '@shared/guards/IsTechnicaltineraryStepCompletedGuard';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const routes = useRoutes([
        {
            path: historyRouteConfig.introduction,
            element: (
                <Guard
                    element={<HistoryContentPage />}
                    guards={[IsStepVisibleGuard, IsTechnicalItineraryStepCompletedAsyncGuard]}
                />
            ),
        },
        { path: '*', element: <Navigate to={`/${currentSeasonId}${NOT_FOUND_ROUTE}`} /> },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
