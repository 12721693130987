import { FormProvider } from 'react-hook-form';
import { FinaliseAssignationWarningModal } from '@modules/encoding/modules/rotation/components/FinaliseAssignationWarningModal/FinaliseAssignationWarningModal';
import { useFieldCropAssignationModalLogic } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/useFieldCropAssignationModalLogic';
import { useTranslation } from 'react-i18next';
import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { FieldCropAssignationModalLeftPanel } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalLeftPanel/FieldCropAssignationModalLeftPanel';
import { FieldCropAssignationModalRightPanel } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalRightPanel/FieldCropAssignationModalRightPanel';
import { Button } from '@soil-capital/ui-kit/components';

export type FieldCropAssignationModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FieldCropAssignationModal = ({ open, onClose }: FieldCropAssignationModalProps) => {
    const { t } = useTranslation();

    const {
        methods,
        isFinalizeLoading,
        onAssignationModalFinish,
        openFinaliseModal,
        onFinaliseWarningModalConfirm,
        onFinaliseModalClose,
        isWaitingForFieldCropCreation,
    } = useFieldCropAssignationModalLogic({
        onClose,
    });

    return (
        <>
            <FormProvider {...methods}>
                <form>
                    <FullscreenModal
                        open={open}
                        onClose={() => undefined}
                        title={t('encoding-rotation.modal.title')}
                        leftPanel={<FieldCropAssignationModalLeftPanel />}
                        rightPanel={<FieldCropAssignationModalRightPanel />}
                        headerButton={
                            <Button
                                onClick={onAssignationModalFinish}
                                loading={isWaitingForFieldCropCreation || isFinalizeLoading}
                                variant="contained"
                                color="primary"
                            >
                                {t('constants.finish')}
                            </Button>
                        }
                    />
                </form>
            </FormProvider>

            <FinaliseAssignationWarningModal
                open={openFinaliseModal}
                onClose={onFinaliseModalClose}
                onConfirm={onFinaliseWarningModalConfirm}
            />
        </>
    );
};
