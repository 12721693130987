import { useContext, useEffect, useState } from 'react';
import { MapContext } from './MapProvider';
import { Map as MapboxGl } from 'mapbox-gl';
import { useMapRootStyle } from './useMapRootStyle';

/**
 * The component on which the map will render. It also initialize the map to be provided through the context. \
 * It should not grow in complexity. Additional UI can be provided through MapPortal. Additional control should be handled through useMap.
 */
export const MapRoot = ({ className }: { className?: string }) => {
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }
    const { classes, cx } = useMapRootStyle();

    const [mapLoaded, setMapLoaded] = useState(false);

    // Initialize map + fill the context
    useEffect(() => {
        if (mapContext.map || !mapContext.mapContainerRef.current) {
            return;
        }

        const mapInstance = new MapboxGl({
            container: mapContext.mapContainerRef.current,
            style: process.env.REACT_APP_MAPBOX_STYLE as string,
            center: [4.730750210785887, 47.720658279951024], // [4.8151, 50.6242],
            zoom: 14,
            accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
            doubleClickZoom: false,
        });

        mapContext.setMap(mapInstance);

        mapInstance.once('load', () => setMapLoaded(true));
        mapInstance.once('remove', () => setMapLoaded(false));

        return () => {
            mapInstance.remove();
            mapContext.setMap(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={mapContext.mapContainerRef}
            className={cx(className, classes.mapRoot, classes.fadeIn, mapLoaded && classes.visible)}
        ></div>
    );
};
