import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { ImagePanel } from '@modules/encoding/shared/components/ImagePanel/ImagePanel';
import { FieldSelectionPanel } from '@modules/encoding/shared/components/FieldSelectionPanel/FieldSelectionPanel';
import { useAgroforestryFieldSelectionModalLogic } from './useAgroforestryFieldSelectionModal.logic';
import { ConfirmDeleteAgroforestryModal } from '../ConfirmDeleteAgroforestryModal/ConfirmDeleteAgroforestryModal';

export type AgroforestryFieldSelectionModalProps = {
    open: boolean;
    onClose: () => void;
};

export const AgroforestryFieldSelectionModal = ({ open, onClose }: AgroforestryFieldSelectionModalProps) => {
    const { t } = useTranslation();

    const {
        handleCloseAgroforestryFieldSelectionModal,
        isCloseLoading,
        harvestYear,
        isLoading,
        handleDeleteAgroforestry,
        handleCreateAgroforestry,
        confirmDeleteAgroforestryController,
        excludedFieldIds,
    } = useAgroforestryFieldSelectionModalLogic({
        onClose,
    });

    return (
        <>
            <FullscreenModal
                open={open}
                onClose={() => undefined}
                title={t('encoding-agroforestry.modal.title')}
                leftPanel={<ImagePanel src={'/assets/images/agroforestry.svg'} />}
                rightPanel={
                    <FieldSelectionPanel
                        title={t('encoding-agroforestry.modal.right-panel.title')}
                        description={t('encoding-agroforestry.modal.right-panel.description', { harvestYear })}
                        isLoading={isLoading}
                        onDeselect={handleDeleteAgroforestry}
                        onSelect={handleCreateAgroforestry}
                        excludedFieldIds={excludedFieldIds}
                    />
                }
                headerButton={
                    <Button
                        onClick={handleCloseAgroforestryFieldSelectionModal}
                        variant="contained"
                        color="primary"
                        loading={isCloseLoading}
                    >
                        {t('constants.finish')}
                    </Button>
                }
            />
            <ConfirmDeleteAgroforestryModal modalController={confirmDeleteAgroforestryController} />
        </>
    );
};
