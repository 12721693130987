import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { LivestockDetailsForm } from '../../components/LivestockDetailsForm/LivestockDetailsForm';
import { livestockRoutesConfig } from '../../livestock.routes';
import { useLivestockDetailsForm } from '../../hooks/useLivestockDetailsForm';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';

export default function LivestockDetailsActionPage({ navigate }: { navigate: NavigateFunction }) {
    const { t } = useTranslation();
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });
    const isPerformingMutation = globalMutationStatus === 'pending';
    const { isLoading, isDone, handleContinue, recomputeProgressLoading } = useLivestockDetailsForm({ navigate });

    return (
        <encodingShared.ActionPage
            title={t('encoding-livestock.details.title')}
            leftButtonProps={{
                onClick: () => {
                    navigate(`${livestockRoutesConfig.introduction}`);
                },
                children: t('constants.back'),
            }}
            rightButtonProps={{
                children: t('constants.continue'),
                loading: recomputeProgressLoading,
                onClick: handleContinue,
                disabled: isPerformingMutation || isLoading || !isDone,
            }}
            isLoading={isLoading}
        >
            <LivestockDetailsForm navigate={navigate} />
        </encodingShared.ActionPage>
    );
}
