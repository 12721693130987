import { auth } from '@modules/auth';
import farmApi from '@shared/entities/farm/farm.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { STATIC_ROLE_SLUGS, UserT } from '@modules/auth/api/authApi.types';
import { skipToken } from '@reduxjs/toolkit/query';

export const useGetFarmer = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const authUser: UserT | null | undefined = auth.useAuthUser();
    const isAuthUserFarmer = authUser?.role?.name === STATIC_ROLE_SLUGS.FARMER;
    const { data: farmerResponse, isLoading } = farmApi.useGetFarmer(
        !isAuthUserFarmer ? { farmSeasonId: currentSeasonId } : skipToken,
    );

    const farmer = isAuthUserFarmer ? authUser : farmerResponse;

    return { farmer, isLoading };
};
