import { ROTATION_ASSIGNATION_INPUT_NAMES } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationAssignationForm';
import { Button, SelectableList, Tooltip } from '@soil-capital/ui-kit';
import { CropInfoBox } from '@shared/components/CropInfoBox/CropInfoBox';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useCropListLogic } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/CropList/useCropList';
import { useCropListStyle } from './useCropListStyle';
import { IconClose, IconEdit } from '@soil-capital/ui-kit/icons';
import { makeStyles } from '@soil-capital/ui-kit/style';

const TooltipWrapper = ({ isSelected, children }: PropsWithChildren<{ isSelected: boolean }>) => {
    const { t } = useTranslation();
    const { classes } = makeStyles()(() => ({
        minWith0: {
            minWidth: 0,
            flex: 1,
        },
    }))();

    return (
        <Tooltip
            PopperProps={{ style: { maxWidth: 230 } }}
            text={!isSelected ? t('encoding-rotation.modal.left-panel.crop-list-tooltip') : ''}
            placement="top"
            className={classes.minWith0}
            disableInteractive
        >
            {children}
        </Tooltip>
    );
};

export const CropList = ({
    onDelete,
    onEdit,
}: {
    onDelete: (cropId: number) => void;
    onEdit: (cropId: number) => void;
}) => {
    const { cropList } = useCropListLogic();
    const { classes } = useCropListStyle();
    const { t } = useTranslation();

    return (
        <ControlledSelectableList
            name={ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID}
            singleValue
            options={cropList}
            getOptionKey={(option) => option.id}
            getOptionValue={(option) => option.id}
            renderOption={({ id, ...option }, props) => {
                return (
                    <div className={classes.row} style={props.isSelected ? { display: 'none' } : undefined} key={id}>
                        <TooltipWrapper isSelected={props.isSelected}>
                            <SelectableList.Item {...props}>
                                <CropInfoBox className={classes.cropInfoBox} {...option} />
                            </SelectableList.Item>
                        </TooltipWrapper>
                        <Tooltip
                            text={t(
                                option.isPermanent
                                    ? 'encoding-rotation.modal.crop-list.is-permanent'
                                    : 'encoding-rotation.modal.left-panel.selected-crop-icon-tooltip-edit',
                            )}
                        >
                            <Button
                                className={classes.button}
                                variant="text"
                                disabled={option.isPermanent}
                                onClick={() => onEdit(id)}
                            >
                                <IconEdit className={classes.icon} />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            text={t(
                                option.isPermanent
                                    ? 'encoding-rotation.modal.crop-list.is-permanent'
                                    : 'encoding-rotation.modal.left-panel.selected-crop-icon-tooltip',
                            )}
                        >
                            <Button
                                className={classes.button}
                                variant="text"
                                disabled={option.isPermanent}
                                onClick={() => onDelete(id)}
                            >
                                <IconClose className={classes.icon} />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }}
        />
    );
};
