import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    flexbox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        textAlign: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));
