import { HelpBox } from '@soil-capital/ui-kit';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';

export const TechnicalItineraryHelpBox = () => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();
    const { harvestYear } = useGetCurrentHarvestYear();

    return (
        <HelpBox>
            <Typography variant="h3">{t('encoding-technical-itinerary.introduction.help-title')}</Typography>
            <Typography variant="body" component="h4" mt={2}>
                {t('encoding-technical-itinerary.introduction.help-subtitle-1', { harvestYear })}
            </Typography>
            <Typography variant="subcaption" color="neutral">
                <Trans
                    i18nKey="encoding-technical-itinerary.introduction.help-description-1"
                    components={{
                        video:
                            currentLanguage?.iso_code === 'fr' ? (
                                <a
                                    href="https://www.loom.com/share/00ea03fa6e104d45a707f8f35986f1ff?sid=71698c5b-3c55-46a0-bf09-cbf3927a3044"
                                    target="_blank"
                                    rel="noreferrer"
                                />
                            ) : (
                                <a
                                    href="https://www.loom.com/share/e2743562306a4557928fb94c159a632a?sid=1d9b0296-4c55-4300-8ae4-bdedcf61b6ff"
                                    target="_blank"
                                    rel="noreferrer"
                                />
                            ),
                    }}
                />
            </Typography>
            <Typography variant="body" component="h4" mt={2}>
                {t('encoding-technical-itinerary.introduction.help-subtitle-2')}
            </Typography>
            <Typography variant="subcaption" color="neutral">
                {t('encoding-technical-itinerary.introduction.help-description-2')}
            </Typography>
            <Typography variant="body" component="h4" mt={2}>
                {t('encoding-technical-itinerary.introduction.help-subtitle-3')}
            </Typography>
            <Typography variant="subcaption" color="neutral">
                {t('encoding-technical-itinerary.introduction.help-description-3')}
            </Typography>
        </HelpBox>
    );
};
