import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useLogErrorMutation } from '@shared/api/logErrorApi';
import entities from '@shared/entities';
import { useCallback, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import irrigationRoutes from '../../irrigation.routes';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useIntroductionContentPageLogic = ({ navigate }: { navigate: NavigateFunction }) => {
    const [logError] = useLogErrorMutation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: currentSeasonId });

    const [isIrrigationFieldSelectionModalOpen, setIsIrrigationFieldSelectionModalOpen] = useState(false);
    const openIrrigationFieldSelectionModal = () => setIsIrrigationFieldSelectionModalOpen(true);
    const closeIrrigationFieldSelectionModal = () => setIsIrrigationFieldSelectionModalOpen(false);

    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();

    const progressStatus = progressState.getBySlug('irrigation')?.status;

    const navigateToIrrigationForm = ({ fieldId }: { fieldId: number }) => {
        const irrigation = irrigationState.getByFieldId(fieldId);
        if (irrigation) {
            navigate(irrigationRoutes.irrigationForm({ irrigationId: irrigation.id }));
        }
    };

    const handleContinue = useCallback(() => {
        // When progress step is done → go to next
        if (progressStatus === 'done') {
            if (nextStepToComplete) {
                return navigate(`../${getRouteMatchingStep(nextStepToComplete?.slug)}`);
            }
        }

        // When progress step is not done AND field-assignation progress detail is NOT done, → open modal “irrigation field assignation”
        const fieldAssignationProgress = progressState
            .getProgressDetailsByCategory('irrigation', 'irrigation-field-assignation')
            .at(0);

        if (!fieldAssignationProgress?.is_done) {
            return openIrrigationFieldSelectionModal();
        }

        // When progress step is not done AND field-assignation progress detail is not done, → go to “irrigation form” page of first irrigation detail undone (sorted by field area)
        const formDetailsProgress = progressState.getProgressDetailsByCategory('irrigation', 'irrigation-form');
        const firstNotDoneProgressDetail = formDetailsProgress?.find(
            (progressDetail) => progressDetail.is_done === false,
        );
        // Log Error, this should never happen
        if (!firstNotDoneProgressDetail?.farm_season_field_id) {
            return logError({ errors: ["handleContinue can't find the next progress detail form undone to navigate"] });
        }
        const nextIrrigation = irrigationState.getByFieldId(firstNotDoneProgressDetail.farm_season_field_id);
        if (!nextIrrigation) {
            return logError({
                errors: [
                    "handleContinue can't find the next irrigation to navigate related to the progress detail undone",
                ],
            });
        }
        navigate(irrigationRoutes.irrigationForm({ irrigationId: nextIrrigation.id }));
    }, [getRouteMatchingStep, irrigationState, logError, navigate, nextStepToComplete, progressState, progressStatus]);

    return {
        handleContinue,
        isIrrigationFieldSelectionModalOpen,
        openIrrigationFieldSelectionModal,
        closeIrrigationFieldSelectionModal,
        navigateToIrrigationForm,
    };
};
