import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Box, Typography } from '@soil-capital/ui-kit/material-core';
import { TypographyProps } from '@soil-capital/ui-kit/material-core';
import useDisplayPageStyle from './DisplayPage.style';
import { ButtonPropsT, ScrollSection } from '@soil-capital/ui-kit/components';
import { DisplayPageSkeleton } from './DisplayPageSkeleton';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';

const DisplayPage = ({
    children,
    Title,
    Subtitle,
    Header,
    isLoading = false,
}: {
    children: ReactNode;
    Title?: ReactElement;
    Subtitle?: ReactElement;
    Header?: ReactElement;
    leftButtonProps?: Pick<ButtonPropsT, 'children' | 'onClick' | 'loading'>;
    rightButtonProps?: Pick<ButtonPropsT, 'children' | 'onClick' | 'disabled' | 'loading'>;
    isLoading?: boolean;
}) => {
    const { classes, cx } = useDisplayPageStyle();

    return (
        <SkeletonContainer
            Content={
                <div className={cx(classes.displayPage, 'transition-panel')}>
                    {Header}
                    <ScrollSection spacingX={4} className={classes.content} backgroundColor="primaryScale.700">
                        <Box sx={{ pb: 2 }}>
                            {Title}
                            {Subtitle}
                        </Box>
                        <div className={classes.children}>{children}</div>
                    </ScrollSection>
                </div>
            }
            Skeletons={<DisplayPageSkeleton />}
            isLoading={isLoading}
            bgColor="primaryScale.700"
        />
    );
};

const DisplayPageHeader = ({ children, className }: { children: ReactNode; className?: string }) => {
    const { classes, cx } = useDisplayPageStyle();

    return <div className={cx(classes.header, className)}>{children}</div>;
};

const DisplayPageTitle = ({ children, variant = 'h2', className, ...rest }: PropsWithChildren<TypographyProps>) => {
    const { classes, cx } = useDisplayPageStyle();

    return (
        <Typography className={cx(classes.title, className)} variant={variant} {...rest}>
            {children}
        </Typography>
    );
};

const DisplayPageSubtitle = ({
    children,
    variant = 'body',
    className,
    ...rest
}: PropsWithChildren<TypographyProps>) => {
    const { classes, cx } = useDisplayPageStyle();

    return (
        <Typography className={cx(classes.subtitle, className)} variant={variant} {...rest}>
            {children}
        </Typography>
    );
};

DisplayPage.Header = DisplayPageHeader;
DisplayPage.Title = DisplayPageTitle;
DisplayPage.Subtitle = DisplayPageSubtitle;

export { DisplayPage };
