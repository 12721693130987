import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';
import { TreeEndpointParamsT, TreeT } from './tree.types';

export const treeCacheAdapter = createEntityAdapter<TreeT>({
    sortComparer: getDefaultSortComparerById<TreeT>(),
});

const treeCacheQueryHelper = createCacheQueryHelper<TreeEndpointParamsT, TreeT, 'Tree'>(api, 'Tree', treeCacheAdapter);

export default {
    transformResponse: treeCacheQueryHelper.transformResponse,
    add: treeCacheQueryHelper.add,
    update: treeCacheQueryHelper.update,
    remove: treeCacheQueryHelper.remove,
    upsertMany: treeCacheQueryHelper.upsertMany,
    removeMany: treeCacheQueryHelper.removeMany,
};
