import { makeStyles } from '@soil-capital/ui-kit/style';

export const useDisabledSectionStyle = makeStyles()(() => ({
    disabledSection: {
        opacity: 0.5,
        pointerEvents: 'none',
        flex: '1',
    },
    activeSection: {
        flex: '1',
    },
}));
