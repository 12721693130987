import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useFormContext } from 'react-hook-form';
import { FIELD_CROP_DESTINATION } from '../../pages/CropDestinationPage/useCropDestinationPageFormLogic';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useComputeCropDestinationInfoBoxData } from '../CropDestinationInfoBox/useComputeCropDestinationInfoBoxData';
import { useCallback, useMemo } from 'react';

export const useFieldCropDestinationLeftPanelLogic = () => {
    const context = useFormContext();

    const setSelectedCropDestinationId = useCallback(
        (cropDestinationId: number | null) => {
            context?.setValue(FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID, cropDestinationId);
        },
        [context],
    );
    const { harvestYear, isLoading: harvestYearIsLoading } = useGetCurrentHarvestYear();
    const { cropState } = useGetFarmSeasonCrops();
    const { computeCropDestinationInfoBoxData, isLoading: isCropDestinationInfoLoading } =
        useComputeCropDestinationInfoBoxData();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    const fieldCropsWithStaticCrop = useMemo(() => {
        return cropState.list.map((fieldCrop) => {
            const staticCrop = staticCropState.list.find((crop) => crop.id === fieldCrop.crop_id);
            return {
                ...fieldCrop,
                staticCrop,
            };
        });
    }, [cropState.list, staticCropState.list]);

    const distinctCropDestinationStaticDataIds = useMemo(() => {
        return Array.from(
            new Set(fieldCropsWithStaticCrop.map((fc) => fc.staticCrop?.crop_destination_static_data_ids).flat()),
        );
    }, [fieldCropsWithStaticCrop]);

    const cropDestinationList = useMemo(() => {
        return distinctCropDestinationStaticDataIds
            .map((cropDestinationStaticDataId) =>
                computeCropDestinationInfoBoxData({ cropDestinationId: cropDestinationStaticDataId ?? 0 }),
            )
            .sort((a, b) => b.id - a.id);
    }, [distinctCropDestinationStaticDataIds, computeCropDestinationInfoBoxData]);

    return {
        harvestYear,
        isLoading: harvestYearIsLoading || isCropDestinationInfoLoading,
        setSelectedCropDestinationId,
        cropDestinationList,
    };
};
