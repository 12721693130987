import { NavigateFunction } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { HelpBox, Stack } from '@soil-capital/ui-kit/components';
import { Typography } from '@mui/material';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { useIntroductionContentData } from '../../hooks/useIntroductionContentData';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IntroductionContentPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        harvestYear,
        isDataLoading,
        maxLivestockProgress,
        currentLivestockProgress,
        handleOpenLivestockDetails,
        handleContinue,
    } = useIntroductionContentData({ navigate });

    return (
        <ContentPage
            Title={
                <ContentPage.Title>
                    <Trans i18nKey="encoding-livestock.introduction.title" values={{ harvestYear }} />
                </ContentPage.Title>
            }
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={<ContentPage.Action onClick={handleContinue}>{t('constants.continue')}</ContentPage.Action>}
            isLoading={isDataLoading}
        >
            <IntroProgressLayout
                title={t('encoding-livestock.introduction.progress-title')}
                leftSection={
                    <Stack mt={1} spacing={1}>
                        <IntroProgressItem
                            text={t('encoding-livestock.introduction.field-progress')}
                            progress={{ max: maxLivestockProgress, current: currentLivestockProgress }}
                            onClick={handleOpenLivestockDetails}
                        />
                    </Stack>
                }
                rightSection={
                    <HelpBox>
                        <Typography variant="h3"> {t('encoding-livestock.introduction.help-title')}</Typography>
                        <Typography variant="body" component="h4" mt={2}>
                            {t('encoding-livestock.introduction.help-subtitle-1')}
                        </Typography>
                        <Typography variant="subcaption" color="neutral">
                            {t('encoding-livestock.introduction.help-description-1')}
                        </Typography>
                    </HelpBox>
                }
            />
        </ContentPage>
    );
};
