import { useDuplicateOperationsFromYear } from '@modules/yoy/hooks/useDuplicateOperationsFromYear';
import { useForm } from '@shared/hooks';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const YOY_DUPLICATION_ERRORS = {
    SOURCE_SEASON_ID_REQUIRED: 'yoy.duplication.page.errors.source_season_id',
};

export const useYoyDuplicationForm = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const schema = z.object({
        source_season_id: z.number({ required_error: t(YOY_DUPLICATION_ERRORS.SOURCE_SEASON_ID_REQUIRED) }),
        crop_ids: z.array(z.number()).nonempty(),
    });

    const { duplicateFromYear, isLoading: isDuplicating } = useDuplicateOperationsFromYear({
        onSuccess: () => enqueueSnackbar(t('yoy.duplication.page.snackbar.success'), { variant: 'success' }),
        onError: () => enqueueSnackbar(t('yoy.duplication.page.snackbar.error'), { variant: 'error' }),
    });

    const methods = useForm({
        schema,
        defaultValues: {
            source_season_id: undefined,
            crop_ids: [],
        },
        mode: 'onChange',
    });

    const onSubmit = methods.handleSubmit(async (data) => {
        await duplicateFromYear({ seasonId: data.source_season_id, cropIds: data.crop_ids });
        methods.clearErrors();
    });

    return {
        methods,
        onSubmit,
        isDuplicating,
    };
};
