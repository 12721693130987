import { Button, SidePanel } from '@soil-capital/ui-kit/components';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { useState } from 'react';

export const DuplicationModalRightPanelFooter = ({
    onButtonAction,
    buttonLabel,
    isButtonDisabled,
    setIsDoingCopy,
}: {
    onButtonAction: () => Promise<unknown>;
    buttonLabel?: string;
    isButtonDisabled?: boolean;
    setIsDoingCopy?: (value: boolean) => void;
}) => {
    const [isLoading, setIsLoading] = useState<boolean>();

    return (
        <SidePanel.Footer>
            <Button
                loading={isLoading}
                onClick={async () => {
                    setIsLoading(true);
                    if (setIsDoingCopy) {
                        setIsDoingCopy(true);
                    }
                    await onButtonAction();
                    if (setIsDoingCopy) {
                        setIsDoingCopy(false);
                    }
                    setIsLoading(false);
                }}
                disabled={isButtonDisabled}
                fullWidth
                variant="contained"
                type="submit"
            >
                <IconLightning />
                {buttonLabel}
            </Button>
        </SidePanel.Footer>
    );
};
