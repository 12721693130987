import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldSelectionPanelStyle = makeStyles()(() => ({
    relative: {
        position: 'relative',
    },

    panel: {
        position: 'relative',
        flex: 1,
    },

    textIndent: {
        textIndent: '-1em',
        paddingLeft: '1em',
        whiteSpace: 'pre-line',
    },
}));
