import { PaymentStepModal } from '@modules/payment/components/PaymentStepModal/PaymentStepModal';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { ConfirmationStep } from '@modules/payment/components/PaymentStepModal/components/ConfirmationStep/ConfirmationStep';
import { useConfirmationLogic } from './useConfirmationLogic';

export const Confirmation = () => {
    const {
        computedTitle,
        computedSubtitle,
        computedCta,
        computedSecondaryCta,
        handleRedirect,
        handleSecondaryRedirect,
        isLoading,
        isCtaLoading,
    } = useConfirmationLogic();

    return (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModal stepProgress={4}>
                <ConfirmationStep
                    title={computedTitle}
                    subtitle={computedSubtitle}
                    ctaText={computedCta}
                    ctaAction={handleRedirect}
                    isCtaLoading={isCtaLoading}
                    secondaryCtaText={computedSecondaryCta}
                    secondaryCtaAction={handleSecondaryRedirect}
                    isLoading={isLoading}
                />
            </PaymentStepModal>
        </Grid>
    );
};
