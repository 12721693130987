import { Accordion, FormControl, MenuItem, Select } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { IconLightning, IconWarning } from '@soil-capital/ui-kit/icons';
import { useYoyDuplicationAccordionStyles } from '@modules/yoy/components/YoyDuplicationAccordion/YoyDuplicationAccordion.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { useDuplicationSeasonSource } from '@modules/yoy/hooks/useDuplicationSeasonSource';

export const YoyDuplicationAccordion = ({ open, onClick }: { open: boolean; onClick: () => void }) => {
    const { t } = useTranslation();
    const { classes } = useYoyDuplicationAccordionStyles();
    const { register } = useFormContext();
    const { farmSeasons } = useDuplicationSeasonSource();

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <Accordion
            expanded={open}
            className={classes.accordion}
            onClick={onClick}
            Header={
                <Accordion.Header>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <IconLightning />
                            <Typography>{t('yoy.duplication.accordion.title')}</Typography>
                        </div>
                        <Typography variant="caption" className={classes.actionLabel}>
                            {t(open ? 'yoy.duplication.accordion.close' : 'yoy.duplication.accordion.open')}
                        </Typography>
                    </div>
                </Accordion.Header>
            }
            Content={
                <Accordion.Content className={classes.content}>
                    <Typography variant="body2">{t('yoy.duplication.accordion.content.select-year')}</Typography>
                    <Typography variant="body2" className={classes.warning}>
                        <IconWarning />
                        {t('yoy.duplication.accordion.content.warning')}
                    </Typography>
                    <FormControl error={!!errors?.source_season_id}>
                        <Select
                            {...register('source_season_id')}
                            className={classes.select}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Année de base de duplication"
                            defaultValue=""
                            fullWidth
                            displayEmpty
                            error={!!errors?.source_season_id}
                        >
                            <MenuItem sx={{ display: 'none' }} value="" disabled>
                                {t('yoy.duplication.accordion.content.input.placeholder')}
                            </MenuItem>
                            {farmSeasons?.map((farmSeason) => (
                                <MenuItem key={farmSeason.id} value={farmSeason.season_id}>
                                    {farmSeason.harvest_year}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors?.source_season_id && (
                            <FormHelperText>{(errors?.source_season_id?.message as string) ?? ''}</FormHelperText>
                        )}
                    </FormControl>
                </Accordion.Content>
            }
        />
    );
};
