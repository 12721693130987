import { useTranslation } from 'react-i18next';
import { MultipleSelectCheckmarks } from '@soil-capital/ui-kit/components';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { Box } from '@soil-capital/ui-kit/material-core';
import { IconSearch } from '@soil-capital/ui-kit/icons';
import { NavigateFunction } from 'react-router-dom';
import { useAgroforestryFormStyles } from './AgroforestryForm.style';
import { AgroforestryDetailsForm } from '../AgroforestryDetailsForm/AgroforestryDetailsForm';
import { useAgroforestryForm } from '../../hooks/useAgroforestryForm';

export const AgroforestryForm = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        staticAgroforestry,
        agroforestry,
        selectedStaticAgroforestry,
        updatingStaticAgroforestry,
        handleAgroforestryChange,
        readOnly,
        field,
        updatingAgroforestry,
    } = useAgroforestryForm({ navigate });
    const { classes } = useAgroforestryFormStyles();

    return (
        <div className={classes.form}>
            <form>
                <FormRow>
                    <FormQuestion question={t('encoding-agroforestry.form.question')} />
                    <MultipleSelectCheckmarks
                        values={selectedStaticAgroforestry}
                        options={staticAgroforestry}
                        className={classes.select}
                        disabledOptions={readOnly ? staticAgroforestry : updatingStaticAgroforestry}
                        placeholder={t('constants.select')}
                        getOptionLabel={(option) => t(option.translation_slug)}
                        getOptionValue={(option) => option.id}
                        getOptionKey={(option) => option.id}
                        onChange={handleAgroforestryChange}
                        renderValues={(values) => (
                            <Box component="span" display="flex" alignItems="center" gap={1}>
                                <IconSearch />
                                {`${values.length} ${t('constants.selected')}`}
                            </Box>
                        )}
                    />
                </FormRow>
            </form>
            {agroforestry.map((el) => {
                return (
                    <AgroforestryDetailsForm
                        field={field}
                        updatingAgroforestry={updatingAgroforestry}
                        key={el.tree_type_id}
                        translationKey={
                            staticAgroforestry.find((staticAgroforestry) => staticAgroforestry.id === el.tree_type_id)
                                ?.translation_slug || ''
                        }
                        agroforestry={el}
                    />
                );
            })}
        </div>
    );
};
