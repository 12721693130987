import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';
import entities from '@shared/entities';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { useTranslation } from 'react-i18next';
import { useIrrigationRouteParams } from '../../hooks/useIrrigationParams';

export const ConfirmDeleteIrrigationModal = ({
    modalController,
}: {
    modalController: ModalPropsT<{ fieldId: number }, { confirmed: boolean }>;
}) => {
    const { t } = useTranslation();
    const { seasonId } = useIrrigationRouteParams('introduction');
    const { fieldState } = entities.field.useState({ farmSeasonId: seasonId });
    const fieldFromIrrigation = fieldState.getById(modalController.data?.fieldId);

    return (
        <ConfirmModal
            type="warning"
            isOpen={modalController.isOpen}
            onClose={modalController.onClose}
            title={t('encoding-irrigation.modal.irrigation-delete-modal.title')}
            primaryText={{
                translationKey: 'encoding-irrigation.modal.irrigation-delete-modal.warning',
                translationValues: {
                    fieldName: fieldFromIrrigation?.name,
                },
            }}
            secondaryText={{ translationKey: 'encoding-irrigation.modal.irrigation-delete-modal.description' }}
            confirmText={t('constants.confirm-deletion')}
            cancelText={t('constants.cancel')}
        />
    );
};
