import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import entities from '@shared/entities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { useDispatch } from 'react-redux';
import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { useModalController } from '@shared/hooks/useModalController';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import api from '@shared/api';

export const useAgroforestryFieldSelectionModalLogic = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const [isCloseLoading, setIsCloseLoading] = useState(false);
    const globalSaveStatusData = useGlobalSaveStatusData();
    const { globalMutationPromiseRef } = useGlobalSaveStatusLogic({ dataContext: globalSaveStatusData });
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const { currentFarm } = useCurrentFarm();
    const { currentSeasonId } = useCurrentSeasonId();
    const { treeState } = entities.tree.useState({ farmSeasonId: currentSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const [updateField] = entities.field.useUpdate();
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const harvestedFieldsIds = harvestedFields.map((item) => item.id);
    const excludedFieldIds = fieldState.list.filter((el) => !harvestedFieldsIds.includes(el.id)).map((el) => el.id);

    const { readOnly } = useFarmSeasonReadOnly();
    const confirmDeleteAgroforestryController = useModalController<{ fieldId: number }, { confirmed: boolean }>();

    const hasDataChange = useRef(false);

    const withFieldLoadingState = useCallback(
        async (fieldId: number, asyncFn: () => Promise<void>) => {
            dispatch(fieldSelectionAssignationSlice.actions.addLoadingFieldId(fieldId));
            dispatch(fieldSelectionAssignationSlice.actions.addDisabledFieldId(fieldId));
            try {
                await asyncFn();
            } finally {
                dispatch(fieldSelectionAssignationSlice.actions.removeLoadingFieldId(fieldId));
                dispatch(fieldSelectionAssignationSlice.actions.removeDisabledFieldId(fieldId));
            }
        },
        [dispatch],
    );

    const handleCreateAgroforestry = useCallback(
        (fieldId: number) => {
            return withFieldLoadingState(fieldId, async () => {
                hasDataChange.current = true;
                await updateField({
                    farmSeasonId: currentSeasonId,
                    id: fieldId,
                    body: {
                        has_agroforestry: true,
                    },
                });
            });
        },
        [currentSeasonId, updateField, withFieldLoadingState],
    );

    const handleDeleteAgroforestry = useCallback(
        async (fieldId: number) => {
            const field = fieldState.getById(fieldId);
            if (!field?.id) {
                throw Error('Try to delete agroforestry on not existing field');
            }
            const hasAgroforestryData = treeState.getByFieldId(fieldId).length > 0;
            if (hasAgroforestryData) {
                const { confirmed } = await confirmDeleteAgroforestryController.open({
                    fieldId,
                });
                if (!confirmed) {
                    return;
                }
            }
            hasDataChange.current = true;
            await withFieldLoadingState(fieldId, async () => {
                await updateField({
                    farmSeasonId: currentSeasonId,
                    id: fieldId,
                    body: {
                        has_agroforestry: false,
                    },
                });
                dispatch(api.util.invalidateTags(['Tree']));
            });
        },
        [
            fieldState,
            treeState,
            withFieldLoadingState,
            dispatch,
            confirmDeleteAgroforestryController,
            updateField,
            currentSeasonId,
        ],
    );

    useEffect(() => {
        dispatch(fieldSelectionAssignationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Sync fieldSelection data
    useEffect(() => {
        const agroforestryFields = harvestedFields.filter((el) => el.has_agroforestry);
        const agroforestryFieldsIds = agroforestryFields.map((el) => el.id);
        dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds(agroforestryFieldsIds));

        if (readOnly) {
            const disabledFieldIds = fieldState.list.map((field) => field.id);
            dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(disabledFieldIds));
        }
    }, [dispatch, fieldState.list, harvestedFields, readOnly]);

    const { harvestYear } = useGetCurrentHarvestYear();

    const handleCloseAgroforestryFieldSelectionModal = async () => {
        if (hasDataChange.current === false) {
            return onClose();
        }
        setIsCloseLoading(true);
        // wait until mutations are done
        await globalMutationPromiseRef.current;
        // wait the progress refetch
        await lazyGetProgress({ seasonId: currentSeasonId });
        setIsCloseLoading(false);
        onClose();
        // reset the form dirty check for next opening
        hasDataChange.current = false;
    };

    return {
        isCloseLoading,
        harvestYear,
        handleCreateAgroforestry,
        handleDeleteAgroforestry,
        handleCloseAgroforestryFieldSelectionModal,
        isLoading: treeState.isLoading || fieldState.isLoading || harvestedFieldsLoading,
        readOnly,
        confirmDeleteAgroforestryController,
        excludedFieldIds,
    };
};
