import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { FieldSelectionPanel } from '@modules/encoding/shared/components/FieldSelectionPanel/FieldSelectionPanel';
import { Button } from '@soil-capital/ui-kit/components';
import { FieldCropDestinationLeftPanel } from '../../components/FieldCropDestinationLeftPanel/FieldCropDestinationLeftPanel';
import { FormProvider } from 'react-hook-form';
import { useCropDestinationPageLogic } from './useCropDestinationPageLogic';

export const CropDestinationPage = () => {
    const {
        t,
        methods,
        onFinishClick,
        handleUpdateSelectedCropDestination,
        fieldIdsToExcludeFromCropDestination,
        isRotationProgressLoading,
        isLoading,
    } = useCropDestinationPageLogic();

    return (
        <FormProvider {...methods}>
            <form>
                <FullscreenModal
                    open={true}
                    onClose={() => undefined}
                    title={t('encoding-rotation.crop-destination.title')}
                    leftPanel={<FieldCropDestinationLeftPanel />}
                    rightPanel={
                        <FieldSelectionPanel
                            title={t('encoding-rotation.crop-destination.right-panel.title')}
                            description={t('encoding-rotation.crop-destination.right-panel.description')}
                            isLoading={isLoading}
                            onDeselect={(fieldId) => handleUpdateSelectedCropDestination(fieldId, 'remove')}
                            onSelect={(fieldId) => handleUpdateSelectedCropDestination(fieldId, 'add')}
                            excludedFieldIds={fieldIdsToExcludeFromCropDestination}
                            isCropAssignation={true}
                        />
                    }
                    headerButton={
                        <Button
                            onClick={onFinishClick}
                            variant="contained"
                            color="primary"
                            loading={isRotationProgressLoading}
                        >
                            {t('constants.finish')}
                        </Button>
                    }
                />
            </form>
        </FormProvider>
    );
};
