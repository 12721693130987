import { FieldWithCropT } from '../types/mapTypes';

/** get {id} array of crops from field_crops without duplicates. */
const getUniqueCrops = (fieldsArray: FieldWithCropT[]): { id: number }[] => {
    const result = fieldsArray?.flatMap((field) => field.crops?.map((crop) => ({ id: crop.id })) || []);
    const uniqueResult = Array.from(new Set(result.map((item) => JSON.stringify(item)))).map((item) =>
        JSON.parse(item),
    );
    return uniqueResult;
};

export default getUniqueCrops;
